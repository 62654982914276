import React, { useContext, useState } from "react";
import { Badge, Caption, Card, Link, TextStyle, Thumbnail, Stack, Icon, Button, ButtonGroup } from "@shopify/polaris";
import { NoteMajor, QuickSaleMajor } from "@shopify/polaris-icons";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { baseHelper, imageHelper } from "lib/helpers";
import LineItemProperties from "app/orders/modules/generic/service/lineItemProperties";
import { StyledCard } from "app/orders/modules/operator/features/view/genericStyled";
import Popover from "app/orders/modules/provider/features/view/features/popover";

const PendingForCollect = (props) => {
  const {
    itemData,
    openClickReserveModal,
    refundData,
    selectedItems,
    setSelectedItems,
    setSelectedReserveItemData,
    selectedReserveItemData,
    setBulkQuantity,
    bulkQuantity,
    getSlipName,
  } = props;
  const { cms, history, match } = useContext(PrivateContext);

  const [activePopover, setActivePopover] = useState({});

  // eslint-disable-next-line no-unused-vars
  const handleChange = (value) => {
    const { fulfillmentStatus, _id: id, collectDetail } = value;
    if ([constant.UNFULFILLED, constant.PARTIAL].includes(fulfillmentStatus) && collectDetail) {
      const { readyQuantity } = collectDetail;
      const found = selectedItems.find((element) => element === id);
      if (found) {
        setSelectedItems(
          selectedItems.filter((item) => {
            return item !== id;
          })
        );
        setSelectedReserveItemData(selectedReserveItemData.filter((item) => item._id !== id));
        setBulkQuantity(bulkQuantity.filter((item) => item.id !== id));
      } else {
        setSelectedItems([...selectedItems, id]);
        setSelectedReserveItemData([...selectedReserveItemData, value]);
        setBulkQuantity([
          ...bulkQuantity,
          {
            id: value._id,
            quantity: (readyQuantity && readyQuantity.toString()) || "",
            totalQuantity: (readyQuantity && readyQuantity.toString()) || "",
          },
        ]);
      }
    }
  };

  const amount = [];
  const vendorsSum = [];

  itemData.filter(({ vendor }) => {
    vendorsSum.push(vendor);
    return vendorsSum;
  });

  const vendorSet = new Set(vendorsSum);
  const setSize = vendorSet.size;
  const addVendorTxt =
    setSize === constant.value.ONE ? `${setSize} ${cms("label.vendor")}` : `${setSize} ${cms("label.vendors")}`;
  const lineDataItems = itemData.filter((lineItem) => {
    const {
      actualQuantity = 0,
      collectDetail,
      fulfillmentStatus = "",
      fulfillmentType = "",
      isRefund,
      quantity = 0,
    } = lineItem;
    return (
      (fulfillmentStatus === constant.UNFULFILLED || fulfillmentStatus === constant.PARTIAL) &&
      [constant.RESERVE, constant.COLLECT, constant.SERVICE].includes(fulfillmentType) &&
      collectDetail &&
      (isRefund
        ? collectDetail && collectDetail.readyQuantity < (quantity || 0)
        : collectDetail && collectDetail.readyQuantity < (actualQuantity || 0))
    );
  }).length;

  const lineItems =
    itemData &&
    itemData.length &&
    itemData
      .filter((item) => {
        const {
          actualQuantity = 0,
          collectDetail,
          fulfillmentStatus = "",
          fulfillmentType = "",
          isRefund,
          quantity = 0,
        } = item;
        return (
          (fulfillmentStatus === constant.UNFULFILLED || fulfillmentStatus === constant.PARTIAL) &&
          [constant.RESERVE, constant.COLLECT, constant.SERVICE].includes(fulfillmentType) &&
          collectDetail &&
          (isRefund
            ? collectDetail && collectDetail.readyQuantity < (quantity || 0)
            : collectDetail && collectDetail.readyQuantity < (actualQuantity || 0))
        );
      })
      .map((lineData, idx) => {
        const {
          _id: id,
          collectDetail = {},
          image = "",
          isProductDeleted,
          isRefund = false,
          moneyFormat: lineMoneyFormat,
          name,
          price = 0,
          productId,
          properties,
          quantity = 0,
          sku,
          status,
          vendor,
          fulfillmentType: lineItemFulfillmentType,
          shopifyLineItemId,
        } = lineData || {};
        let { actualQuantity = 0 } = lineData || {};

        const { readyQuantity = 0 } = collectDetail || {};

        if (isRefund && refundData) {
          refundData.forEach((refundItem) => {
            const { totalQuantity = 0, line_item_id: shopifyId = "" } = refundItem;
            if (shopifyLineItemId === shopifyId) {
              actualQuantity += totalQuantity;
            }
          });
        }
        const lineItemKey = `lineItem${idx}`;
        const lineImage =
          imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;

        const moneyFormat = lineMoneyFormat || constant.symbol.DOLLAR;

        const lineItemName = <div className="productName">{name}</div>;
        const productURL =
          properties && properties.length ? `/products/service/edit/${productId}` : `/products/edit/${productId}`;
        const totalQuantity = parseInt(actualQuantity || quantity, 10) - parseInt(readyQuantity, 10);
        const totalPrice = totalQuantity * price;
        amount.push(totalPrice);

        const isServiceLineItem = baseHelper.isServiceLineItem(lineItemFulfillmentType, properties);

        return (
          <div key={lineItemKey} className="fulfilled-Secn">
            <Card>
              <Card.Section subdued>
                <StyledCard className="styled-card-unfulfill">
                  <Stack wrap={false}>
                    <Stack.Item>
                      <Thumbnail size="large" source={lineImage} alt={name} />
                    </Stack.Item>
                    <Stack.Item fill>
                      {(isProductDeleted && (lineItemName || "")) || (
                        <Link onClick={() => history.push(productURL)}>{name || ""}</Link>
                      )}

                      <Caption>
                        <TextStyle variation={constant.SUBDUED}>
                          {vendor ? `${cms("label.vendor")}: ${vendor}` : ""}
                        </TextStyle>
                        <LineItemProperties
                          variation={constant.SUBDUED}
                          properties={properties}
                          isServiceLineItem={isServiceLineItem}
                        />
                        {properties && properties.length
                          ? ""
                          : sku && (
                          <TextStyle variation={constant.SUBDUED}>
                                {cms("label.skuList")}
                                {sku}
                              </TextStyle>
                            )}
                      </Caption>

                      <Badge status={baseHelper.getBadgeType(status)} className="text-capitalize">
                        {lineItemFulfillmentType === constant.SERVICE
                          ? cms("label.pendingService")
                          : cms("label.partialReady")}
                      </Badge>
                      <Stack alignment="center">
                        <Stack.Item>
                          <ButtonGroup>
                            <Stack.Item>
                              <Popover
                                active={activePopover[id]}
                                setActive={() => {
                                  setActivePopover({ [id]: !activePopover[id] });
                                }}
                                item={[
                                  {
                                    content:
                                      lineItemFulfillmentType === constant.SERVICE
                                        ? cms("label.markReadyService")
                                        : cms("label.markReadyForCollection"),
                                    onAction: () =>
                                      openClickReserveModal(
                                        lineData,
                                        lineItemFulfillmentType,
                                        lineData.sellerId,
                                        false
                                      ),
                                    icon: QuickSaleMajor,
                                    disabled: readyQuantity >= (actualQuantity || 0),
                                  },
                                ]}
                              />
                            </Stack.Item>
                            <Stack alignment="center">
                              <Button plain size="slim" onClick={() => window.open(`/order-slip/${match.params.id}`)}>
                                <Stack spacing="extraTight" alignment="center">
                                  <Stack.Item>
                                    <Icon source={NoteMajor} />
                                  </Stack.Item>
                                  <Stack.Item>{getSlipName(lineItemFulfillmentType)}</Stack.Item>
                                </Stack>
                              </Button>
                            </Stack>
                          </ButtonGroup>
                        </Stack.Item>
                      </Stack>
                    </Stack.Item>
                    <Stack.Item>{` ${cms("label.qty")}: ${totalQuantity}`}</Stack.Item>
                    <Stack.Item>
                      {moneyFormat}
                      {(totalPrice && totalPrice.toFixed(2)) || constant.value.ZERO}
                    </Stack.Item>
                  </Stack>
                </StyledCard>
              </Card.Section>
            </Card>
          </div>
        );
      });

  const stringToNumber = amount.map((item) => parseFloat(item));
  const totalAmount = stringToNumber.reduce((cur, acc) => cur + acc, constant.value.ZERO);

  const moneyFormat = (itemData && itemData.length && itemData[0].moneyFormat) || constant.symbol.DOLLAR;

  const totalFulfill = (
    <Card.Section>
      <Stack>
        <Stack.Item fill>
          <TextStyle variation="strong">{cms("label.total")}</TextStyle>
        </Stack.Item>
        <Stack.Item>
          <Caption>
            (
            {lineDataItems > 1
              ? `${lineDataItems}
                ${cms("label.items")} from ${addVendorTxt}`
              : `${lineDataItems}
                ${cms("label.item")} from ${addVendorTxt}`}
            )
          </Caption>
        </Stack.Item>
        <Stack.Item>
          <TextStyle variation="strong">{`${moneyFormat} ${totalAmount.toFixed(2)}`}</TextStyle>
        </Stack.Item>
      </Stack>
    </Card.Section>
  );

  return [lineItems, totalFulfill];
};

export default PendingForCollect;
