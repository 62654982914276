import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";

import {
  Badge,
  Button,
  ButtonGroup,
  Caption,
  Card,
  FormLayout,
  Icon,
  Link,
  Modal,
  Stack,
  TextField,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";

import { NoteMajor } from "@shopify/polaris-icons";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { baseHelper, imageHelper } from "lib/helpers";
import { StyledCard } from "app/orders/modules/operator/features/view/genericStyled";

const UnfulfilledLineItems = (props) => {
  const {
    data,
    fulfillmentType: orderFulfillmentType,
    itemData = [],
    setFulfillmentType: setOrderFulfillmentType,
    setBanner,
    fulfillOrderLakeItem,
    fulfillOrderLakeItemData,
    onRefetchOrderView,
    onRefetchOrderLineView,
    fulfillOrderItemLoading,
    currentUser,
  } = props;

  const [trackingModal, setTrackingModal] = useState(false);

  const [initialTrackingNumber, setInitialTrackingNumber] = useState("");
  const [initialTrackingCompany, setInitialTrackingCompany] = useState("");
  const [trackingCompany, setTrackingCompany] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");

  const [selectedItemData, setSelectedItemData] = useState({});
  const [selected] = useState(constant.DISPATCHED);

  const isPackingSlip = !constant.IS_PACKING_SLIP.includes(data.storeStatus);
  const { history, cms, match } = useContext(PrivateContext);
  const isDispatched = selected === constant.DISPATCHED;

  const isBigCommerceUser = baseHelper.isBigCommerceUser(currentUser);
  const { moneyFormat: userMoneyFormat } = currentUser;
  const { gql, providerList, REFUND } = constant;
  const { PENDING } = providerList;

  const acceptOnlyValidInput = (value) => {
    return baseHelper.stringNotAcceptSpaceAtStart(value) && value;
  };

  useEffect(() => {
    if (fulfillOrderLakeItemData) {
      const updateOrderTracking = baseHelper.getResponseData(fulfillOrderLakeItemData, gql.FULFILL_ORDER_LAKE_ITEM);
      const updateOrderTrackingError = baseHelper.getResponseError(
        fulfillOrderLakeItemData,
        gql.FULFILL_ORDER_LAKE_ITEM
      );
      if (updateOrderTracking) {
        setTrackingModal(false);
        setInitialTrackingNumber("");
        setInitialTrackingCompany("");
        setTrackingCompany("");
        setTrackingNumber("");
        setTimeout(() => {
          onRefetchOrderView();
          onRefetchOrderLineView();
          // refetchOrderFulfillment();
        }, 2000);
      }
      if (updateOrderTrackingError) {
        setInitialTrackingNumber("");
        setInitialTrackingCompany("");
        setTrackingCompany("");
        setTrackingNumber("");

        setTrackingModal(false);
        setBanner({
          status: constant.CRITICAL,
          title: updateOrderTrackingError,
          isOpen: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillOrderLakeItemData]);

  const renderTotalAmount = () => {
    const vendorsSum = [];
    const countItems = itemData && itemData.length;
    const moneyFormat = userMoneyFormat || constant.symbol.DOLLAR;

    const subTotalAmount = itemData
      // .filter((lineItem) => !(lineItem.trackingCompany && lineItem.trackingNumber))
      .map((item) => {
        let total = 0;
        const totalPrice = parseFloat(item.quantity) * parseFloat(isBigCommerceUser ? item.basePrice : item.price);
        if (item && item.providerId && !vendorsSum.includes[item.providerId]) {
          vendorsSum.push(item.providerId);
        }
        total = parseFloat(total) + parseFloat(totalPrice);
        return total;
      });

    const subTotalData = subTotalAmount.reduce((total, item) => {
      return total + item;
    }, 0);

    const vendorSet = new Set(vendorsSum);
    const setSize = vendorSet.size;
    const addVendorTxt =
      setSize === constant.value.ONE ? `${setSize} ${cms("label.vendor")}` : `${setSize} ${cms("label.vendors")}`;

    return (
      <>
        <Card.Section>
          <Stack alignment="center">
            <Stack.Item fill>
              <TextStyle variation="strong">{cms("label.total")}</TextStyle>
            </Stack.Item>
            <Stack.Item>
              <Caption>
                (
                {countItems > 1
                  ? `${countItems}
                ${cms("label.items")} from ${addVendorTxt}`
                  : `${countItems}
                ${cms("label.item")} from ${addVendorTxt}`}
                )
              </Caption>
            </Stack.Item>
            <Stack.Item>
              <TextStyle variation="strong">{`${moneyFormat} ${subTotalData.toFixed(2)}`}</TextStyle>
            </Stack.Item>
          </Stack>
        </Card.Section>
      </>
    );
  };

  const addTrackingDetails = () => {
    const input = {
      shippingStatus: selected,
      isSendNotification: false,
      lineItemId: selectedItemData._id,
    };
    if (isDispatched) {
      input.trackingNumber = initialTrackingNumber.trim();
      input.trackingCompany = initialTrackingCompany.trim();
    }

    fulfillOrderLakeItem({
      variables: {
        input: { ...input },
      },
    });
  };

  const openTrackingModel = (value) => {
    setSelectedItemData(value);

    if (value && value.trackingCompany) {
      setInitialTrackingCompany(value.trackingCompany);
      setTrackingCompany(value.trackingCompany);
    }

    if (value && value.trackingNumber) {
      setInitialTrackingNumber(value.trackingNumber);
      setTrackingNumber(value.trackingNumber);
    }
    setTrackingModal(true);
  };

  // const options = [
  //   { label: cms("label.awaitingDispatch"), value: constant.AWAITING_DISPATCH },
  //   { label: cms("label.dispatch"), value: constant.DISPATCHED },
  // ];

  const renderTracking = () => {
    return (
      <>
        <Modal
          open={trackingModal}
          onClose={() => {
            setTrackingModal(false);
            setInitialTrackingNumber("");
            setInitialTrackingCompany("");
            setTrackingCompany("");
            setTrackingNumber("");
          }}
          title={cms("label.addTracking")}
          primaryAction={{
            content: cms("label.saveDetails"),
            onAction: () => {
              addTrackingDetails();
            },
            loading: fulfillOrderItemLoading,
            disabled: isDispatched && !(initialTrackingCompany.length && initialTrackingNumber.length),
          }}
          sectioned
        >
          <Modal.Section>
            <FormLayout>
              {/* <Stack wrap={false} id={selectedItemData._id}>
                <Stack.Item fill>
                  <Select
                    label={cms("label.shipmentStatus")}
                    options={options}
                    onChange={handleSelectChange}
                    value={selected}
                  />
                </Stack.Item>
              </Stack> */}

              {isDispatched && (
                <>
                  <TextField
                    label={cms("label.company")}
                    value={initialTrackingCompany}
                    onChange={(val) => {
                      setInitialTrackingCompany(acceptOnlyValidInput(val));
                    }}
                    disabled={trackingCompany}
                  />
                  <br />
                  <TextField
                    label={cms("label.trackingNumber")}
                    value={initialTrackingNumber}
                    onChange={(val) => {
                      setInitialTrackingNumber(acceptOnlyValidInput(val));
                    }}
                    disabled={trackingNumber}
                  />
                </>
              )}
            </FormLayout>
          </Modal.Section>
        </Modal>
      </>
    );
  };

  const renderUnfullfilled = () => {
    const UnfulFilled = itemData.map((value, key) => {
      // if (!value || (value.trackingCompany && value.trackingNumber)) {
      //   return null;
      // }
      const lineImage =
        imageHelper.resize({ url: value.image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;

      const lineItemKey = `lineItem${key}`;
      const { isProductDeleted } = value;

      const typeValue = value && value.fulfillmentType;
      const typeData = orderFulfillmentType;
      if (typeValue && _.isArray(typeData) && !typeData.includes(typeValue)) {
        typeData.push(typeValue);
      }
      setOrderFulfillmentType(typeData);

      // commenting for now as there is no use for this
      // let isAccepted = false;
      // switch (value.status) {
      //   case constant.ACCEPTED:
      //     isAccepted = true;
      //     break;
      //   case constant.REJECTED:
      //     isAccepted = false;
      //     break;
      //   case constant.STATUS_NEW:
      //     isAccepted = true;
      //     break;
      //   case constant.CLOSED:
      //     isAccepted = null;
      //     break;
      //   default:
      // }

      const moneyFormat = userMoneyFormat || constant.symbol.DOLLAR;
      // const lineTotalPrice = parseFloat(value.price) * parseInt(value.quantity, 10);
      let lineItemStatus = value.status;

      if (value.fulfillmentStatus === constant.PARTIAL && value.status === constant.SHIPPED) {
        lineItemStatus = constant.ACCEPTED;
        // isAccepted = true;
      }
      const badgeType = baseHelper.getBadgeType(lineItemStatus);
      const lineItemName = <div className="productName">{value.name}</div>;

      const storeStatusView = [PENDING, REFUND];

      return (
        <>
          <div className="badge-gap" key={lineItemKey}>
            <Card>
              <Card.Section subdued>
                <StyledCard className="styled-card-unfulfill">
                  <Stack wrap={false}>
                    <Stack.Item>
                      <Thumbnail size="large" source={lineImage} alt={value.name} />
                    </Stack.Item>
                    <Stack.Item fill>
                      {(isProductDeleted && (lineItemName || "")) || (
                        <Link onClick={() => history.push(`/product-lake/edit/${value.productId}`)}>
                          {value.name || ""}
                        </Link>
                      )}

                      <Caption>
                        <TextStyle variation={constant.SUBDUED}>{`${cms("label.skuList")} ${value.sku}`}</TextStyle>
                      </Caption>
                      <Badge
                        status={baseHelper.getBadgeType((value.shippingStatus || "").toLowerCase()) || badgeType}
                        className="text-capitalize"
                      >
                        {value && storeStatusView.includes(value.storeStatus)
                          ? baseHelper.getBadgeStatus(value.storeStatus)
                          : baseHelper.getBadgeStatus(value.shippingStatus)}
                      </Badge>

                      <Stack>
                        {!(value && storeStatusView.includes(value.storeStatus)) && (
                          <ButtonGroup>
                            <Stack alignment="center">
                              <Button onClick={() => openTrackingModel(value)}>
                                {`${cms("label.fulfillOrderLine")}`}
                              </Button>
                            </Stack>
                          </ButtonGroup>
                        )}

                        {isPackingSlip && (
                          <Button plain onClick={() => window.open(`/orderlake-slip/${match.params.id}`)}>
                            <Stack spacing="extraTight" alignment="center">
                              <Stack.Item>
                                <Icon source={NoteMajor} />
                              </Stack.Item>
                              <Stack.Item>{`${cms("label.packingSlip")}`}</Stack.Item>
                            </Stack>
                          </Button>
                        )}
                      </Stack>
                    </Stack.Item>
                    <Stack.Item>{` ${cms("label.qty")}: ${value.quantity}`}</Stack.Item>
                    <Stack.Item>
                      {` ${cms("label.price")}: ${moneyFormat}${
                        isBigCommerceUser ? parseFloat(value.basePrice).toFixed(2) : value.price
                      }`}
                    </Stack.Item>
                    {/* <Stack.Item>
                      {` ${cms("label.total")}: ${moneyFormat}`}
                      {(lineTotalPrice && lineTotalPrice.toFixed(2)) || constant.value.ZERO}
                    </Stack.Item> */}
                  </Stack>
                </StyledCard>
              </Card.Section>
            </Card>
          </div>
        </>
      );
    });
    return UnfulFilled;
  };

  return [renderUnfullfilled(), renderTotalAmount(), renderTracking()];
};

export default UnfulfilledLineItems;
