import { gql } from "apollo-boost";

const GET_PAYMENT_EXPORT_SETTING = gql`
  query getPaymentExportSetting {
    getPaymentExportSetting {
      data {
        export {
          date {
            isHideCsv
            isHidePdf
            label
          }
          orderNumber {
            isHideCsv
            label
            isHidePdf
          }
          invoiceId {
            isHideCsv
            isHidePdf
            label
          }
          lineItemId {
            isHideCsv
            label
            isHidePdf
          }
          vendor {
            isHideCsv
            label
            isHidePdf
          }
          product {
            isHideCsv
            isHidePdf
            label
          }
          sku {
            isHideCsv
            label
            isHidePdf
          }
          quantity {
            isHideCsv
            isHidePdf
            label
          }
          price {
            isHideCsv
            isHidePdf
            label
          }
          discount {
            isHideCsv
            isHidePdf
            label
          }
          markupPrice {
            label
            isHideCsv
            isHidePdf
          }
          paymentStatus {
            label
            isHideCsv
            isHidePdf
          }
          createdAt {
            label
            isHideCsv
            isHidePdf
          }
          tax {
            label
            isHideCsv
            isHidePdf
          }
          shipping {
            label
            isHideCsv
            isHidePdf
          }
          shippingTax {
            label
            isHideCsv
            isHidePdf
          }
          operatorCommission {
            label
            isHideCsv
            isHidePdf
          }
          commissionTax {
            label
            isHideCsv
            isHidePdf
          }
          orderPayout {
            label
            isHideCsv
            isHidePdf
          }
          actualPayoutDate {
            isHideCsv
            isHidePdf
            label
          }
          shippingPayout {
            label
            isHideCsv
            isHidePdf
          }
          totalVendorPayout {
            label
            isHideCsv
            isHidePdf
          }
          vendorAmount {
            label
            isHideCsv
            isHidePdf
          }
          status {
            label
            isHideCsv
            isHidePdf
          }
        }
        updatedAt
      }
      error
      status
    }
  }
`;

export default GET_PAYMENT_EXPORT_SETTING;
