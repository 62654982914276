import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLazyQuery, useQuery, useMutation } from "@apollo/react-hooks";
import moment from "moment";
import _ from "lodash";

import {
  Badge,
  Banner,
  Button,
  ButtonGroup,
  Caption,
  Card,
  Checkbox,
  DatePicker,
  FormLayout,
  Icon,
  Link,
  Modal,
  Select,
  Stack,
  TextField,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";
import {
  CalendarMinor,
  CircleCancelMajor,
  CircleTickOutlineMinor,
  ClockMinor,
  EditMinor,
  NoteMajor,
  QuickSaleMajor,
} from "@shopify/polaris-icons";

import { CREATE_HOPSCOTCH_TASK } from "app/orders/apollo/mutations";
import { updateOrderLineTracking, updateOrderLineStatus } from "app/orders/apollo/updateOrderNote";
import { GET_SHIPPING_LABEL_STATUS, GET_ADDRESS } from "app/orders/apollo/queries";

import { PrivateContext } from "lib/context";
import { ModalPopup } from "lib/components";
import constant from "lib/constant/constant";
import { statusHelper, baseHelper, imageHelper, errorHelper } from "lib/helpers";
import { StyledCard, HopScotchModal } from "app/orders/modules/operator/features/view/genericStyled";
import Popover from "app/orders/modules/generic/popover/popover";
import LineItemProperties from "app/orders/modules/generic/service/lineItemProperties";
// import { OrderContext } from "app/orders/modules/operator/features/view/orderContext";
import { FieldSetQty } from "app/orders/modules/operator/features/view/modal/modalStyle";

const {
  gql,
  value: { ZERO },
  WARNING,
} = constant;

const UnfulfilledLineItems = (props) => {
  // const {
  //   fulfillmentType: orderFulfillmentType,
  //   setFulfillmentType: setOrderFulfillmentType,
  // } = useContext(OrderContext);

  const {
    actionData,
    bulkFulfillData,
    bulkFulfillItem,
    data,
    fulfillment = {},
    getSlipText,
    fulfillmentType: orderFulfillmentType,
    isBulkChecked,
    isEnabledData,
    itemData = [],
    lineLoading,
    linkData = [],
    loading,
    onRefetchOrderLineView,
    onRefetchOrderView,
    refetchOrderFulfillment,
    selectedItems,
    setLineLoading,
    setSelectedItems,
    handleBulkAccept,
    openModel,
    setActionData,
    setActive,
    setBanner,
    setConsignmentValue,
    setFulfillmentType: setOrderFulfillmentType,
    setMessage,
    setRejectModal,
    setIsBulkChecked,
    setIsCapmaignOrder,
  } = props;

  const date = new Date();
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();

  const [activePopover, setActivePopover] = useState({});
  const [fulfillPopover, setFulfillPopover] = useState({});

  const [dateValue, setDateValue] = useState("");
  const [initialTrackingCompany, setInitialTrackingCompany] = useState("");
  const [initialTrackingNumber, setInitialTrackingNumber] = useState("");
  const [lineItemId, setLineItemId] = useState("");
  const [trackingCompany] = useState("");
  const [trackingNumber] = useState("");

  const [activeCalendar, setActiveCalendar] = useState(false);
  const [openReinitiateModal, setOpenReinitiateModal] = useState(false);

  const [selectedDates, setSelectedDates] = useState();
  const [selectTime, setSelectTime] = useState();

  const [fulfillmentType, setFulfillmentType] = useState(constant.DROPSHIP);
  const [{ month, year }, setDate] = useState({ month: currentMonth, year: currentYear });

  const { dropship = {}, consignmentShip = {} } = fulfillment || {};
  const { isThirdPartyShipping = false, nzPost = {}, shippingOption = "" } = dropship || consignmentShip || {};

  const [trackingModal, setTrackingModal] = useState(false);

  const [selectedItemData, setSelectedItemData] = useState({});
  const [isAcceptOrder, setIsAcceptedOrder] = useState("");
  const [bulkQuantity, setBulkQuantity] = useState([{ id: "", quantity: "", totalQuantity: "" }]);

  const [updateOrderLine, { data: dataUpdateOrderLineTracking, loading: loadingOrderLineTracking }] = useMutation(
    updateOrderLineTracking
  );
  const [updateOrderStatus, { data: updateOrderLineData, loading: orderLineLoading }] = useMutation(
    updateOrderLineStatus
  );

  const [updateHopscotchTask, { loading: hopscotchTaskLoading }] = useMutation(CREATE_HOPSCOTCH_TASK);

  const { setPageData, pageData, currentUser, history, cms, match } = useContext(PrivateContext);
  const { title, breadcrumbs, subtitle } = pageData;

  const [getShippingLabelStatus, { loading: checkShippingLabelLoading, data: checkShippingLabelData }] = useLazyQuery(
    GET_SHIPPING_LABEL_STATUS
  );

  const [isfulfillLineItemLoading, setFulFillLineItemLoading] = useState({});

  const [getAddressData, { loading: addressDataLoading, data: addressData }] = useLazyQuery(GET_ADDRESS);
  const [addressList, setAddressList] = useState([]);
  const [sellerAddresId, setSellerAddresId] = useState("");
  const [addressModal, setAddressModal] = useState(false);
  const [clickReserveLoading, setClickReserveLoading] = useState({});
  const [isTrackingCompnay, setIsTrackingCompany] = useState(false);
  const [isTrackingNumber, setIsTrackingNumber] = useState(false);

  const selectTimeAction = useCallback((value) => setSelectTime(value), []);

  useEffect(() => {
    if (updateOrderLineData) {
      const updateOrderStatusData = baseHelper.getResponseData(
        updateOrderLineData,
        constant.gql.UPDATE_ORDER_LINE_STATUS
      );
      const updateOrderStatusError = baseHelper.getResponseError(
        updateOrderLineData,
        constant.gql.UPDATE_ORDER_LINE_STATUS
      );
      if (updateOrderStatusData && isAcceptOrder) {
        setPageData({ title, breadcrumbs, subtitle });
        setMessage(cms("message.success.orderLineApproved"));
        onRefetchOrderView();
        onRefetchOrderLineView();
      } else if (updateOrderStatusData && !isAcceptOrder) {
        setMessage(cms("message.error.orderLineRejected"));
        onRefetchOrderView();
        onRefetchOrderLineView();
      }
      if (updateOrderStatusError) {
        setBanner({
          status: constant.CRITICAL,
          title: updateOrderStatusError,
          isOpen: true,
        });
      }
    }
  }, [updateOrderLineData]);

  useEffect(() => {
    let bannerData = false;
    if (!checkShippingLabelLoading && checkShippingLabelData) {
      const { _id: orderLineItemId = "" } = selectedItemData || {};
      setFulFillLineItemLoading({
        [orderLineItemId]: checkShippingLabelLoading,
      });
      const responseData = baseHelper.getResponseData(checkShippingLabelData, gql.GET_SHIPPING_LABEL_STATUS);
      if (!responseData) {
        bannerData = {
          title:
            baseHelper.getResponseError(checkShippingLabelData, gql.GET_SHIPPING_LABEL_STATUS) || errorHelper.parse(),
          status: constant.CRITICAL,
        };
      }

      if (responseData && !responseData.consignmentStatus) {
        bannerData = {
          title: cms("message.error.labelNotGenerated"),
          status: constant.WARNING,
        };
      }

      if (responseData && responseData.consignmentStatus === constant.PROCESSING) {
        bannerData = {
          title: cms("message.success.labelUnderProcess"),
          status: constant.WARNING,
        };
      }

      if (bannerData) {
        setBanner({ ...bannerData, isOpen: true });
        setTrackingModal(false);
        return;
      }
      setConsignmentValue(true);
      setTrackingModal(true);
      const { consignmentId = "", labels = [] } = responseData;
      const trackingReference = labels && labels.length && labels[0].tracking_reference;
      setInitialTrackingCompany(consignmentId);
      setInitialTrackingNumber(trackingReference);
    }
  }, [checkShippingLabelLoading, checkShippingLabelData, cms]);

  const handleChange = (value) => {
    const { _id: id, fulfillmentStatus } = value;
    if (fulfillmentStatus === constant.UNFULFILLED || fulfillmentStatus === constant.PARTIAL) {
      const found = selectedItems.find((element) => element === id);
      if (found) {
        setSelectedItems(
          selectedItems.filter((item) => {
            return item !== id;
          })
        );
      } else {
        setSelectedItems([...selectedItems, id]);
      }
    }
    setActive(false);
  };

  const checkShippingLabelStatus = (value) => {
    const { orderId = "", _id: orderLineItemId, consignmentId = "" } = value;
    setFulFillLineItemLoading({
      [orderLineItemId]: true,
    });
    getShippingLabelStatus({ variables: { input: { orderId, orderLineItemId, consignmentId } } });
  };

  const openTrackingModel = (value) => {
    const { fulfillmentType: fulfillType } = value;
    setFulfillmentType(fulfillType || constant.DROPSHIP);
    setSelectedItemData(value);
    setBulkQuantity([
      {
        id: value._id,
        quantity: value.fulfillableQuantity,
        totalQuantity: value.fulfillableQuantity.toString(),
      },
    ]);

    if (isThirdPartyShipping && nzPost && nzPost.clientId) {
      checkShippingLabelStatus(value);
      return;
    }
    if (value && value.trackingCompany) {
      setInitialTrackingCompany(value.trackingCompany);
      setIsTrackingCompany(true);
    }

    if (value && value.trackingNumber) {
      setInitialTrackingNumber(value.trackingNumber);
      setIsTrackingNumber(true);
    }
    setTrackingModal(true);
  };

  // function to fetch addresses list for corresponding vendor
  const openClickReserveModal = async (value) => {
    const { vendorId = "", sellerId = "", _id: lineItemId } = value;
    setSellerAddresId(sellerId);
    setSelectedItemData(value);
    setAddressList([]);
    getAddressData({ variables: { input: { vendorId } } });
    setClickReserveLoading({ [lineItemId]: true });
  };

  const addTrackingDetails = () => {
    if (!isEnabledData && !initialTrackingCompany) {
      setTrackingModal(false);
      setBanner({ isOpen: true, title: cms("message.error.trackingCompany"), status: constant.CRITICAL });
      return false;
    }

    if (!isEnabledData && !initialTrackingNumber) {
      setTrackingModal(false);
      setBanner({ isOpen: true, title: cms("message.error.trackingNumber"), status: constant.CRITICAL });
      return false;
    }

    const data = {
      trackingNumber: initialTrackingNumber.trim(),
      trackingCompany: initialTrackingCompany.trim(),
    };
    data.fulfillmentType = fulfillmentType || constant.DROPSHIP;
    data.bulkQuantity = bulkQuantity.map((bulkQty) => ({
      id: bulkQty.id,
      quantity: bulkQty.quantity,
    }));
    bulkFulfillItem({
      variables: {
        input: { ...data, isSingle: true },
      },
    });
  };
  const partial = bulkQuantity[0].totalQuantity - bulkQuantity[0].quantity;

  useEffect(() => {
    if (bulkFulfillData) {
      const updateOrderTracking = baseHelper.getResponseData(bulkFulfillData, gql.BULK_ORDER_FULFILL);
      const updateOrderTrackingError = baseHelper.getResponseError(bulkFulfillData, gql.BULK_ORDER_FULFILL);
      if (updateOrderTracking) {
        setPageData({ title, breadcrumbs, subtitle });
        setTrackingModal(false);
        setInitialTrackingCompany("");
        setInitialTrackingNumber("");
        if (partial > ZERO) {
          setMessage(cms("message.success.partialFulfill"));
        }
        if (partial === ZERO) {
          setMessage(cms("message.success.fulfill"));
        }
        setTimeout(() => {
          onRefetchOrderView();
          onRefetchOrderLineView();
          refetchOrderFulfillment();
        }, 2000);
      }
      if (updateOrderTrackingError) {
        setTrackingModal(false);
        setBanner({
          status: constant.CRITICAL,
          title: updateOrderTrackingError,
          isOpen: true,
        });
      }
    }
  }, [bulkFulfillData]);

  const acceptOnlyValidInput = (value) => {
    return baseHelper.stringNotAcceptSpaceAtStart(value) && value;
  };

  const validInput = (value, preValue) => {
    return (baseHelper.validateWholeNumber(value) && value) || (value !== "" && preValue) || "";
  };

  const handleQuantityChange = (id, value) => {
    setBulkQuantity([{ id, quantity: parseInt(value, 10), totalQuantity: bulkQuantity[0].totalQuantity.toString() }]);
  };

  const modalAction = useCallback(() => setOpenReinitiateModal(!openReinitiateModal), [openReinitiateModal]);

  // eslint-disable-next-line no-shadow
  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

  // const dataAction = useCallback((newValue) => setDateValue(newValue), []);

  // Hopscotch

  const handleReinitiate = async (value) => {
    let invalid = false;
    const requestData = {
      deliveryDate: baseHelper.yearFirstDateFormat(selectedDates),
      deliveryTime: selectTime,
      lineItemId,
    };

    if (!selectedDates || !selectTime) {
      invalid = true;
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: !selectedDates ? cms("message.error.date") : cms("message.error.time"),
      });
    }

    if (!invalid) {
      try {
        await updateHopscotchTask({
          variables: { input: requestData },
        });
        onRefetchOrderLineView();
        setMessage(cms("message.success.orderLineApproved"));
        // setActiveToast(value);
      } catch (error) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorHelper.parse(error) });
      }
    }
    setOpenReinitiateModal(false);
  };

  const reInitiateDeliveryModal = () => {
    // Append clock icon
    const clockIcon = <Icon source={ClockMinor} color="base" />;
    const timeOptions = baseHelper.getTime().map((item) => {
      return {
        value: item,
        label: item,
        prefix: clockIcon,
      };
    });

    const primaryAction = {
      content: cms("popover.label.reinitiate"),
      loading: hopscotchTaskLoading,
      onAction: (value) => {
        handleReinitiate(value);
      },
    };
    const secondaryActions = [
      {
        content: "Cancel",
        onAction: modalAction,
      },
    ];

    return (
      <ModalPopup
        open={openReinitiateModal}
        onClose={modalAction}
        activator={modalAction}
        title={cms("popover.label.reinitiate")}
        primaryAction={primaryAction}
        secondaryActions={secondaryActions}
      >
        <HopScotchModal>
          <Stack distribution="equalSpacing">
            <Stack.Item>
              <TextStyle variation="strong">Date</TextStyle>
              <div className="date-input">
                <TextField
                  value={dateValue}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  value={moment(selectedDates).format("YYYY-MM-DD")}
                  onChange={(value) => setDateValue(value.start)}
                  placeholder="DD/MM/YYYY"
                  onFocus={() => setActiveCalendar(true)}
                  suffix={<Icon source={CalendarMinor} color="base" />}
                />
              </div>
              {activeCalendar && (
                <div className="date-picker">
                  <DatePicker
                    month={month}
                    year={year}
                    onChange={(value) => [setSelectedDates(value.start), setActiveCalendar(false)]}
                    onMonthChange={handleMonthChange}
                    selected={selectedDates}
                    disableDatesBefore={date}
                  />
                </div>
              )}
            </Stack.Item>
            <Stack.Item>
              <TextStyle variation="strong">Time</TextStyle>
              <div className="select-time">
                <Select options={timeOptions || []} onChange={selectTimeAction} value={selectTime} />
              </div>
            </Stack.Item>
          </Stack>
        </HopScotchModal>
      </ModalPopup>
    );
  };
  const badgeStatus =
    baseHelper.getBadgeStatus(fulfillmentType) === constant.PICKUP
      ? constant.CONSIGNMENT_PICKUP_CAPITAL
      : baseHelper.getBadgeStatus(fulfillmentType);
  const renderTracking = () => {
    const lineImage =
      imageHelper.resize({ url: selectedItemData.image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;
    return (
      <>
        <Modal
          open={trackingModal}
          onClose={() => {
            setTrackingModal(false);
            setInitialTrackingNumber(trackingNumber);
            setInitialTrackingCompany(trackingCompany);
          }}
          title={cms("label.addTracking")}
          primaryAction={{
            content: cms("label.saveDetails"),
            onAction: () => {
              addTrackingDetails();
            },
            loading,
            disabled:
              parseInt(bulkQuantity[0].quantity, 10) === constant.value.ZERO ||
              bulkQuantity[0].quantity === "" ||
              loading,
          }}
          sectioned
        >
          <Modal.Section>
            <FormLayout>
              <Stack wrap={false} id={selectedItemData._id}>
                <Stack.Item>
                  <Thumbnail size="large" source={lineImage} alt={selectedItemData.name} />
                </Stack.Item>
                <Stack.Item fill>
                  <div className="ellipsis">
                    <Stack.Item>
                      {(selectedItemData.isProductDeleted && (selectedItemData.name || "")) || (
                        <Link onClick={() => history.push(`/products/edit/${selectedItemData.productId}`)}>
                          {selectedItemData.name || ""}
                        </Link>
                      )}
                      {selectedItemData.variantTitle && (
                        <Caption>
                          <TextStyle variation={constant.SUBDUED}>
                            {selectedItemData.variantTitle.toUpperCase()}
                          </TextStyle>
                        </Caption>
                      )}
                    </Stack.Item>
                    {fulfillmentType && (
                      <Badge className="text-capitalize">
                        {badgeStatus === constant.CONSIGNMENT ? constant.CONSIGNMENT_SHIP_CAPITAL : badgeStatus}
                      </Badge>
                    )}
                    {partial > 0 && <Badge status={constant.SUCCESS}>{cms("label.partial")}</Badge>}
                  </div>
                </Stack.Item>
                <FieldSetQty>
                  <Stack.Item>
                    <TextField
                      value={(bulkQuantity[0].quantity && bulkQuantity[0].quantity.toString()) || ""}
                      min={0}
                      max={(bulkQuantity[0].totalQuantity && bulkQuantity[0].totalQuantity.toString()) || ""}
                      onChange={(value) => [
                        value <= parseInt(bulkQuantity[0].totalQuantity, 10) &&
                          handleQuantityChange(bulkQuantity[0].id, validInput(value, bulkQuantity[0].quantity)),
                      ]}
                      suffix={`${cms("label.of")} ${bulkQuantity[0].totalQuantity}`}
                    />
                  </Stack.Item>
                </FieldSetQty>
              </Stack>
              {partial > 0 && <Banner status={WARNING} title={`${cms("modal.banner.partial")}.`} />}
              <TextField
                label={cms("label.company")}
                value={initialTrackingCompany}
                disabled={isTrackingCompnay}
                onChange={(val) => {
                  setInitialTrackingCompany(acceptOnlyValidInput(val));
                }}
              />
              <TextField
                label={cms("label.trackingNumber")}
                value={initialTrackingNumber}
                disabled={isTrackingNumber}
                onChange={(val) => {
                  setInitialTrackingNumber(acceptOnlyValidInput(val));
                }}
              />
            </FormLayout>
          </Modal.Section>
        </Modal>
      </>
    );
  };
  const renderTotalAmount = () => {
    const amount = [];
    const vendorsSum = [];
    let countItems = 0;
    const moneyFormat = data.moneyFormat || constant.symbol.DOLLAR;

    itemData.forEach(
      ({ collectDetail = {}, fulfillmentStatus, fulfillableQuantity = 1, price, quantity = 1, status, vendor }) => {
        if (
          (fulfillmentStatus === constant.UNFULFILLED || fulfillmentStatus === constant.PARTIAL) &&
          !(status === constant.REJECTED) && //  || status === constant.CLOSED
          !collectDetail
        ) {
          vendorsSum.push(vendor);
          amount.push(parseFloat(price) * parseInt(fulfillableQuantity, 10));
          countItems += 1;
        }
        return [vendorsSum, amount];
      }
    );

    const vendorSet = new Set(vendorsSum);
    const setSize = vendorSet.size;
    const addVendorTxt =
      setSize === constant.value.ONE ? `${setSize} ${cms("label.vendor")}` : `${setSize} ${cms("label.vendors")}`;

    const stringToNumber = amount.map((item) => parseFloat(item));
    const totalAmount = stringToNumber.reduce((cur, acc) => cur + acc, constant.value.ZERO);

    return (
      <>
        <Card.Section>
          <Stack alignment="center">
            <Stack.Item fill>
              <TextStyle variation="strong">{cms("label.total")}</TextStyle>
            </Stack.Item>
            <Stack.Item>
              <Caption>
                (
                {countItems > 1
                  ? `${countItems}
                ${cms("label.items")} from ${addVendorTxt}`
                  : `${countItems}
                ${cms("label.item")} from ${addVendorTxt}`}
                )
              </Caption>
            </Stack.Item>
            <Stack.Item>
              <TextStyle variation="strong">{`${moneyFormat}${totalAmount.toFixed(2)}`}</TextStyle>
            </Stack.Item>
          </Stack>
        </Card.Section>
      </>
    );
  };
  const renderUnfullfilled = () => {
    const UnfulFilled = itemData.map((value, key) => {
      if (
        !value ||
        value.fulfillmentStatus === constant.FULFILLED ||
        value.fulfillmentStatus === constant.REFUND ||
        value.status === constant.REJECTED ||
        // value.status === constant.CLOSED ||
        value.collectDetail ||
        !value.fulfillableQuantity
      ) {
        return null;
      }
      const { properties } = value;
      const isVendor = baseHelper.isVendor(currentUser);
      const lineImage =
        imageHelper.resize({ url: value.image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;

      const lineItemKey = `lineItem${key}`;
      const { isProductDeleted, isVendorDeleted } = value;

      const typeValue = value && value.fulfillmentType;
      const typeData = orderFulfillmentType;

      if (typeData && _.isArray(typeData) && typeValue && !typeData.includes(typeValue)) {
        typeData.push(typeValue);
        setOrderFulfillmentType(typeData);
      }

      let isAccepted = false;
      let isFulfilled = false;
      // eslint-disable-next-line default-case
      switch (value.status) {
        case constant.ACCEPTED:
          isAccepted = true;
          break;
        case constant.REJECTED:
          isAccepted = false;
          break;
        case constant.STATUS_NEW:
          isAccepted = true;
          break;
        case constant.CLOSED:
          isAccepted = null;
          break;
      }
      if (value.fulfillmentStatus === constant.UNFULFILLED) {
        switch (value.status) {
          case constant.ACCEPTED:
            isFulfilled = false;
            break;
          case constant.REJECTED:
            isFulfilled = false;
            break;
          case constant.STATUS_NEW:
            isFulfilled = true;
            break;
          case constant.CLOSED:
            isFulfilled = null;
            break;
          default:
            break;
        }
      }

      if (value.isCampaign) {
        setIsCapmaignOrder(true);
      }

      const moneyFormat = value.moneyFormat || constant.symbol.DOLLAR;
      const lineTotalPrice = parseFloat(value.price) * parseInt(value.fulfillableQuantity, 10);
      let lineItemStatus = value.status;

      if (value.fulfillmentStatus === constant.PARTIAL) {
        isAccepted = true;
        if (value.status === constant.SHIPPED) {
          lineItemStatus = constant.ACCEPTED;
          isFulfilled = false;
        }
        if (value.status === constant.NEW) {
          isFulfilled = true;
        }
      }

      const status = statusHelper.getBadgeStatus(lineItemStatus);
      const badgeType = baseHelper.getBadgeType(lineItemStatus);

      const lineItemName = <div className="productName">{value.name}</div>;
      const isFulfillLineItemButtonVisible =
        (fulfillmentType === constant.DROPSHIP && !isThirdPartyShipping && !value.consignmentId) ||
        ((fulfillmentType === constant.DROPSHIP || constant.CONSIGNMENT) &&
          isThirdPartyShipping &&
          value.consignmentId) ||
        !(fulfillmentType === constant.DROPSHIP) ||
        !(fulfillmentType === constant.CONSIGNMENT);
      const { _id: itemId } = value;
      const lineItemLoading = (isfulfillLineItemLoading && isfulfillLineItemLoading[itemId]) || false;
      const isClickAndReserve = typeValue === constant.RESERVE;
      const isClickAndCollect = typeValue === constant.COLLECT;
      const isTimeBasedService = typeValue === constant.SERVICE;
      const isServiceLineItem = baseHelper.isServiceLineItem(typeValue, properties);
      const productURL = isServiceLineItem
        ? `/products/service/edit/${value.productId}`
        : `/products/edit/${value.productId}`;

      // if (isAccepted && !isFulfilled && isFulfillLineItemButtonVisible) {
      //   const isPackingSlipEnabled = (actionData || []).find(action => action.id === "packingSlip");
      //   if (!isPackingSlipEnabled) {
      //     const packingSlip = {
      //       action: () => window.open(`/order-slip/${match.params.id}`),
      //       caption: cms("caption.packingSlip"),
      //       id: "packingSlip",
      //       label: cms("label.packingSlip"),
      //       icon: NoteMajor,
      //     };
      //     linkData.push(packingSlip);
      //     setActionData(linkData);
      //   }
      // }

      const items = [
        {
          content: cms("popover.label.accept"),
          icon: CircleTickOutlineMinor,
          onAction: () => [handleBulkAccept(false, [itemId])],
        },
        {
          content: cms("popover.label.edit"),
          icon: EditMinor,
          onAction: () => [openModel(constant.BULK_EDIT, [itemId]), setIsBulkChecked(false)],
          disabled: value.isCampaign,
        },
        {
          content: cms("popover.label.reject"),
          destructive: true,
          icon: CircleCancelMajor,
          onAction: () => [setRejectModal(true), setSelectedItems([itemId])],
        },
      ];

      const fulfillItems = [
        {
          content: cms("popover.label.fulfill"),
          icon: QuickSaleMajor,
          disabled:
            [constant.COLLECT, constant.RESERVE, constant.SERVICE].includes(value.fulfillmentType),
          onAction: () => openTrackingModel(value),
        },
        {
          content: cms("popover.label.edit"),
          icon: EditMinor,
          onAction: () => [openModel(constant.BULK_EDIT, [itemId]), setIsBulkChecked(false)],
          disabled: value.isCampaign,
        },
        {
          content: cms("popover.label.reject"),
          destructive: true,
          icon: CircleCancelMajor,
          onAction: () => [setRejectModal(true), setSelectedItems([itemId])],
        },
      ];

      const hopScotchOption = {
        content: cms("popover.label.reinitiate"),
        icon: QuickSaleMajor,
        onAction: () => {
          setOpenReinitiateModal(true);
          setLineItemId(itemId);
        },
      };

      if (value.hopscotch && value.hopscotch.status === constant.FAILED) {
        fulfillItems.push(hopScotchOption);
      }

      return (
        <>
          <div className="badge-gap" key={lineItemKey}>
            <Card>
              <Card.Section subdued>
                <StyledCard className="styled-card-unfulfill">
                  <Stack wrap={false}>
                    <Stack.Item>
                      <Checkbox
                        id={itemId}
                        checked={isBulkChecked && selectedItems.find((element) => element === itemId)}
                        onChange={() => [handleChange(value), setIsBulkChecked(true)]}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Thumbnail size="large" source={lineImage} alt={value.name} />
                    </Stack.Item>
                    <Stack.Item fill>
                      {(isProductDeleted && (lineItemName || "")) || (
                        <Link onClick={() => history.push(productURL)}>{value.name || ""}</Link>
                      )}

                      <Caption>
                        <TextStyle variation={constant.SUBDUED}>
                          {value.vendor && value.vendorId
                            ? isVendor || isVendorDeleted
                              ? [`${cms("label.vendor")}: `, value.vendor, <br />]
                              : [
                                  `${cms("label.vendor")}: `,
                                <Link onClick={() => history.push(constant.PROVIDER_VIEW + value.vendorId)}>
                                    {value.vendor}
                                  </Link>,
                                <br />,
                                ]
                            : ""}
                        </TextStyle>
                        {isServiceLineItem ? (
                          ""
                        ) : (
                          <TextStyle variation={constant.SUBDUED}>{`${cms("label.skuList")} ${value.sku}`}</TextStyle>
                        )}

                        <LineItemProperties
                          variation={constant.SUBDUED}
                          properties={properties}
                          isServiceLineItem={isServiceLineItem}
                        />
                      </Caption>

                      {isClickAndReserve && <Badge className="text-capitalize">{cms("label.clickAndReserve")}</Badge>}
                      {isClickAndCollect && <Badge className="text-capitalize">{cms("label.clickAndCollect")}</Badge>}
                      {isTimeBasedService && <Badge className="text-capitalize">{cms("label.timeService")}</Badge>}
                      <Badge
                        status={
                          (isAccepted && lineItemStatus === cms("label.accepted").toLowerCase() && constant.SUCCESS) ||
                          (isAccepted === false && constant.WARNING) ||
                          (isAccepted && lineItemStatus === cms("label.new").toLowerCase() && constant.ATTENTION) ||
                          badgeType
                        }
                        className="text-capitalize"
                      >
                        {(isAccepted &&
                          lineItemStatus === cms("label.accepted").toLowerCase() &&
                          cms("label.accepted")) ||
                          (isAccepted && lineItemStatus === cms("label.new").toLowerCase() && cms("label.new")) ||
                          baseHelper.getBadgeStatus(status) ||
                          status}
                      </Badge>
                      {value.hopscotch && value.hopscotch.status && (
                        <Badge
                          status={
                            (value.hopscotch.status === constant.displayStatus.DELIVERED && constant.SUCCESS) ||
                            (value.hopscotch.status === constant.FAILED && constant.WARNING) ||
                            ((value.hopscotch.status === constant.displayStatus.OUT_OF_DELIVERY ||
                              value.hopscotch.status === constant.displayStatus.READY_FOR_DELIVERY) &&
                              constant.ATTENTION)
                          }
                        >
                          {`${baseHelper.getBadgeStatus(value.hopscotch.status).replace(/-/g, " ")}`}
                        </Badge>
                      )}
                      {value.rejectedByVendor && (
                        <Badge className="text-capitalize" status="critical">
                          {value.isProductDeleted ? "Product deleted by vendor" : constant.REJECTED_BY_VENDOR}
                        </Badge>
                      )}

                      {isAccepted && isFulfilled && (
                        <Stack alignment="center">
                          <Stack.Item>
                            <Popover
                              active={activePopover[itemId]}
                              setActive={() => {
                                setLineLoading([itemId]);
                                setActivePopover({ [itemId]: !activePopover[itemId] });
                              }}
                              item={items}
                              loading={loading && lineLoading.includes(itemId)}
                            />
                          </Stack.Item>
                        </Stack>
                      )}
                      <Stack>
                        {isAccepted && !isFulfilled && isFulfillLineItemButtonVisible && (
                          <ButtonGroup>
                            <Stack alignment="center">
                              <Stack.Item>
                                <Popover
                                  active={fulfillPopover[itemId]}
                                  setActive={() => setFulfillPopover({ [itemId]: !fulfillPopover[itemId] })}
                                  item={fulfillItems}
                                />
                              </Stack.Item>
                            </Stack>
                            <Button plain size="slim" onClick={() => window.open(`/order-slip/${match.params.id}`)}>
                              <Stack spacing="extraTight" alignment="center">
                                <Stack.Item>
                                  <Icon source={NoteMajor} />
                                </Stack.Item>
                                {getSlipText(value.fulfillmentType)}
                              </Stack>
                            </Button>
                          </ButtonGroup>
                        )}
                      </Stack>
                    </Stack.Item>
                    <Stack.Item>{` ${cms("label.qty")}: ${value.fulfillableQuantity}`}</Stack.Item>
                    <Stack.Item>
                      {moneyFormat}
                      {(lineTotalPrice && lineTotalPrice.toFixed(2)) || constant.value.ZERO}
                    </Stack.Item>
                  </Stack>
                </StyledCard>
              </Card.Section>
            </Card>
          </div>
        </>
      );
    });
    return UnfulFilled;
  };

  return [renderUnfullfilled(), renderTotalAmount(), renderTracking(), reInitiateDeliveryModal()];
};

export default UnfulfilledLineItems;
