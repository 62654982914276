import React, { useContext } from "react";
import { Caption, Card, Stack, TextStyle } from "@shopify/polaris";
import baseHelper from "lib/helpers/base";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

import { fullfillmentStatusProp } from "app/orders/modules/provider/features/view/propsType";
import { TotalSection } from "app/orders/modules/provider/features/view/genericStyled";
import { StyledFulfillment } from "asset/styles/globalStyle";

const FulfillmentStatus = (props) => {
  const { data, lineData, fulfilledData, term, totalFulfilledAmount = 0, totalRejectAmount = 0 } = props;

  const totalTax = lineData
    .filter((item) => item && !(item.rejectedByVendor && item.status === constant.NEW))
    .reduce((prev, value) => {
      return parseFloat(prev) + parseFloat(value.totalTax || 0);
    }, 0);

  const isRejected =
    lineData &&
    lineData.every(
      (item) =>
        (item.status === constant.REJECTED &&
          (item.fulfillmentStatus === constant.UNFULFILLED || item.fulfillmentStatus === constant.REFUND)) ||
        (item.rejectedByVendor && item.status === constant.NEW)
    );
  const isDiscountAllowed = lineData && lineData.every((item) => item.isDiscountAllowed);
  const { userData, cms } = useContext(PrivateContext);
  const currentUserData = baseHelper.getResponseData(userData, constant.gql.GET_CURRENT_USER);
  const isTaxIncluded = !!data.taxesIncluded;
  const isSeller = baseHelper.isSeller(currentUserData);

  const rejectedData = {
    commissionSeller: 0,
    commissionVendor: 0,
    discount: 0,
    isUpdatedOrderLine: true,
    markUp: 0,
    markUpItems: 0,
    price: 0,
    sellerDiscount: 0,
    shippingAmount: 0,
    tax: 0,
    totalTaxRate: 0,
    totalPrice: 0,
  };

  const totals = isRejected
    ? rejectedData
    : baseHelper.getOrderPrice(lineData, data, isSeller, false, false, fulfilledData);
  const { isUpdatedOrderLine, price = 0 } = totals;

  const subTotal = parseFloat(price);
  const updatedLineSubTotal = parseFloat(data.totalLineItemsPrice);
  const lineDataItems = lineData.filter(
    (lineItem) =>
      !(
        (lineItem.status === constant.REJECTED &&
          (lineItem.fulfillmentStatus === constant.UNFULFILLED || lineItem.fulfillmentStatus === constant.REFUND)) ||
        (lineItem.rejectedByVendor && lineItem.status === constant.NEW)
      )
  ).length;
  const items = `${lineDataItems > constant.value.ONE ? constant.items : `${cms("label.items")}:`} ${lineDataItems}`;
  const { code: discountLineCode = "", amount } =
    (data.discountCodes && data.discountCodes.length && data.discountCodes[0]) || {};

  const getDynamicDiscount = (orderLineItem = {}) => {
    let discountValue = 0;
    const { actualQuantity } = orderLineItem;
    const { value_type: dynamicDiscountType } = data.discountApplications[0] || {};

    if (orderLineItem.discountAllocations && orderLineItem.discountAllocations.length) {
      const lastDiscountAllocations = orderLineItem.discountAllocations[orderLineItem.discountAllocations.length - 1];
      if (dynamicDiscountType === constant.PERCENTAGE) {
        discountValue = lastDiscountAllocations.amount * actualQuantity;
      } else {
        discountValue = lastDiscountAllocations.amount;
      }
    }
    return parseFloat(discountValue).toFixed(2);
  };

  const dynamicDiscountValue = lineData.map((item) => getDynamicDiscount(item));
  let discountAmount = 0;
  const totalDynamicDiscount = dynamicDiscountValue.filter((item) => {
    discountAmount += parseFloat(item);
  });

  const markUpItems = `${totals.markUpItems > constant.value.ONE ? constant.items : `${cms("label.items")}:`} ${
    totals.markUpItems
  }`;
  const moneyFormat = data.moneyFormat || constant.symbol.DOLLAR;
  const { title: shippingLineTitle = "" } =
    (data.shippingLines && data.shippingLines.length && data.shippingLines[0]) || {};

  const { price: shippingLinePrice = "" } =
    (data.shippingLines && data.shippingLines.length && data.shippingLines[0]) || {};

  const vendorsSum = [];
  lineData.filter(({ vendor }) => {
    vendorsSum.push(vendor);
    return vendorsSum;
  });
  const vendorSet = new Set(vendorsSum);
  const setSize = vendorSet.size;
  const addVendorTxt =
    setSize === constant.value.ONE ? `${setSize} ${cms("label.vendor")}` : `${setSize} ${cms("label.vendors")}`;

  // const totalAmount =
  //   subTotal +
  //   (term !== constant.NONE ? totals.shippingAmount || 0 : 0) -
  //   (totals.marketplaceDiscount || totals.sellerDiscount || 0);
  return (
    <TotalSection className="total-Section">
      <Card>
        <Card.Section subdued>
          <Card.Subsection>
            <Card.Section>
              <Stack wrap={false}>
                <Stack.Item fill>{`${cms("label.subTotal")} (Exclude rejected order line item)`}</Stack.Item>
                <StyledFulfillment>
                  <Stack.Item>{items}</Stack.Item>
                  <Stack.Item>
                    {`${moneyFormat}`}
                    {isUpdatedOrderLine
                      ? Number(baseHelper.getPrice(subTotal)).toLocaleString()
                      : Number(updatedLineSubTotal.toFixed(2)).toLocaleString()}
                  </Stack.Item>
                </StyledFulfillment>
              </Stack>
            </Card.Section>
            {isSeller && isUpdatedOrderLine && totals.markUp && (
              <Card.Section>
                <Stack wrap={false}>
                  <Stack.Item fill>{cms("label.markUpTotal")}</Stack.Item>
                  <StyledFulfillment>
                    <Stack.Item>{markUpItems}</Stack.Item>
                    <Stack.Item>
                      {`${moneyFormat}`}
                      {baseHelper.getPrice(totals.markUp)}
                    </Stack.Item>
                  </StyledFulfillment>
                </Stack>
              </Card.Section>
            )}
            <Card.Section>
              <Stack wrap={false}>
                <Stack.Item fill>
                  <div className="ellipsis">
                    {cms("label.shipping")}
                    {`${shippingLineTitle && isSeller ? `(${shippingLineTitle})` : ""}`}
                  </div>
                </Stack.Item>
                <Stack.Item>
                  {`${moneyFormat}`}
                  {`${
                    term === constant.NONE || totals.shippingAmount
                      ? baseHelper.getPrice(totals.shippingAmount)
                      : baseHelper.getPrice(shippingLinePrice)
                  }`}
                </Stack.Item>
              </Stack>
            </Card.Section>
            <Card.Section>
              <Stack wrap={false}>
                <Stack.Item fill>
                  {cms("label.discount")}
                  {discountLineCode ? `(${discountLineCode})` : ""}
                </Stack.Item>
                <Stack.Item>
                  {`${moneyFormat}-`}
                  {discountAmount ||
                    (isUpdatedOrderLine &&
                      ((isSeller && baseHelper.getPrice(totals.sellerDiscount)) ||
                        baseHelper.getPrice(totals.discount))) ||
                    (lineData.totalDiscount && baseHelper.getPrice(lineData.totalDiscount)) ||
                    constant.value.FLOAT_ZERO}
                </Stack.Item>
              </Stack>
            </Card.Section>
            <Card.Section>
              <Stack wrap={false}>
                <Stack.Item fill>
                  {`${cms("label.salesTax")} ${(isTaxIncluded && cms("label.included")) || ""}`}
                </Stack.Item>
                <Stack.Item>
                  {`${moneyFormat} `}
                  {Number(totalTax).toLocaleString()}
                </Stack.Item>
              </Stack>
            </Card.Section>
          </Card.Subsection>
        </Card.Section>
        <Card.Section>
          <Stack vertical>
            <Stack wrap={false} alignment={constant.CENTER}>
              <Stack.Item fill>
                <b>{cms("label.total")}</b>
              </Stack.Item>
              <StyledFulfillment>
                <Stack.Item>
                  <Caption>
                    {" "}
                    (
                    {lineDataItems > 1
                      ? `${lineDataItems}
                      ${cms("label.items")} from ${addVendorTxt}`
                      : `${lineDataItems}
                      ${cms("label.item")} from ${addVendorTxt}`}
                    )
                  </Caption>
                </Stack.Item>
                <Stack.Item>
                  <TextStyle variation="strong">
                    {`${moneyFormat}`}
                    {/* {term === constant.FLAT || !isUpdatedOrderLine || !data.taxLines.length > 0
                      ? Number(baseHelper.getPrice(totals.totalPrice)).toLocaleString()
                      : Number(totals.totalPrice).toLocaleString()} */}
                    {Number(totals.totalPrice).toLocaleString()}
                  </TextStyle>
                </Stack.Item>
              </StyledFulfillment>
            </Stack>
          </Stack>
        </Card.Section>
      </Card>
    </TotalSection>
  );
};

FulfillmentStatus.propTypes = fullfillmentStatusProp.type;
export default FulfillmentStatus;
