import constant from "lib/constant/constant";

const { admin, operator, provider } = constant.userKey;

const allRoles = [admin, operator, provider];

/*
 * Structure
 *
 * module -> useful for feature flag and page content (page content is deprecated)
 * cms -> useful for cms, treated as CMS code
 * parentModule -> useful for cms, treated as CMS collection, default is dashboard
 *
 *
 * New changes
 *
 * Deprecated parentModule
 *
 * code -> useful for pageContent, default to module, ToDo: Remove module as default
 *
 */
const privateRouteCodes = {
  "/": {
    module: "dashboard",
    cms: "dashboard",
    parentModule: "dashboard",
    isTwoColumnLayout: true,
    rolesAllowed: [...allRoles],
  },
  "/activity": {
    module: "activity",
    cms: "activity",
    parentModule: "userManagement",
    rolesAllowed: [...allRoles],
  },
  "/activity/bulk": {
    module: "bulkActivity",
    cms: "bulkActivity",
    parentModule: "userManagement",
    rolesAllowed: [...allRoles],
  },
  "/activity/bulk-edit": {
    module: "bulkEditActivity",
    cms: "bulkEditActivity",
    parentModule: "userManagement",
    rolesAllowed: [...allRoles],
  },
  "/activity/view/:id": {
    module: "viewActivity",
    cms: "viewActivity",
    rolesAllowed: [...allRoles],
    parentModule: "userManagement",
  },
  "/analytics": {
    module: "analytics",
    cms: "analytics",
    parentModule: "analytics",
    isTwoColumnLayout: true,
    rolesAllowed: [operator, provider],
  },
  "/analytics/order-analytics": {
    module: "analytics",
    cms: "analytics",
    parentModule: "analytics",
    rolesAllowed: [provider],
  },
  "/analytics/report": {
    module: "analytics",
    cms: "analytics",
    parentModule: "analytics",
    rolesAllowed: [provider],
  },
  "/analytics/order-commission-revenue": {
    module: "analytics",
    cms: "analytics",
    parentModule: "analytics",
    rolesAllowed: [operator],
  },
  "/analytics/product-analytics": {
    module: "analytics",
    cms: "analytics",
    parentModule: "analytics",
    rolesAllowed: [provider],
  },
  "/activity/edit/view/:id": {
    module: "viewBulkEditActivity",
    cms: "viewBulkEditActivity",
    rolesAllowed: [operator, provider],
    parentModule: "userManagement",
  },
  "/association": {
    module: "association",
    cms: "association",
    parentModule: "setting",
    rolesAllowed: [admin],
  },
  "/billing/failure": {
    module: "dashboard",
    cms: "dashboard",
    parentModule: "dashboard",
    rolesAllowed: [operator],
  },
  "/billing/success": {
    module: "dashboard",
    cms: "dashboard",
    parentModule: "dashboard",
    rolesAllowed: [operator],
  },
  "/branding": {
    module: "branding",
    cms: "branding",
    rolesAllowed: [operator],
    parentModule: "setting",
  },
  "/customize/fonts": {
    module: "customizeFont",
    cms: "customizeFont",
    rolesAllowed: [operator],
    parentModule: "setting",
  },
  "/products/bulk/edit": {
    module: "bulkProductEdit",
    cms: "bulkProductEdit",
    rolesAllowed: [...allRoles],
    isFullWidth: true,
    parentModule: "product",
  },
  "/commission": {
    module: "commission",
    cms: "commission",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/order/discount": {
    code: "dynamicDiscount",
    cms: "dynamicDiscount",
    module: "order",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },
  "/change-password": {
    module: "changePassword",
    cms: "changePassword",
    parentModule: "setting",
    rolesAllowed: [...allRoles],
  },
  "/chat": {
    cms: "chat",
    module: "vendor",
    parentModule: "setting",
    rolesAllowed: [...allRoles],
  },
  "/profile": {
    code: "editProfile",
    module: "vendor",
    cms: "editProfile",
    parentModule: "setting",
    rolesAllowed: [...allRoles],
    dependentKey: ["advanceVendorProfile"],
  },
  "/chat-setting": {
    code: "chatSetting",
    module: "vendor",
    cms: "chatSetting",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/invitation": {
    module: "invitation",
    cms: "list",
    parentModule: "invitation",
    rolesAllowed: [operator, admin],
  },
  "/invoice/:id/:lineItemId": {
    module: "invoice",
    cms: "invoice",
    isPrivateLayoutHidden: true,
    parentModule: "payment",
    rolesAllowed: [admin, operator, provider],
  },
  "/maintenance": {
    module: "maintenance",
    cms: "maintenance",
    rolesAllowed: [...allRoles],
    parentModule: "userManagement",
  },
  "/orders": {
    module: "orders",
    cms: "orders",
    parentModule: "order",
    rolesAllowed: [...allRoles],
  },
  "/orders/bulk": {
    module: "order",
    cms: "orders",
    parentModule: "order",
    rolesAllowed: [provider, operator],
  },
  "/order-slip/:id": {
    module: "order",
    cms: "orderSlip",
    parentModule: "order",
    rolesAllowed: [...allRoles],
    isPrivateLayoutHidden: true,
  },
  "/orderlake-slip/:id": {
    module: "orderAdd",
    cms: "orderSlip",
    parentModule: "order",
    rolesAllowed: [...allRoles],
    isPrivateLayoutHidden: true,
  },
  "/orders/view/:id": {
    module: "viewOrder",
    cms: "viewOrder",
    parentModule: "order",
    rolesAllowed: [...allRoles],
  },
  "/order-lake/view/:id": {
    module: "viewOrder",
    cms: "viewOrderLake",
    parentModule: "order",
    rolesAllowed: [...allRoles],
    pageInfo: "viewOrderLake",
  },
  "/export/order-lake": {
    module: "exportOrder",
    cms: "exportOrder",
    isPrivateLayoutHidden: true,
    rolesAllowed: [...allRoles],
  },
  "/export/order-lake/pdf": {
    module: "exportOrder",
    cms: "exportOrder",
    isPrivateLayoutHidden: true,
    rolesAllowed: [...allRoles],
  },
  "/export/order": {
    module: "orders",
    cms: "orders",
    parentModule: "order",
    isPrivateLayoutHidden: true,
    rolesAllowed: [...allRoles],
  },
  "/export/order/pdf": {
    module: "orders",
    cms: "orders",
    parentModule: "order",
    isPrivateLayoutHidden: true,
    rolesAllowed: [...allRoles],
  },
  "/export/payment": {
    module: "exportPayment",
    cms: "exportPayment",
    isPrivateLayoutHidden: true,
    rolesAllowed: [...allRoles],
  },
  "/export/payment/pdf": {
    module: "exportPayment",
    cms: "exportPayment",
    isPrivateLayoutHidden: true,
    rolesAllowed: [...allRoles],
  },
  "/export/report": {
    module: "exportReport",
    cms: "exportReport",
    isPrivateLayoutHidden: true,
    rolesAllowed: [...allRoles],
  },
  "/export/report/pdf": {
    module: "exportReport",
    cms: "exportReport",
    isPrivateLayoutHidden: true,
    rolesAllowed: [...allRoles],
  },
  "/export/product": {
    module: "exportProduct",
    cms: "exportProduct",
    isPrivateLayoutHidden: true,
    rolesAllowed: [operator, provider],
  },
  "/export/product/csv": {
    module: "exportProduct",
    cms: "exportProduct",
    isPrivateLayoutHidden: true,
    rolesAllowed: [operator, provider],
  },
  "/export/product/pdf": {
    module: "exportProduct",
    cms: "exportProduct",
    isPrivateLayoutHidden: true,
    rolesAllowed: [operator, provider],
  },
  "/export/vendor": {
    module: "exportVendor",
    cms: "exportVendor",
    isPrivateLayoutHidden: true,
    rolesAllowed: [operator],
  },
  "/export/vendor/pdf": {
    module: "exportVendor",
    cms: "exportVendor",
    isPrivateLayoutHidden: true,
    rolesAllowed: [operator],
  },
  "/setting/provider/export": {
    cms: "vendorExport",
    code: "vendorExport",
    module: "vendor",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/onboard-activity": {
    module: "onboardActivity",
    cms: "onboardActivity",
    parentModule: "userManagement",
    rolesAllowed: [admin],
  },
  "/reporting": {
    module: "reporting",
    cms: "reporting",
    rolesAllowed: [operator, provider],
  },
  "/update/plan": {
    module: "updatePlan",
    cms: "updatePlan",
    rolesAllowed: [operator, provider],
  },
  "/payments": {
    module: "payments",
    cms: "payment",
    parentModule: "payment",
    rolesAllowed: [...allRoles],
  },
  "/payment-connect": {
    module: "connectPayment",
    cms: "connectPayment",
    parentModule: "setting",
    rolesAllowed: [provider],
  },
  "/payment-lake": {
    module: "payments",
    cms: "paymentLakeList",
    parentModule: "payment",
    rolesAllowed: [...allRoles],
  },
  "/payment-lake/invoice/:id": {
    cms: "paymentLakeInvoice",
    module: "invoice",
    parentModule: "payment",
    rolesAllowed: [provider],
    isPrivateLayoutHidden: true,
  },
  // deprecated by /paypal/callback
  "/paypal-callback": {
    module: "paypalCallback",
    cms: "paypalCallback",
    rolesAllowed: [provider],
  },
  "/paypal/callback": {
    module: "paypalCallback",
    cms: "paypalCallback",
    rolesAllowed: [provider],
  },
  "/plan": {
    module: "plan",
    cms: "updatePlan",
    rolesAllowed: [operator, provider],
    isTabsHidden: true,
  },
  "/products": {
    cms: "listProduct",
    module: "products",
    parentModule: "product",
    rolesAllowed: [...allRoles],
  },
  // "/v1/products/add": {
  //   module: "product",
  //   cms: "addProductV1",
  //   parentModule: "product",
  //   rolesAllowed: [...allRoles],
  // },
  // "/v1/products/edit/:id": {
  //   module: "editProductV1",
  //   cms: "editProductV1",
  //   parentModule: "product",
  //   rolesAllowed: [...allRoles],
  // },
  // "/v1/variants/add/:productId": {
  //   module: "addVariantV1",
  //   cms: "addVariantV1",
  //   rolesAllowed: [...allRoles],
  //   parentModule: "product",
  // },
  // "/v1/variants/edit/:productId/:id": {
  //   module: "editVariantV1",
  //   cms: "editVariantV1",
  //   rolesAllowed: [...allRoles],
  //   parentModule: "product",
  // },
  // "/products/add": {
  //   module: "addProduct",
  //   cms: "addProduct",
  //   parentModule: "product",
  //   rolesAllowed: [...allRoles],
  // },
  "/product-lake": {
    cms: "lakeProductList",
    module: "products",
    parentModule: "product",
    rolesAllowed: [...allRoles],
  },
  "/product-lake/shopify": {
    module: "shopifyProduct",
    cms: "shopifyProduct",
    rolesAllowed: [provider],
    parentModule: "product",
  },
  "/product-lake/bigcommerce": {
    module: "bigcommerceProduct",
    cms: "bigcommerceProduct",
    rolesAllowed: [provider],
    parentModule: "product",
  },
  "/products/edit": {
    module: "editProducts",
    cms: "editProducts",
    parentModule: "product",
    rolesAllowed: [operator, provider],
  },
  "/products/edit/:id": {
    module: "editProduct",
    cms: "editProductV1",
    parentModule: "product",
    rolesAllowed: [...allRoles],
  },
  "/products/service/edit/:id": {
    module: "editService",
    cms: "editProductV1",
    parentModule: "product",
    rolesAllowed: [...allRoles],
  },
  "/variants/add/:productId": {
    module: "addVariant",
    cms: "addVariantV1",
    rolesAllowed: [...allRoles],
    parentModule: "product",
  },
  "/variants/edit/:productId/:id": {
    module: "editVariant",
    cms: "editVariantV1",
    rolesAllowed: [...allRoles],
    parentModule: "product",
  },
  "/product-lake/edit/:id": {
    module: "editProductV1",
    cms: "editProductLake",
    parentModule: "product",
    rolesAllowed: [operator, provider],
    pageInfo: "editLakeProduct",
  },
  "/variants/service/add/:productId": {
    module: "addVariantV1",
    cms: "addVariantV1",
    rolesAllowed: [...allRoles],
    parentModule: "product",
  },
  "/variants/service/edit/:productId/:id": {
    module: "editVariantV1",
    cms: "editVariantV1",
    rolesAllowed: [...allRoles],
    parentModule: "product",
  },
  "/lake-variants/edit/:productId/:id": {
    module: "editVariantV1",
    cms: "editLakeVariant",
    rolesAllowed: [operator, provider],
    parentModule: "product",
    pageInfo: "editLakeVariant",
  },
  "/product-lake/review/:id": {
    module: "product",
    cms: "reviewProduct",
    rolesAllowed: [operator],
    parentModule: "product",
  },
  "/products/add": {
    module: "addProduct",
    cms: "addProductV1",
    parentModule: "product",
    rolesAllowed: [...allRoles],
  },
  "/products/add/shop": {
    module: "connectShop", // connectShop
    cms: "addProduct",
    rolesAllowed: [provider],
    parentModule: "product",
  },
  // "/products/edit/:id": {
  //   module: "editProduct",
  //   cms: "productEdit",
  //   parentModule: "product",
  //   rolesAllowed: [...allRoles],
  // },
  "/products/review/:id": {
    module: "reviewProduct",
    cms: "reviewProduct",
    rolesAllowed: [operator],
    parentModule: "product",
  },
  "/products/view/:id": {
    module: "viewProduct",
    cms: "editProductV1",
    parentModule: "product",
    rolesAllowed: [admin, provider],
  },
  "/products/shopify": {
    module: "shopifyProduct",
    cms: "shopifyProduct",
    rolesAllowed: [provider],
    parentModule: "product",
  },
  "/products/wix": {
    code: "wixProduct",
    module: "liveConnect",
    cms: "wixProduct",
    rolesAllowed: [provider],
    parentModule: "product",
  },
  "/setting/report": {
    module: "report",
    code: "reportSetting",
    cms: "reportSetting",
    rolesAllowed: [operator, provider],
    parentModule: "setting",
  },
  "/products/woocommerce": {
    code: "woocommerceProduct",

    module: "liveConnect",
    cms: "woocommerceProduct",
    rolesAllowed: [provider],
    parentModule: "product",
  },
  "/products/magento": {
    code: "magentoProduct",
    module: "liveConnect",
    cms: "magentoProduct",
    rolesAllowed: [provider],
    parentModule: "product",
  },
  "/products/prestashop": {
    code: "prestaShopProduct",
    module: "liveConnect",
    cms: "prestaShopProduct",
    rolesAllowed: [provider],
    parentModule: "product",
  },
  "/operators": {
    code: "operator",
    module: "operators",
    cms: "sellerList",
    parentModule: "operator",
    rolesAllowed: [admin],
  },
  "/operators/view/:id": {
    module: "viewSeller",
    cms: "viewSeller",
    parentModule: "operator",
    rolesAllowed: [admin],
  },
  "/quotes": {
    module: "quotes",
    cms: "quote",
    parentModule: "quote",
    rolesAllowed: [...allRoles],
  },
  "/setting": {
    module: "settings",
    cms: "setting",
    rolesAllowed: [...allRoles],
    parentModule: "setting",
  },
  "/setting/attachment": {
    module: "attachment",
    cms: "attachment",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/carrier": {
    code: "carrier",
    cms: "carrier",
    module: "carrier",
    parentModule: "setting",
    rolesAllowed: [provider],
  },
  "/setting/discount": {
    code: "discountSetting",

    module: "discount",
    cms: "discountSetting",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },
  "/setting/fulfillment": {
    module: "fulfillment",
    cms: "fulfillment",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/location": {
    module: "location",
    cms: "location",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },
  "/setting/manage-fulfillment": {
    module: "manageFulfillment",
    cms: "manageFulfillment",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/manage-fulfillment-tracking": {
    module: "manageFulfillmentTracking",
    cms: "manageFulfillmentTracking",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/markup": {
    code: "markupSetting",
    module: "markup",
    cms: "markupSetting",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/notification": {
    module: "muteNotification",
    cms: "muteNotification",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },
  "/setting/order": {
    module: "orderSetting",
    cms: "orderSetting",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },
  "/setting/order/acs": {
    cms: "orderACSSetting",
    code: "orderACSSetting",
    module: "order",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/payment": {
    module: "addPayment",
    cms: "addPayment",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/product": {
    code: "productSetting",
    module: "product",
    cms: "productSetting",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },

  "/setting/product/listing": {
    code: "productListingSetting",
    module: "product",
    cms: "productListingSetting",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },
  "/setting/product/approval": {
    code: "productApprovalSetting",
    module: "product",
    cms: "productApprovalSetting",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  // "/setting/product-lake/approval": {
  //   code: "productApprovalSetting",
  //   module: "product",
  //   cms: "productApprovalSetting",
  //   parentModule: "setting",
  //   rolesAllowed: [operator],
  // },
  "/setting/product/label": {
    code: "productFormLabel",
    module: "product",
    cms: "productFormLabel",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },
  "/setting/product/metafield": {
    code: "metafield",
    cms: "metafield",
    module: "product",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/product/export": {
    cms: "productExport",
    code: "productExportSetting",
    module: "product",
    parentModule: "setting",
    rolesAllowed: [...allRoles],
  },
  "/setting/product/geolocation": {
    cms: "manageGeolocation",
    code: "manageGeolocation",
    module: "product",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/product/price": {
    code: "productPriceSetting",
    module: "product",
    cms: "productPriceSetting",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/product/export": {
    cms: "productExport",
    code: "productExportSetting",
    module: "product",
    parentModule: "setting",
    rolesAllowed: [...allRoles],
  },
  "/setting/product/tag": {
    code: "productTagSetting",
    module: "product",
    cms: "productTagSetting",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/shipping": {
    module: "shipping",
    cms: "shipping",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },
  "/session": {
    module: "session",
    cms: "session",
    parentModule: "userManagement",
    rolesAllowed: [admin],
  },
  "/providers/shopify-callback": {
    main: "shopifyCallback",
    cms: "shopifyCallback",
    rolesAllowed: [provider],
    parentModule: "product",
  },
  "/providers/shopify/install": {
    main: "shopifyCallback",
    cms: "shopifyCallback",
    rolesAllowed: [provider],
    parentModule: "product",
  },
  "/stripe/callback": {
    module: "stripeCallback",
    cms: "stripeCallback",
    rolesAllowed: [provider],
  },
  "/stripe/return": {
    module: "stripeReturn",
    cms: "stripeReturn",
    rolesAllowed: [provider],
  },
  "/contact-us": {
    module: "contactUs",
    cms: "contactUs",
    parentModule: "userManagement",
    rolesAllowed: [operator, provider],
  },
  "/terms": {
    code: "term",

    module: "payment",
    cms: "terms",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  // "/variants/add/:productId": {
  //   module: "addVariant",
  //   cms: "addVariant",
  //   rolesAllowed: [...allRoles],
  //   parentModule: "product",
  // },
  // "/variants/edit/:productId/:id": {
  //   module: "editVariant",
  //   cms: "editVariant",
  //   parentModule: "product",
  //   rolesAllowed: [...allRoles],
  // },
  "/providers": {
    module: "vendors",
    cms: "listVendor",
    parentModule: "provider",
    rolesAllowed: [operator, admin],
  },
  "/providers/add": {
    code: "addVendor",

    module: "vendor",
    cms: "addVendor",
    parentModule: "provider",
    rolesAllowed: [operator, admin],
  },
  "/providers/edit/:id": {
    module: "editVendor",
    cms: "editVendor",
    parentModule: "provider",
    rolesAllowed: [operator, admin],
  },
  "/payouts": {
    module: "payouts",
    cms: "payouts",
    parentModule: "userManagement",
    rolesAllowed: [...allRoles],
  },
  "/shipping/advance": {
    code: "advanceShipping",

    module: "shipping",
    cms: "advanceShipping",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },
  "/feature": {
    module: "feature",
    cms: "feature",
    parentModule: "setting",
    rolesAllowed: [admin],
  },
  "/domain": {
    module: "domain",
    cms: "domain",
    rolesAllowed: [admin],
    parentModule: "userManagement",
  },
  "/file-download/vendor": {
    module: "downloadVendorFile",
    cms: "downloadVendorFile",
    parentModule: "provider",
    isPrivateLayoutHidden: true,
    rolesAllowed: [operator, admin],
  },
  "/file-download/invite": {
    module: "downloadInviteFile",
    cms: "downloadInviteFile",
    isPrivateLayoutHidden: true,
    rolesAllowed: [operator, admin],
  },
  "/providers/view/:id": {
    code: "viewVendor",

    module: "vendor",
    cms: "viewVendor",
    parentModule: "provider",
    rolesAllowed: [operator, admin],
  },
  "/update-payment": {
    module: "updatePayment",
    cms: "updatePayment",
    parentModule: "payment",
    rolesAllowed: [operator],
  },
  "/email-template": {
    module: "emailTemplate",
    cms: "emailTemplate",
    parentModule: "setting",
    rolesAllowed: [operator, admin],
  },
  "/email-template/edit/:id": {
    module: "customizeEmailTemplate",
    cms: "customizeEmailTemplate",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/advance-providers": {
    code: "advanceVendorProfile",

    module: "vendor",
    cms: "vendorAdvance",
    parentModule: "provider",
    rolesAllowed: [operator],
  },
  "/providers/profile/:id": {
    code: "advanceVendorProfile",

    module: "vendor",
    cms: "vendorAdvance",
    parentModule: "provider",
    rolesAllowed: [operator],
  },
  "/provider/manage": {
    module: "manageVendor",
    cms: "manageVendor",
    parentModule: "provider",
    rolesAllowed: [operator],
  },
  "/setting/address": {
    cms: "address",
    module: "address",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },
  "/setting/customer": {
    code: "manageCustomer",

    module: "order",
    cms: "manageCustomer",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/order-expiry": {
    code: "orderExpiry",
    module: "order",
    cms: "orderExpiry",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },
  "/setting/order/acs": {
    cms: "orderACSSetting",
    code: "orderACSSetting",
    module: "order",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/order/live-connect": {
    cms: "orderLiveConnect",
    code: "orderLiveConnect",
    module: "order",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/currency/rate": {
    cms: "currency",
    module: "currencyRateSetting",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/currency/payout": {
    cms: "currency",
    module: "currencyPayoutSetting",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/payment/export": {
    cms: "paymentExport",
    code: "paymentExportSetting",
    module: "payment",
    parentModule: "setting",
    rolesAllowed: [...allRoles],
  },
  "/setting/payment/express-payment": {
    cms: "expressPayment",
    code: "expressPayment",
    module: "payment",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/provider/access": {
    cms: "vendorAccess",
    code: "vendorAccess",
    module: "vendor",
    parentModule: "setting",
    rolesAllowed: [operator, admin],
  },
  "/setting/plan/edit/:id": {
    code: "editPlan",
    module: "vendor",
    cms: "editPlan",
    parentModule: "setting",
    rolesAllowed: [operator, admin],
  },
  "/setting/plan/add": {
    code: "addPlan",
    module: "vendor",
    cms: "addPlan",
    parentModule: "setting",
    rolesAllowed: [operator, admin],
  },
  "/setting/plan/preview": {
    module: "previewPlan",
    cms: "updatePlan",
    parentModule: "setting",
    rolesAllowed: [operator, admin],
  },
  "/setting/plan/list": {
    code: "managePlans",
    module: "vendor",
    cms: "managePlans",
    parentModule: "setting",
    rolesAllowed: [operator, admin],
  },
  "/setting/provider/advance": {
    cms: "advanceVendorAccess",
    code: "advanceVendorAccess",
    module: "vendor",
    parentModule: "setting",
    rolesAllowed: [operator, admin],
  },
  "/setting/provider/label": {
    cms: "providerFormLabel",
    code: "providerFormLabel",
    module: "vendor",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/provider/holiday": {
    module: "vendor",
    cms: "holidaySetting",
    code: "holidaySetting",
    parentModule: "provider",
    rolesAllowed: [...allRoles],
  },
  "/setting/provider/review": {
    cms: "reviewAccess",
    module: "vendor",
    code: "vendorApproval",
    parentModule: "setting",
    rolesAllowed: [operator, admin],
  },
  "/setting/provider/subscription": {
    cms: "vendorSubscription",
    module: "vendor",
    code: "vendorSubscription",
    parentModule: "setting",
    rolesAllowed: [operator, admin],
  },
  "/setting/digest": {
    code: "digestSetting",
    module: "digestSetting",
    cms: "digest",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },
  "/setting/order/export": {
    cms: "orderExport",
    code: "orderExportSetting",
    module: "order",
    parentModule: "setting",
    rolesAllowed: [...allRoles],
  },
  "/payments/credit-note": {
    cms: "creditNoteList",
    code: "creditNoteList",
    module: "order",
    parentModule: "payment",
    rolesAllowed: [operator, provider],
  },
  "/setting/credit-note": {
    code: "creditNoteSetting",
    cms: "creditNoteSetting",
    module: "order",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },
  "/setting/order/packing-slip": {
    code: "packingSlipSetting",
    cms: "packingSlipSetting",
    module: "order",
    parentModule: "setting",
    rolesAllowed: [...allRoles],
  },
  "/setting/payment/invoice": {
    code: "invoiceSetting",
    cms: "invoiceSetting",
    module: "payment",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/payment/transaction-fees": {
    code: "transactionFeesSetting",
    cms: "transactionFeesSetting",
    module: "payment",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/schedule-payment": {
    code: "schedulePayment",
    cms: "schedulePayment",
    module: "payment",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/user/database": {
    cms: "userDatabaseSetting",
    code: "userDatabaseSetting",
    module: "setting",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/tax": {
    code: "tax",
    cms: "tax",
    module: "tax",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/user/database": {
    cms: "userDatabaseSetting",
    code: "userDatabaseSetting",
    module: "setting",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/tax-rate": {
    code: "orderTaxRate",
    cms: "orderTaxRate",
    module: "order",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/provider/review/:id": {
    cms: "reviewVendor",
    module: "vendor",
    code: "reviewVendor",
    parentModule: "provider",
    rolesAllowed: [operator, admin],
  },
  "/wix/callback": {
    code: "wixInstall",
    module: "wixInstall",
    cms: "wixInstall",
    parentModule: "product",
    rolesAllowed: [provider],
  },
  "/wix/connect": {
    code: "wixInstall",
    module: "wixInstall",
    cms: "wixInstall",
    parentModule: "product",
    rolesAllowed: [provider],
  },
  "/bigCommerce/connect": {
    code: "wixInstall",
    module: "wixInstall",
    cms: "wixInstall",
    parentModule: "product",
    rolesAllowed: [provider],
  },
  "/product-lake/add": {
    module: "product",
    cms: "productLake",
    parentModule: "product",
    rolesAllowed: [provider, operator],
    pageInfo: "productLake",
  },
  "/order-lake": {
    module: "order",
    cms: "orderLake",
    parentModule: "order",
    rolesAllowed: [operator, provider],
    pageInfo: "orderLake",
  },
  "/lake-variants/add/:productId": {
    module: "addVariantV1",
    cms: "addLakeVariant",
    rolesAllowed: [...allRoles],
    parentModule: "product",
    pageInfo: "addLakeVariant",
  },
  "*": {},
};

export default privateRouteCodes;
