import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { PrivateContext } from "lib/context";

const SEO = (props) => {
  const { title, favicon, loading = false, logo, fontType } = props;

  const { currentUser } = useContext(PrivateContext);
  let backgroundColor = "";

  if (currentUser && currentUser.style) {
    backgroundColor = currentUser.style.backgroundColor;
  }

  const defaultFavicon = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }/doc/assets/root.ico`;
  const defaultTitle = "Marketcube.io - Dropshipping made simple";

  const isProd = process.env.REACT_APP_ENV === "prod";

  return (
    <Helmet
      title={loading ? "" : title || defaultTitle}
      meta={[
        {
          name: "theme-color",
          content: "#008f68",
        },
      ]}
    >
      <link id="favicon" rel="icon" href={favicon || logo || defaultFavicon} type="image/x-icon" />
      {document.querySelectorAll("*, span").forEach((node) => {
        node.style.fontFamily = fontType || "SF Pro Text";
      })}

      {document.querySelectorAll("body, .Polaris-Frame").forEach((node) => {
        node.style.backgroundColor = backgroundColor;
      })}

      {/* <!-- </Helmet>*<!-- Start Global Site Tag (gtag.js) - Google Analytics Embed Code --> */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-106553292-1" />
      <script>
        {isProd &&
          `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)};
          gtag('js', new Date());
          gtag('config', 'UA-106553292-1');
      `}
      </script>
      {/* <!-- End of Global Site Tag (gtag.js) - Google Analytics Embed Code --> */}

      {/* <!-- Begin Inspectlet Asynchronous Code --> */}
      <script type="text/javascript">
        {isProd &&
          `
        (function() {
          var insp_ab_loader = true; // set to false to disable A/B optimized loader
          window.__insp = window.__insp || [];
          __insp.push(['wid', 1418309130]);
          var ldinsp = function(){
          if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=1418309130&r=' + Math.floor(new Date().getTime()/3600000); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x);if(typeof insp_ab_loader != "undefined" && insp_ab_loader){ var adlt = function(){ var e = document.getElementById('insp_abl'); if(e){ e.parentNode.removeChild(e); __insp.push(['ab_timeout']); }}; var adlc = "body{ visibility: hidden !important; }"; var adln = typeof insp_ab_loader_t != "undefined" ? insp_ab_loader_t : 800; insp.onerror = adlt; var abti = setTimeout(adlt, adln); window.__insp_abt = abti; var abl = document.createElement('style'); abl.id = "insp_abl"; abl.type = "text/css"; if(abl.styleSheet) abl.styleSheet.cssText = adlc; else abl.appendChild(document.createTextNode(adlc)); document.head.appendChild(abl); } };
          setTimeout(ldinsp, 0);
        })();
      `}
      </script>
      {/* <!-- End Inspectlet Asynchronous Code --> */}

      {/* <!-- Begin Weglot Code --> */}
      <script type="text/javascript" src="https://cdn.weglot.com/weglot.min.js"></script>
      <script>
        {isProd &&
          `
        Weglot.initialize({
          api_key: "wg_30c498e5ef7a7beebd80c39b703a20276",
        });
      `}
      </script>
      {/* <!-- End Weglot Code --> */}
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  favicon: PropTypes.string,
  logo: PropTypes.string,
};

SEO.defaultProps = {
  logo: "",
  title: "",
  favicon: "",
};

export default SEO;
