// import packages
import React, { useState, useContext, useCallback, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Card,
  Collapsible,
  Caption,
  Form,
  FormLayout,
  Layout,
  PageActions,
  SettingToggle,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import { ActionButton } from "app/setup/modules/operator/features/productTagSetting/style";

// import context
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

import { useMutation, useQuery } from "@apollo/react-hooks";

// import propTypes
import { advanceProp } from "app/setup/modules/operator/features/commission/props";

// import components
import { Banner, Spinner, Toast } from "lib/components";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import queries
import { GET_METAFIELD_SETTING } from "app/product/apollo/queries";
import { GET_MANAGE_METAFIELD_SETTING } from "app/productOld/apollo/queries";

import { UPDATE_METAFIELD } from "app/productOld/apollo/mutations";

import ProductMetafieldAdd from "app/productOld/modules/operator/features/metafield/components/add";
import AdvanceMetaFields from "./components/advance";
import OperatorMetafieldKeySetting from "./components/metaFieldkey";

const ProductMetafield = () => {
  const { cms = {}, history } = useContext(PrivateContext);

  const [disableNameKey, setDisableNameKey] = useState([]);
  const [state, setState] = useState([]);
  const [metaData, setMetaData] = useState([]);

  // const [active, setActive] = useState(false);
  // const [collapsOpen, setCollapseOpen] = useState(false);
  const [submitEnabled, setSubmitEnable] = useState(false);

  const [banner, setBanner] = useState({ isOpen: false, status: "", title: "", message: "" });
  const [message, setMessage] = useState("");
  const [updateAt, setUpdateAt] = useState("");
  const [isAdvanceActive, setIsAdvanceActive] = useState(false);
  const [isMetaFieldEnable, setIsMetafieldEnable] = useState(false);
  const [isEnabledData, setIsEnabledData] = useState(false);
  const [keyUpdateAt, setKeyUpdateAt] = useState("");

  const selectValue = {
    weight: constant.WEIGHT,
    dimension: constant.DIMENSION,
    volume: constant.VOLUME,
    color: constant.COLOR,
    date: constant.DATE,
    dateTime: constant.DATE_TIME,
    url: constant.url,
    rating: constant.RATING,
    file: constant.FILE,
    valueType: "valueType",
    // string: constant.STRING,
  };

  const { loading: catalogLoading, data: metaFieldData, error: getProductTagSettingError, refetch } = useQuery(
    GET_METAFIELD_SETTING
  );

  const { loading: loadingManageMetaField, data: dataManageMetaField, refetch: metaketRefetch } = useQuery(
    GET_MANAGE_METAFIELD_SETTING
  );

  const [updateMetafieldSetting, { loading: updateTagSettingLoading }] = useMutation(UPDATE_METAFIELD);

  useEffect(() => {
    if (metaFieldData) {
      const metafieldSetting = baseHelper.getResponseData(metaFieldData, constant.gql.GET_METAFIELD_SETTING) || {};
      const { metafield = [], updatedAt } = metafieldSetting;
      refetch();
      setUpdateAt(updatedAt);
      if (metafield && metafield.length) {
        setState([...metafield]);
        setIsAdvanceActive(true);
        const mapMetafield = metafield.map((item) => ({ label: item.name, value: item.key }));
        setMetaData(mapMetafield);
      } else {
        setIsAdvanceActive(false);
      }
    }
  }, [metaFieldData, refetch]);

  useEffect(() => {
    if (dataManageMetaField) {
      const metaFieldkeyResponse = baseHelper.getResponseData(
        dataManageMetaField,
        constant.gql.GET_MANAGE_METAFIELD_KEYS
      );
      if (metaFieldkeyResponse) {
        const { isEnabled = {}, updatedAt } = metaFieldkeyResponse;
        setKeyUpdateAt(updatedAt);
        setIsEnabledData(isEnabled);
        setIsMetafieldEnable(isEnabled);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataManageMetaField]);

  // load product catalog data

  const updateVal = (option, val, item, isAdvance = false) => {
    setSubmitEnable(true);
    setBanner({ isOpen: false, status: "", title: "" });
    const advancedValue = [...state];
    if (option === constant.NAME) {
      advancedValue[item].name = null;
    }
    if (option === constant.VALUE) {
      advancedValue[item].value = null;
    }
    if (option === constant.VALUE && isAdvance) {
      advancedValue[item].value = val;
    }
    if (option === constant.VALUE_TYPE) {
      advancedValue[item].value = null;
    }
    if (option === constant.CONTENT_TYPE) {
      advancedValue[item].value = null;
      advancedValue[item].contentType = val;
    } else {
      if (isMetaFieldEnable && option === constant.NAME) {
        advancedValue[item].key = (val || "").toLowerCase();
      }
      advancedValue[item][option] = val;
    }
    if ([constant.JSON, constant.TRUE_FALSE, constant.MONEY].includes(val) && option === constant.CONTENT_TYPE) {
      advancedValue[item].value = constant.ONE;
    }

    setState(advancedValue);
  };

  const uniqueVal = (option, val, item) => {
    const advancedValue = [...state];
    if (option === constant.NAMESPACE || option === constant.KEY) {
      advancedValue.forEach((advanceData, index) => {
        if (((!isMetaFieldEnable && advanceData.namespace === val) || advanceData.key === val) && item !== index) {
          const uniquekey = advanceData.key === val ? (advancedValue[item].key = null) : "";
          if (!isMetaFieldEnable) {
            // eslint-disable-next-line no-unused-expressions
            advanceData.namespace === val ? (advancedValue[item].namespace = null) : uniquekey;
          }
          setBanner({ isOpen: true, status: constant.CRITICAL, title: cms("message.error.unique") });
        }
        return null;
      });
    }
    setState(advancedValue);
  };

  const add = () => {
    const { length } = state;
    if (length > 1) {
      const line = state[length - 1];
      const keys = Object.keys(line);
      let isInvalid = false;
      keys.forEach((key) => {
        if (key !== "isRequired" && key !== "valueType" && !line[key]) {
          isInvalid = true;
        }
      });
      if (isInvalid) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: cms("message.error.row") });
        return;
      }
    }

    const row = {
      contentType: null,
      key: null,
      name: null,
      value: null,
      namespace: isMetaFieldEnable ? constant.CUSTOM : null,
    };
    setDisableNameKey([...disableNameKey, state.length]);
    state.push(row);
    setState([...state]);
    setSubmitEnable(true);
  };

  const removeItem = (item) => {
    setSubmitEnable(true);
    state.splice(item, 1);
    const disableFields = [...disableNameKey];
    disableFields.forEach((key, index) => {
      disableFields[index] = key - 1;
    });
    setDisableNameKey(disableFields);
    setState([...state]);
  };

  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "", message: "" });

  const validateValue = () => {
    let isInvalid = false;
    let isKeyInvalid = false;
    let isNamespaceInvalid = false;
    const validation = state.every((item) => [constant.JSON, constant.TRUE_FALSE, constant.MONEY].includes(item.contentType) || item.value);
    state.forEach((line) => {
      const keys = Object.keys(line);
      keys.forEach((key) => {
        if (key === "value") {
          return;
        }
        if (!line[key] || !validation) {
          isInvalid = true;
        }
        if (!isMetaFieldEnable && key === constant.NAMESPACE && !isInvalid && line[key]?.length < 3) {
          isNamespaceInvalid = true;
        }
        if (key === constant.KEY && !isInvalid && line[key]?.length < 3) {
          isKeyInvalid = true;
        }
      });
      if (isInvalid) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: cms("message.error.row") });
      }
      if (!isInvalid && isKeyInvalid && isNamespaceInvalid) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: cms("message.error.both"),
        });
      } else {
        if (!isInvalid && isKeyInvalid) {
          const messageData = isEnabledData ? cms("message.error.displayName") : cms("message.error.key");
          setBanner({ isOpen: true, status: constant.CRITICAL, title: messageData });
        }
        if (!isInvalid && isNamespaceInvalid) {
          setBanner({
            isOpen: true,
            status: constant.CRITICAL,
            title: cms("message.error.namespace"),
          });
        }
      }
    });
    return isInvalid || isKeyInvalid || isNamespaceInvalid;
  };

  // const handleToggle = useCallback(() => {
  //   setActive(!active);
  //   setCollapseOpen(!collapsOpen);
  // });

  const handleSubmit = async () => {
    try {
      if (!validateValue()) {
        const advancedValue = [...state];
        advancedValue.forEach((advanceData, index) => {
          if (advanceData.contentType === constant.MEASUREMENT && advanceData && advanceData.value) {
            advancedValue[index].value =
              advanceData && _.isArray(advanceData.value) ? advanceData.value : [advanceData.value];
          }
        });
        setState(advancedValue);
        setSubmitEnable(false);
        setDisableNameKey([]);

        const res = await updateMetafieldSetting({
          variables: {
            input: { metafield: state },
          },
        });
        const resData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_METAFIELD);
        const resError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_METAFIELD);
        if (resError) {
          setBanner({
            status: constant.CRITICAL,
            isOpen: true,
            title: resError,
          });
        }
        if (resData && !updateAt && validateValue) {
          setMessage(cms("message.success.metafield"));
        }
        if (resData && validateValue) {
          setMessage(cms("message.success.update"));
        }
        setTimeout(() => {
          // history.push("/products");
        }, 1500);
        refetch();
      }
    } catch (exception) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(exception),
      });
    }
  };

  // const contentStatus = active ? constant.displayStatus.DISABLE : constant.ENABLE;

  if (catalogLoading || loadingManageMetaField) {
    return <Spinner />;
  }

  return (
    <>
      <div className="metafield-card">
        <OperatorMetafieldKeySetting
          metaketRefetch={metaketRefetch}
          isEnabledData={isEnabledData}
          setIsEnabledData={setIsEnabledData}
          keyUpdateAt={keyUpdateAt}
          history={history}
        />
        <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
          <Card
            title={[
              cms("cardTitle"),
              <TextStyle variation="subdued">
                {updateAt && <Caption>{`${baseHelper.lastUpdateDate(updateAt)}`}</Caption>}
              </TextStyle>,
            ]}
          >
            <Card.Section>
              <TextContainer>{cms("cardDescription")}</TextContainer>
              {/* <div className="toggle-action">
            <SettingToggle
              action={{
                content: contentStatus,
                onAction: handleToggle,
                destructive: active,
              }}
              enabled={active}
            >
              <TextStyle variation="strong">{cms("title")}</TextStyle>
              <div className="card-discription">
                <TextContainer>{cms("cardDescription")}</TextContainer>
              </div>
            </SettingToggle>
          </div>
          <br /> */}
              <br />
              <Collapsible
                open
                id="advancedCommission"
                transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
                expandOnPrint
              >
                {banner.isOpen && (
                  <>
                    <Banner
                      isOpen={banner.isOpen}
                      status={banner.status}
                      title={banner.title}
                      isScrollTop={false}
                      onDismiss={() => dismissBanner()}
                    >
                      {banner.message}
                    </Banner>
                    <br />
                  </>
                )}
                <Form>
                  <div className="stack-scroll">
                    <FormLayout>
                      <ProductMetafieldAdd
                        advancedValue={state}
                        removeItem={removeItem}
                        updateVal={updateVal}
                        count={state.length}
                        uniqueVal={uniqueVal}
                        disableNameKey={disableNameKey}
                        selectValue={selectValue}
                        isMetaFieldEnable={isMetaFieldEnable}
                      />
                    </FormLayout>
                  </div>
                  <Button plain id="addLink" onClick={add}>
                    {`+${cms("label.metafield")}`}
                  </Button>
                  <br />
                  <ActionButton>
                    {updateAt && (
                      <div className="metafield-action-button">
                        <PageActions
                          primaryAction={{
                            content: cms("common.button.update"),
                            onAction: () => handleSubmit(),
                            disabled: !submitEnabled,
                            loading: updateTagSettingLoading,
                          }}
                          secondaryActions={[
                            {
                              content: cms("common.button.cancel"),
                              onAction: () => history.push("/setting"),
                            },
                          ]}
                        />
                      </div>
                    )}
                  </ActionButton>
                </Form>
              </Collapsible>
            </Card.Section>
          </Card>
          {!updateAt && (
            <PageActions
              primaryAction={{
                content: cms("common.button.submit"),
                onAction: () => handleSubmit(),
                disabled: !submitEnabled,
                loading: updateTagSettingLoading,
              }}
              secondaryActions={[
                {
                  content: cms("common.button.cancel"),
                  onAction: () => history.push("/setting"),
                },
              ]}
            />
          )}
          <div className="toast">
            <Toast message={message} setToast={setMessage} />
          </div>
        </Layout.AnnotatedSection>
        {isAdvanceActive && <AdvanceMetaFields metaData={metaData} setBanner={setBanner} />}
      </div>
    </>
  );
};

ProductMetafield.propTypes = advanceProp.type;

export default withFeature(withErrorBoundary(ProductMetafield), { feature: constant.PRODUCT_METAFIELD_SETTING });
