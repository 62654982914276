import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import {
  ActionList,
  Badge,
  Button,
  Caption,
  Card,
  Layout,
  Link,
  Popover,
  ResourceItem,
  ResourceList,
  Stack,
  TextStyle,
  Thumbnail,
  Tooltip,
} from "@shopify/polaris";

import { ViewMajor } from "@shopify/polaris-icons";

import { baseHelper, imageHelper } from "lib/helpers";
import { GET_ORDER_LAKE_LINE_VIEW } from "app/orderLake/apollo/queries";
import { NotFound } from "layout/private/components";
import { Spinner } from "lib/components";
import constant from "lib/constant/constant";
import listData from "app/orderLake/modules/operator/features/list/orderListData";
import { PrivateContext } from "lib/context";

const LineItem = (props) => {
  const { cms, id: orderViewId, moneyFormat, orderId, brandName } = props;

  const { history } = useContext(PrivateContext);
  const { mainLink } = listData(cms);

  const [viewLineOrderData, setViewLineOrderData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [totalOrderItem, setTotalOrderItem] = useState();
  const [totalOrderVendor, setTotalOrderVendor] = useState();
  const [popoverActive, setPopoverActive] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);

  const { loading: loadingOrderLineView, data: dataOrderLineView } = useQuery(GET_ORDER_LAKE_LINE_VIEW, {
    variables: { input: { orderId } },
  });

  useEffect(() => {
    if (dataOrderLineView) {
      const orderLineView = baseHelper.getResponseData(dataOrderLineView, constant.gql.GET_ORDER_LAKE_LINE_VIEW);
      const orderLineViewError = baseHelper.getResponseError(dataOrderLineView, constant.gql.GET_ORDER_LAKE_LINE_VIEW);
      if (orderLineView) {
        setViewLineOrderData(orderLineView);
        setTotalOrderItem(orderLineView.length);
        const vendorsSum = [];
        orderLineView.filter(({ vendor }) => {
          vendorsSum.push(vendor);
          return vendorsSum;
        });
        const vendorCount = [...new Set(vendorsSum)];
        setTotalOrderVendor(vendorCount.length);
        const totalItemPrice =
          orderLineView &&
          orderLineView.map(({ price, basePrice, quantity }) => parseFloat(parseFloat(price || basePrice) * quantity));
        const totalAmount = totalItemPrice.reduce((cur, acc) => cur + acc, constant.value.ZERO);
        if (totalAmount) {
          setTotalPrice(totalAmount);
        }
      }
      if (orderLineViewError) {
        setIsError(true);
      }
    }
  }, [dataOrderLineView, setTotalOrderItem, setTotalOrderVendor]);

  if (loadingOrderLineView || !totalOrderItem || !totalOrderVendor) {
    return <Spinner />;
  }

  const renderItem = (item) => {
    const {
      _id: id,
      image,
      orderId: lineItemOrderId,
      price,
      basePrice,
      quantity,
      name: productName,
      storeStatus,
      shippingStatus,
    } = item;

    const orderStatus =
      (shippingStatus === constant.DISPATCHED && constant.COMPLETE) ||
      (shippingStatus === constant.AWAITING_DISPATCH && constant.PROCESS) ||
      storeStatus;

    const size = constant.LARGE;
    const thumbnailImage = image
      ? imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL })
      : constant.NOIMAGESNAP;
    const thumbnail = <Thumbnail source={thumbnailImage} size={size} />;
    const togglePopoverActive = () => {
      setPopoverActive({ [id]: !popoverActive[id] });
    };
    const activator = (
      <Button onClick={togglePopoverActive} disclosure>
        Manage
      </Button>
    );

    const manageActions = (value) => {
      if (value === constant.VIEW_ID) {
        history.push(`${mainLink.orderNumber}${lineItemOrderId}`);
      }
    };

    const option = [
      {
        content: constant.VIEW_LABEL,
        icon: ViewMajor,
        onAction: () => manageActions(constant.VIEW_ID),
      },
    ];

    const pageContent = {
      label: {
        status: cms("section.orderList.label.status"),
        fulfillmentStatus: cms("section.orderList.label.fulfillmentStatus"),
      },
    };

    return (
      <ResourceItem accessibilityLabel={`View details for ${id}`}>
        <Card.Section>
          <Stack sectioned>
            <Stack.Item>{thumbnail}</Stack.Item>
            <Stack.Item fill>
              <div className="card-description ">
                <Link onClick={() => history.push(`${mainLink.orderNumber}${orderViewId}`)} removeUnderline>
                  {productName}
                </Link>
                <Caption>
                  <TextStyle variation="subdued">
                    {`${cms("section.orderList.label.vendor")}: `}
                    {brandName}
                  </TextStyle>
                </Caption>
                <Stack>
                  {orderStatus && (
                    <Stack.Item>
                      <Tooltip content={pageContent.label.status} preferredPosition="above">
                        <Badge status={baseHelper.getBadgeType(orderStatus)}>{baseHelper.ucFirst(orderStatus)}</Badge>
                      </Tooltip>
                    </Stack.Item>
                  )}
                </Stack>
              </div>
            </Stack.Item>
            <Stack.Item>
              <TextStyle>{quantity}</TextStyle>
            </Stack.Item>
            <Stack.Item>
              <Tooltip content={`${(parseFloat(price || basePrice) * quantity).toFixed(2)}`}>
                <span className="fix-content">{`${moneyFormat}${parseFloat(price || basePrice) * quantity}`}</span>
              </Tooltip>
              <br />
              {/* <br /> */}
              <div className="manage-button">
                <Popover
                  active={popoverActive[id]}
                  activator={activator}
                  onClose={() => setPopoverActive({ [id]: !popoverActive[id] })}
                  setActive={() => setPopoverActive({ [id]: !popoverActive[id] })}
                >
                  <ActionList items={option} />
                </Popover>
              </div>
            </Stack.Item>
          </Stack>
        </Card.Section>
      </ResourceItem>
    );
  };

  return (
    <>
      {isError && (
        <Layout.Section>
          <div style={{ display: "flex" }}>
            <NotFound />
          </div>
        </Layout.Section>
      )}
      <div className="card-header">
        <Card sectioned>
          <Stack>
            <Stack.Item>Product</Stack.Item>
            <Stack.Item fill>Description</Stack.Item>
            <Stack.Item>Quantity</Stack.Item>
            <Stack.Item>Price</Stack.Item>
          </Stack>
        </Card>
      </div>
      <ResourceList items={viewLineOrderData} renderItem={renderItem} />
      <div className="card-footer">
        <Card sectioned>
          <Stack>
            <Stack.Item fill>
              <TextStyle variation="strong">Total</TextStyle>
            </Stack.Item>
            <Stack.Item>
              <span className="light-text">{`(${totalOrderItem} ${totalOrderItem > 1 ? "Items" : "Item"})`}</span>
              <TextStyle variation="strong">{`${moneyFormat}${totalPrice.toFixed(2)}`}</TextStyle>
            </Stack.Item>
          </Stack>
        </Card>
      </div>
    </>
  );
};

export default LineItem;
