import Bowser from "bowser";
import QueryString from "query-string";
import cryptoJS from "crypto";
import {
  attempt,
  isEqual,
  isError,
  isObject,
  isUndefined,
} from "lodash";
import moment from "moment";

// import config
import constant from "lib/constant/constant";
import config from "configuration";

// import helper
import chatHelper from "./chat";
import localizeHelper from "./localize";
// import currency from "app/setup/modules/operator/features/currency";
import accessHelper from "./access";
// import { baseHelper } from ".";

const currencyData = {
  AED: "د.إ",
  AFN: "؋",
  ALL: "L",
  AMD: "֏",
  ANG: "ƒ",
  AOA: "Kz",
  ARS: "$",
  AUD: "$",
  AWG: "ƒ",
  AZN: "₼",
  BAM: "KM",
  BBD: "$",
  BDT: "৳",
  BGN: "лв",
  BHD: ".د.ب",
  BIF: "FBu",
  BMD: "$",
  BND: "$",
  BOB: "$b",
  BRL: "R$",
  BSD: "$",
  BTC: "฿",
  BTN: "Nu.",
  BWP: "P",
  BYR: "Br",
  BYN: "Br",
  BZD: "BZ$",
  CAD: "$",
  CDF: "FC",
  CHF: "CHF",
  CLP: "$",
  CNY: "¥",
  COP: "$",
  CRC: "₡",
  CUC: "$",
  CUP: "₱",
  CVE: "$",
  CZK: "Kč",
  DJF: "Fdj",
  DKK: "kr",
  DOP: "RD$",
  DZD: "دج",
  EEK: "kr",
  EGP: "£",
  ERN: "Nfk",
  ETB: "Br",
  ETH: "Ξ",
  EUR: "€",
  FJD: "$",
  FKP: "£",
  GBP: "£",
  GEL: "₾",
  GGP: "£",
  GHC: "₵",
  GHS: "GH₵",
  GIP: "£",
  GMD: "D",
  GNF: "FG",
  GTQ: "Q",
  GYD: "$",
  HKD: "$",
  HNL: "L",
  HRK: "kn",
  HTG: "G",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  IMP: "£",
  INR: "₹",
  IQD: "ع.د",
  IRR: "﷼",
  ISK: "kr",
  JEP: "£",
  JMD: "J$",
  JOD: "JD",
  JPY: "¥",
  KES: "KSh",
  KGS: "лв",
  KHR: "៛",
  KMF: "CF",
  KPW: "₩",
  KRW: "₩",
  KWD: "KD",
  KYD: "$",
  KZT: "лв",
  LAK: "₭",
  LBP: "£",
  LKR: "₨",
  LRD: "$",
  LSL: "M",
  LTC: "Ł",
  LTL: "Lt",
  LVL: "Ls",
  LYD: "LD",
  MAD: "MAD",
  MDL: "lei",
  MGA: "Ar",
  MKD: "ден",
  MMK: "K",
  MNT: "₮",
  MOP: "MOP$",
  MRO: "UM",
  MRU: "UM",
  MUR: "₨",
  MVR: "Rf",
  MWK: "MK",
  MXN: "$",
  MYR: "RM",
  MZN: "MT",
  NAD: "$",
  NGN: "₦",
  NIO: "C$",
  NOK: "kr",
  NPR: "₨",
  NZD: "$",
  OMR: "﷼",
  PAB: "B/.",
  PEN: "S/.",
  PGK: "K",
  PHP: "₱",
  PKR: "₨",
  PLN: "zł",
  PYG: "Gs",
  QAR: "﷼",
  RMB: "￥",
  RON: "lei",
  RSD: "Дин.",
  RUB: "₽",
  RWF: "R₣",
  SAR: "﷼",
  SBD: "$",
  SCR: "₨",
  SDG: "ج.س.",
  SEK: "kr",
  SGD: "$",
  SHP: "£",
  SLL: "Le",
  SOS: "S",
  SRD: "$",
  SSP: "£",
  STD: "Db",
  STN: "Db",
  SVC: "$",
  SYP: "£",
  SZL: "E",
  THB: "฿",
  TJS: "SM",
  TMT: "T",
  TND: "د.ت",
  TOP: "T$",
  TRL: "₤",
  TRY: "₺",
  TTD: "TT$",
  TVD: "$",
  TWD: "NT$",
  TZS: "TSh",
  UAH: "₴",
  UGX: "USh",
  USD: "$",
  UYU: "$U",
  UZS: "лв",
  VEF: "Bs",
  VND: "₫",
  VUV: "VT",
  WST: "WS$",
  XAF: "FCFA",
  XBT: "Ƀ",
  XCD: "$",
  XOF: "CFA",
  XPF: "₣",
  YER: "﷼",
  ZAR: "R",
  ZWD: "Z$",
};
const { displayPlan, displayStatus, financialStatus, quoteStatus, userKey, productVersion, IS_SERVICE } = constant;
const { newZeaLandPostAPIUrl } = config;
class BaseHelper {
  constructor() {
    this.sellerRole = userKey.seller;
    this.vendorRole = userKey.vendor;
  }

  fulfillmentDetails = (fulfillment, currentUser) => {
    if (!fulfillment) {
      return false;
    }

    const { _id } = currentUser;
    const id = this.mongoIdAsString(_id);
    const { consignment = {} } = fulfillment;
    const { global = {}, addresses = [] } = consignment || {};
    const vendorDetails =
      (addresses &&
        addresses.length &&
        addresses
          .map((item) => {
            const existVendor = item.vendorIds.find((vendorItem) => vendorItem === id);
            if (existVendor) {
              const { address } = item;
              return address;
            }
            return false;
          })
          .filter((item) => item)) ||
      false;

    let shippingDetails = {};

    if (vendorDetails && vendorDetails.length) {
      shippingDetails = vendorDetails[0] || {};
    } else {
      shippingDetails = global;
    }

    return shippingDetails;
  };

  /**
   * @desc To get current browser name
   */
  getBrowserName = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getBrowserName();
  };

  /**
   * @desc To get current browser version
   */
  getBrowserVersion = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getBrowserVersion();
  };

  /**
   * @desc To get user details for chat
   * @param currentUser
   *
   * @return userDetails consists name, email, role, plan name and plan price
   */
  getChatUser = (currentUser) => {
    const role = accessHelper.getUserRole(currentUser);
    const userEmail = currentUser.email && currentUser.email.address;
    const name = this.getFullName(currentUser);
    const plan = {
      name: (currentUser.plan && currentUser.plan.name) || "",
      price: (currentUser.plan && currentUser.plan.price) || "",
    };

    const { _id: currentUserId = null, createdAt = "" } = currentUser || {};

    const dateFreeTrialStarted = moment.utc(createdAt).format("YYYY-MM-DD");

    const userDetails = {
      name,
      plan,
      role,
      city: currentUser.city,
      country: currentUser.country,
      email: userEmail,
      id: this.mongoIdAsString(currentUserId),
      dateFreeTrialStarted,
    };

    return userDetails;
  };

  /**
   * @desc To get current domain
   *
   * @return domain
   *
   */
  getCurrentDomain = (location, string = "") => `${location.protocol}//${location.host}/${string}`;

  getCurrencyPrice = (amount, currencyData) => {
    let price = (amount * currencyData.price).toFixed(2);
    price = (price && `${currencyData.currencySymbol}${price}`) || "";
    return price;
  };

  /* @desc To get accounting status details of provided order lines
   * @param {Array} orderLine array of order lines
   *
   * @return status as paid, unpaid, partially paid
   *
   */
  getOrderAccountingStatus = (orderLine) => {
    let paid = 0;
    let unpaid = 0;

    orderLine.forEach((item) => {
      if (item.accountingStatus === constant.PAID) {
        paid += 1;
      } else {
        unpaid += 1;
      }
    });
    if (paid === orderLine.length) {
      return constant.PAID;
    }
    return constant.UNPAID;
  };

  /**
   * @desc To return res data/false for given object
   * @param res
   *
   * @return res data/false
   *
   */
  getData = (res) => {
    if (isUndefined(res) || !res || res.status !== constant.OK) {
      return false;
    }
    return res.data;
  };

  /**
   * @desc To return day list of month
   * @param noOfDays
   *
   * @return options array
   *
   */
  getDate = (noOfDays = 28) => {
    const options = [];
    let i = 1;
    for (i; i <= noOfDays; i += 1) {
      options.push(String(i));
    }
    return options;
  };

  /**
   * @desc To get financial status name
   *
   * @param {String} status financial status
   *
   * @return {String} name of the financial status
   */
  getFinancialStatus = (status) => {
    switch (status) {
      case financialStatus.PENDING:
        return financialStatus.PAYMENT_PENDING;
      case financialStatus.AUTHORIZED:
        return this.ucFirst(financialStatus.AUTHORIZED);
      case financialStatus.PARTIALLY_PAID:
        return financialStatus.PAID_PARTIALLY;
      case financialStatus.PAID:
        return this.ucFirst(financialStatus.PAID);
      case financialStatus.PARTIALLY_REFUNDED:
        return financialStatus.REFUNDED_PARTIALLY;
      case financialStatus.REFUNDED:
        return this.ucFirst(financialStatus.REFUNDED);
      case financialStatus.VOIDED:
        return this.ucFirst(financialStatus.VOIDED);
      default:
        break;
    }
    return "";
  };

  /**
   * @desc To get financial status name
   *
   * @param {String} status financial status
   *
   * @return {String} name of the financial status
   */
   getQuoteFinancialStatus = (status) => {
    switch (status) {
      case financialStatus.PENDING:
        return financialStatus.INVOICE_PENDING;
      case financialStatus.AUTHORIZED:
        return this.ucFirst(financialStatus.AUTHORIZED);
      case financialStatus.PARTIALLY_PAID:
        return financialStatus.PAID_PARTIALLY;
      case financialStatus.PAID:
        return this.ucFirst(financialStatus.PAID);
      case financialStatus.PARTIALLY_REFUNDED:
        return financialStatus.REFUNDED_PARTIALLY;
      case financialStatus.REFUNDED:
        return this.ucFirst(financialStatus.REFUNDED);
      case financialStatus.VOIDED:
        return this.ucFirst(financialStatus.VOIDED);
      default:
        break;
    }
    return "";
  };

  /**
   * @desc To return day list of month
   * @param numberOfDays
   *
   * @return options array
   *
   */
  getDates = (numberOfDays = 30) => {
    const options = [];
    let i = 1;
    for (i; i <= numberOfDays; i += 1) {
      options.push(String(i));
    }
    return options;
  };

  /**
   * @desc To return time list of day
   * @param noOfDays
   *
   * @return options array
   *
   */
  getTime = () => {
    const options = [];

    // for time duration in 24 hours
    const TIME_DURATION = 23;

    let i = 0;
    for (i; i <= TIME_DURATION; i += 1) {
      const time = i < 10 ? `0${i}:00` : `${i}:00`;
      options.push(time);
    }
    return options;
  };

  /**
   * @desc To get param from url
   * @param params object
   * @param key string
   *
   * @return url
   *
   */

  getParam = (_ref12, key = "id") => {
    const { params } = _ref12;
    return (key && params[key]) || "";
  };

  /**
   * @desc To get full name of user
   * @param user object
   *
   * @return full name of user
   *
   */
  /* @desc To get price details of order
   * @param {Array} lineData array of order line items
   *
   * @return newPrice price upto 2 decimals
   *
   */
  getPrice = (price) => {
    const newPrice = parseFloat(price || 0).toFixed(2);
    return newPrice;
  };

  /**
 * @desc To get price details of order
 * @param {Array} lineData array of order line items
/**
 * @desc To get price upto 2 decimals
 * @param price price
 *
 * @return newPrice price upto 2 decimals
 *
 */
  getOrderPrice = (lineData = [], orderData, isSeller, isPackingSlip = false, isInvoiceSlip = false, fulfilledData) => {
    let actualQuantity = 0;
    let commissionSeller = 0;
    let commissionTax = 0;
    let commissionVendor = 0;
    let discount = 0;
    let isUpdatedOrderLine = false;
    let markUp = 0;
    let markUpItems = 0;
    let orderDiscount = 0;
    let price = 0;
    let sellerDiscount = 0;
    let shippingAmount = 0;
    let shippingTax = 0;
    let tax = 0;
    let taxRate = 0;
    let totalAmount = 0;
    let vendorDiscount = 0;
    const isTaxIncluded = !!orderData.taxesIncluded;

    lineData
      .filter(
        (item) =>
          !!item &&
          !(
            (item.status === constant.REJECTED &&
              (item.fulfillmentStatus === constant.UNFULFILLED || item.fulfillmentStatus === constant.REFUND)) ||
            (item.rejectedByVendor && item.status === constant.NEW)
          )
      )
      .forEach((data) => {
        let taxLineRate = 0;
        actualQuantity = data.actualQuantity || data.quantity;
        price += parseFloat(data.price) * actualQuantity;
        if (data.markUpPrice && data.markUpPrice !== "0" && data.markUpPrice !== 0) {
          markUpItems += 1;
        }
        markUp += parseFloat(data.markUpPrice) * actualQuantity;
        discount += parseFloat(data.totalDiscount || 0);
        sellerDiscount += parseFloat(data.sellerDiscount || 0) * actualQuantity;
        vendorDiscount += parseFloat(data.vendorDiscount || 0) * actualQuantity;
        shippingAmount += parseFloat(data.shippingAmount || 0);
        commissionTax += parseFloat(data.commissionTax || 0);
        shippingTax += parseFloat(data.shippingTax || 0);
        commissionSeller += parseFloat(data.commissionSeller || 0);
        commissionVendor += parseFloat(data.commissionVendor || 0);
        // eslint-disable-next-line no-shadow
        (data.discountAllocations || []).forEach((discount) => {
          orderDiscount += parseFloat(discount.amount || 0);
        });
        const amount = parseFloat(data.price || 0) * parseFloat(data.actualQuantity);
        totalAmount += parseFloat(amount || 0) - parseFloat(data.vendorDiscount || 0) * parseFloat(data.actualQuantity);
        const hasTotalTaxKey = this.hasKey(data, "totalTax");
        if (hasTotalTaxKey) {
          // to check if order line data is based upon new markUp changes or not
          isUpdatedOrderLine = true;
        }
        (data.taxLines || []).forEach((item) => {
          taxRate += parseFloat(item.price);
          taxLineRate += parseFloat(item.rate);
          return true;
        });

        if (isTaxIncluded && taxLineRate) {
          tax += parseFloat(data.price) * actualQuantity * (taxLineRate / (1 + taxLineRate));
        } else {
          tax += parseFloat(data.totalTax) * (data.actualQuantity || data.quantity);

          const currentfulfilledOrderLine =
            (fulfilledData && fulfilledData.find((filledOrderLine) => data.shopifyLineItemId === filledOrderLine.id)) ||
            {};
          tax += parseFloat(data.totalTax) * currentfulfilledOrderLine.actualQuantity || 0;
        }
      });

    // const orderShipping = shippingAmount || (orderData.shipping && orderData.shipping.amount);

    // const orderShipping = isSeller
    //   ? (orderData && orderData.shipping && orderData.shipping.amount) || 0
    //   : shippingAmount;

    const { shippingLines = [], taxLines = [] } = orderData;

    const marketcubeShipping = shippingLines.find(
      (shippingLine) => shippingLine.source === constant.MARKETCUBE_SHIPPING
    );

    if (marketcubeShipping) {
      const { tax_lines: shippingTaxLines = [] } = marketcubeShipping;

      let totalTaxRateOnShipping = 0;

      shippingTaxLines.forEach((taxLine) => {
        totalTaxRateOnShipping += taxLine.rate;
      });

      if (totalTaxRateOnShipping) {
        shippingAmount += parseFloat(shippingAmount * totalTaxRateOnShipping);
      }
    }

    const orderShipping = shippingAmount || (orderData && orderData.shipping && orderData.shipping.amount) || 0;

    let totalTaxRate = 0;

    taxLines &&
      taxLines.forEach((taxLine) => {
        totalTaxRate += taxLine.rate;
      });

    let totalPrice;
    if (isPackingSlip) {
      totalPrice = isSeller
        ? parseFloat(orderShipping) +
          parseFloat(price) +
          parseFloat(markUp) -
          parseFloat(sellerDiscount) -
          parseFloat(orderDiscount || 0)
        : parseFloat(shippingAmount) +
          parseFloat(price) +
          parseFloat(markUp) -
          parseFloat(sellerDiscount || discount) -
          parseFloat(orderDiscount || 0);
    } else if (isInvoiceSlip) {
      totalPrice = isSeller
        ? parseFloat(orderShipping) +
          parseFloat(shippingTax) +
          parseFloat(price) +
          parseFloat(markUp) -
          parseFloat(sellerDiscount) -
          parseFloat(vendorDiscount) -
          parseFloat(orderDiscount)
        : parseFloat(shippingAmount) + parseFloat(shippingTax) + parseFloat(price) - parseFloat(discount);
    } else {
      totalPrice = isSeller
        ? parseFloat(orderShipping) +
          parseFloat(price) +
          parseFloat(markUp) -
          parseFloat(sellerDiscount) -
          parseFloat(orderData.totalDiscount)
        : parseFloat(shippingAmount) +
          parseFloat(price) -
          parseFloat(orderDiscount || 0); // +
          // parseFloat(markUp) -
          // parseFloat(sellerDiscount || discount) -
          // parseFloat(orderDiscount || 0);
    }

    const { totalTaxSet = {} } = orderData;
    if (isSeller && totalTaxSet && totalTaxSet.shopMoney && totalTaxSet.shopMoney.amount) {
      taxRate = totalTaxSet.shopMoney.amount;
    }

    if (!isTaxIncluded) {
      // if (isPackingSlip) {
      //   totalPrice += parseFloat(orderData.totalTax);
      // } else {
      //   // totalPrice += isSeller ? parseFloat(orderData.totalTax) : parseFloat(tax);
      //   totalPrice += parseFloat(taxRate);
      // }
      totalPrice = isSeller ? totalPrice + parseFloat(tax) : totalPrice + parseFloat(tax);
    }

    return {
      commissionSeller,
      commissionTax,
      commissionVendor,
      discount,
      isUpdatedOrderLine,
      marketplaceDiscount: parseFloat(orderData.totalDiscount), // sellerDiscount
      markUp,
      markUpItems,
      price,
      sellerDiscount,
      shippingAmount,
      shippingTax,
      tax,
      taxRate,
      totalAmount,
      totalPrice,
      totalTaxRate,
      vendorDiscount,
    };
  };

  /**
   * @desc To get price details of orderLake
   * @param {Array} lineData array of order line items
   *
   * @return newPrice price upto 2 decimals
   *
   */
  getOrderLakePrice = (lineData = [], orderData, isSeller, isPackingSlip = false, isInvoiceSlip = false) => {
    let actualQuantity = 0;
    let commissionSeller = 0;
    let commissionTax = 0;
    let commissionVendor = 0;
    let discount = 0;
    let isUpdatedOrderLine = false;
    let markUp = 0;
    let markUpItems = 0;
    let orderDiscount = 0;
    let price = 0;
    let sellerDiscount = 0;
    let shippingAmount = 0;
    let shippingTax = 0;
    let tax = 0;
    let taxRate = 0;
    let totalAmount = 0;
    let vendorDiscount = 0;
    const isTaxIncluded = !!orderData.taxesIncluded;

    lineData
      .filter(
        (item) =>
          !!item &&
          !(
            item.status === constant.REJECTED &&
            (item.fulfillmentStatus === constant.UNFULFILLED || item.fulfillmentStatus === constant.REFUND)
          )
      )
      .forEach((data) => {
        let taxLineRate = 0;
        const isBigCommerceOrder = this.isBigCommerceUser(data);

        actualQuantity = data.actualQuantity || data.quantity;
        price += parseFloat(isBigCommerceOrder ? data.basePrice : data.price) * actualQuantity;
        if (data.markUpPrice && data.markUpPrice !== "0" && data.markUpPrice !== 0) {
          markUpItems += 1;
        }
        markUp += parseFloat(data.markUpPrice) * actualQuantity;
        discount += parseFloat(data.totalDiscount || 0);
        sellerDiscount += parseFloat(data.totalDiscount || 0) + parseFloat(data.sellerDiscount || 0) * actualQuantity;
        vendorDiscount += parseFloat(data.vendorDiscount || 0) * actualQuantity;
        shippingAmount += parseFloat(data.shippingAmount || 0);
        commissionTax += parseFloat(data.commissionTax || 0);
        shippingTax += parseFloat(data.shippingTax || 0);
        commissionSeller += parseFloat(data.commissionSeller || 0);
        commissionVendor += parseFloat(data.commissionVendor || 0);
        // eslint-disable-next-line no-shadow
        (data.discountAllocations || []).forEach((discount) => {
          orderDiscount += parseFloat(discount.amount || 0);
        });
        const amount = parseFloat(data.price || 0) * parseFloat(data.actualQuantity);
        totalAmount += parseFloat(amount || 0) - parseFloat(data.vendorDiscount || 0) * parseFloat(data.actualQuantity);
        const hasTotalTaxKey = this.hasKey(data, "totalTax");
        if (hasTotalTaxKey) {
          // to check if order line data is based upon new markUp changes or not
          isUpdatedOrderLine = true;
        }
        (data.taxLines || []).forEach((item) => {
          taxRate += parseFloat(item.price);
          taxLineRate += parseFloat(item.rate);
          return true;
        });

        if (isTaxIncluded && taxLineRate) {
          tax += parseFloat(data.price) * actualQuantity * (taxLineRate / (1 + taxLineRate));
        } else {
          tax += parseFloat(data.totalTax) * (data.actualQuantity || data.quantity);
        }
      });

    // const orderShipping = shippingAmount || (orderData.shipping && orderData.shipping.amount);

    // const orderShipping = isSeller
    //   ? (orderData && orderData.shipping && orderData.shipping.amount) || 0
    //   : shippingAmount;

    const { shippingLines = [], taxLines = [] } = orderData;

    const marketcubeShipping = shippingLines.find(
      (shippingLine) => shippingLine.source === constant.MARKETCUBE_SHIPPING
    );

    if (marketcubeShipping) {
      const { tax_lines: shippingTaxLines = [] } = marketcubeShipping;

      let totalTaxRateOnShipping = 0;

      shippingTaxLines.forEach((taxLine) => {
        totalTaxRateOnShipping += taxLine.rate;
      });

      if (totalTaxRateOnShipping) {
        shippingAmount += parseFloat(shippingAmount * totalTaxRateOnShipping);
      }
    }

    const orderShipping = isSeller
      ? (orderData && orderData.shipping && orderData.shipping.amount) || 0
      : shippingAmount;

    let totalTaxRate = 0;

    taxLines &&
      taxLines.forEach((taxLine) => {
        totalTaxRate += taxLine.rate;
      });

    let totalPrice;
    if (isPackingSlip) {
      totalPrice = isSeller
        ? parseFloat(orderShipping) +
          parseFloat(price) +
          parseFloat(markUp) -
          parseFloat(sellerDiscount) -
          parseFloat(orderDiscount || 0)
        : parseFloat(shippingAmount) +
          parseFloat(price) +
          parseFloat(markUp) -
          parseFloat(sellerDiscount || discount) -
          parseFloat(orderDiscount || 0);
    } else if (isInvoiceSlip) {
      totalPrice = isSeller
        ? parseFloat(shippingAmount) +
          parseFloat(shippingTax) +
          parseFloat(price) +
          parseFloat(markUp) -
          parseFloat(sellerDiscount) -
          parseFloat(vendorDiscount) -
          parseFloat(orderDiscount)
        : parseFloat(shippingAmount) + parseFloat(shippingTax) + parseFloat(price) - parseFloat(discount);
    } else {
      totalPrice = isSeller
        ? parseFloat(orderShipping) +
          parseFloat(price) +
          parseFloat(markUp) -
          parseFloat(sellerDiscount) -
          parseFloat(orderDiscount || 0)
        : parseFloat(shippingAmount) + parseFloat(price) - parseFloat(discount) - parseFloat(orderDiscount || 0);
    }

    const { totalTaxSet = {} } = orderData;
    if (isSeller && totalTaxSet && totalTaxSet.shopMoney && totalTaxSet.shopMoney.amount) {
      taxRate = totalTaxSet.shopMoney.amount;
    }

    if (!isTaxIncluded) {
      // if (isPackingSlip) {
      //   totalPrice += parseFloat(orderData.totalTax);
      // } else {
      //   // totalPrice += isSeller ? parseFloat(orderData.totalTax) : parseFloat(tax);
      //   totalPrice += parseFloat(taxRate);
      // }
      totalPrice = isSeller ? totalPrice + parseFloat(taxRate) : totalPrice + parseFloat(tax);
    }

    return {
      commissionSeller,
      commissionTax,
      commissionVendor,
      discount,
      isUpdatedOrderLine,
      markUp,
      markUpItems,
      orderDiscount,
      price,
      sellerDiscount: sellerDiscount || orderDiscount,
      shippingAmount,
      shippingTax,
      tax,
      taxRate,
      totalAmount,
      totalPrice,
      totalTaxRate,
      vendorDiscount,
    };
  };

  /**
   * @desc To get quote statuses
   *
   * @param {String} status cms
   * @param {String} status quote status
   *
   * @return {String} quote status
   */
  getQuoteStatus = (cms, status) => {
    return cms(`label.${status}`) || quoteStatus[status.toUpperCase()];
  };

  /**
   * @desc To get order price calculations for packing slip
   *
   * @param {Array} lineData array of lineitems
   * @param {Object} orderData order data
   * @param {Boolean} isSeller If seller (true/false)
   *
   * @return {Object} Object containing price calculations
   */
  getCalculationForSlip = (lineData = [], orderData, isSeller) => {
    let price = 0;
    let markUp = 0;
    let markUpItems = 0;
    let discount = 0;
    let sellerDiscount = 0;
    let tax = 0;
    let taxRate = 0;
    let isUpdatedOrderLine = false;
    let commissionSeller = 0;
    let commissionVendor = 0;
    let shippingAmount = 0;
    let orderDiscount = 0;
    let subTotal = 0;
    let actualQuantity = 0;
    const isTaxIncluded = !!orderData.taxesIncluded;
    lineData
      .filter((item) => !!item)
      .forEach((data) => {
        actualQuantity = !data.actualQuantity ? data.fulfillableQuantity : data.actualQuantity;
        price += parseFloat(data.price) * actualQuantity;
        if (data.markUpPrice && data.markUpPrice !== "0" && data.markUpPrice !== 0) {
          markUpItems += 1;
        }
        markUp += parseFloat(data.markUpPrice) * actualQuantity;
        discount += parseFloat(data.totalDiscount || 0);
        sellerDiscount += parseFloat(data.totalDiscount || 0) + parseFloat(data.sellerDiscount || 0) * actualQuantity;
        shippingAmount += parseFloat(data.shippingAmount || 0);
        commissionSeller += parseFloat(data.commissionSeller || 0);
        commissionVendor += parseFloat(data.commissionVendor || 0);
        tax += parseFloat(data.totalTax) * actualQuantity;
        // eslint-disable-next-line no-shadow
        (data.discountAllocations || []).forEach((discount) => {
          orderDiscount += parseFloat(discount.amount || 0);
        });
        const hasTotalTaxKey = this.hasKey(data, "totalTax");
        if (hasTotalTaxKey) {
          // to check if order line data is based upon new markUp changes or not
          isUpdatedOrderLine = true;
        }
        (data.taxLines || []).forEach((item) => {
          taxRate += parseFloat(item.price);
          return true;
        });
        subTotal +=
          (parseFloat(data.price) + parseFloat(data.markUpPrice) - parseFloat(data.sellerDiscount || 0)) *
          actualQuantity;
      });
    // const orderShipping = shippingAmount || (orderData.shipping && orderData.shipping.amount);

    // const orderShipping = isSeller
    //   ? (orderData && orderData.shipping && orderData.shipping.amount) || 0
    //   : shippingAmount;

    const { shipping: { amount = "" } = {}, shippingLines = [], taxLines = [], taxesIncluded = false } =
      orderData || {};
    let totalTaxRate = 0;

    taxLines.forEach((taxLine) => {
      totalTaxRate += taxLine.rate;
    });

    let totalPrice = parseFloat(subTotal) - parseFloat(discount) - parseFloat(orderDiscount);

    const marketcubeShipping = shippingLines.find(
      (shippingLine) => shippingLine.source === constant.MARKETCUBE_SHIPPING
    );
    const shopifyShipping = shippingLines.find((shippingLine) => shippingLine.source === constant.SHOPIFY);

    if (marketcubeShipping && !taxesIncluded) {
      const { tax_lines: shippingTaxLines = [] } = marketcubeShipping;

      let totalTaxRateOnShipping = 0;

      shippingTaxLines.forEach((taxLine) => {
        totalTaxRateOnShipping += taxLine.rate;
      });

      if (totalTaxRateOnShipping) {
        shippingAmount += parseFloat(shippingAmount * totalTaxRateOnShipping);
      }

      totalPrice += parseFloat(shippingAmount);
    } else {
      totalPrice += parseFloat(shippingAmount || amount);
    }

    if (shopifyShipping && isSeller) {
      const { price } = shopifyShipping; // tax_lines: shippingTaxLines = [],
      // shippingTaxLines.forEach((taxItem) => {
      //   const { rate = 0 } = taxItem;
      //   shippingCharge += rate;
      // });
      // taxRate += parseFloat(shippingCharge * amount);
      totalPrice += parseFloat(price || 0);
    }

    if (!isTaxIncluded) {
      totalPrice += taxRate;
    }

    return {
      commissionSeller,
      commissionVendor,
      discount: discount || orderDiscount,
      isUpdatedOrderLine,
      markUp,
      markUpItems,
      price,
      sellerDiscount: sellerDiscount || orderDiscount,
      shippingAmount: shippingAmount || amount,
      subTotal,
      tax,
      totalTaxRate,
      taxRate,
      totalPrice,
    };
  };

  /**
   * @desc To get get currency name
   *
   * @param {String} moneyFormat format of money
   * @param {Object} symbol symbol of money format
   * @param cms contains the hard coded text value
   *
   * @return {String} name of the currency
   */

  getCurrencyName = (moneyFormat, symbol, cms) => {
    switch (moneyFormat) {
      case symbol.DOLLAR:
        return cms("label.usd");
      case symbol.CANADIAN:
        return cms("label.cad");
      case symbol.EURO:
        return cms("label.eur");
      case symbol.RUPEE:
        return cms("label.inr");
      case symbol.RUPEES:
        return cms("label.inr");
      default:
        break;
    }
    return "";
  };

  /**
   * @desc To check, if the data object has the given key
   * @param data in which key will be checked
   * @param key to be checked
   *
   * @return {Boolean} check if the data object has the given key
   */
  hasKey = (data, key) => Object.prototype.hasOwnProperty.call(data, key);

  /**
   * @desc To address and city of user
   * @param user object
   *
   * @return object
   *
   */

  getFullName = (user) => {
    const { firstName = "", lastName = "" } = user;
    const fullName = `${(!!firstName && firstName) || ""} ${(!!lastName && lastName) || ""}`.trim();
    return fullName;
  };

  /**
   * @desc To get query params
   * @param url
   *
   * @return query params
   *
   */
  /**
   * @desc To address and city of user
   * @param user object
   *
   * @return object
   *
   */
  getFullAddress = ({ address, city, country, pinCode }) => {
    return {
      address,
      city,
      country,
      pinCode,
    };
  };

  getCustomerAddress = (data = {}) => {
    const customerAddress = data || {};
    Object.keys(data).forEach((key) => data[key] === null && delete customerAddress[key]);
    if (!Object.keys(customerAddress).length) {
      return false;
    }
    const { address1 = "", address2 = "", city = "", country = "", name = "", phone = "", zip = "" } =
      customerAddress || {};
    return {
      address1,
      address2,
      city,
      country,
      name,
      phone,
      zip,
    };
  };

  /**
   * @desc To get hidden module for vendor
   *
   * @param {Object} vendorAccess Vendor access data
   *
   * @param {Object} currentUser user data
   *
   * @return {String} vendor hidden module
   */
  getHiddenModule = (vendorAccess = {}, currentUser = {}) => {
    const isVendor = this.isVendor(currentUser);
    if (isVendor) {
      const vendorHideTabs = { order: "Orders", product: "Products", payment: "Payments" };
      let vendorHideModules = [];
      const { access = {}, advanceAccess = [] } = vendorAccess || {};
      const advanceVendorAccess = advanceAccess && advanceAccess.filter((item) => item.vendorId === currentUser._id);
      const hideData = advanceVendorAccess && advanceVendorAccess.length ? advanceVendorAccess[0] : access || {};
      const hideModule =
        advanceVendorAccess && advanceVendorAccess.length ? Object.keys(hideData) : Object.keys(access || {}) || [];
      vendorHideModules =
        hideModule &&
        hideModule.map((item) => {
          if (hideData[item].isHide) {
            return vendorHideTabs[item];
          }
          return null;
        });
      vendorHideModules = vendorHideModules.filter((item) => item);
      return vendorHideModules;
    }
    return [];
  };

  /**
   * @desc To get read only module for vendor
   *
   * @param {Object} vendorAccess Vendor access data
   *
   * @param {Object} currentUser user data
   *
   * @return {String} vendor read only module
   */
  getReadOnlyModule = (vendorAccess = {}, currentUser = {}) => {
    const isVendor = this.isVendor(currentUser);
    if (isVendor) {
      const vendorHideTabs = { order: "order", product: "product", payment: "payments" };
      let vendorHideModules = [];
      const { access = {}, advanceAccess = [] } = vendorAccess || {};
      const advanceVendorAccess = advanceAccess && advanceAccess.filter((item) => item.vendorId === currentUser._id);
      const hideData = advanceVendorAccess && advanceVendorAccess.length ? advanceVendorAccess[0] : access || {};
      const hideModule =
        advanceVendorAccess && advanceVendorAccess.length ? Object.keys(hideData) : Object.keys(access || {}) || [];
      vendorHideModules =
        hideModule &&
        hideModule.map((item) => {
          if (hideData[item].isReadOnly) {
            return vendorHideTabs[item];
          }
          return null;
        });
      vendorHideModules = vendorHideModules.filter((item) => item);
      return vendorHideModules;
    }
    return [];
  };

  /**
   * @desc To get Time in hour min string format from provided minutes ex - hh:mm
   *
   * @param {number} minutes in integer
   *
   * @return {String} time in hh:mm
   */
  getTimeInHourMin = (minutes = 0) => {
    let hour = Math.floor(minutes / 60);
    let min = minutes % 60;
    hour = hour < 10 ? `0${hour}` : hour;
    min = min < 10 ? `0${min}` : min;
    return `${hour}:${min}`;
  };

  /**
   * @desc To get hidden module keys from title for vendor
   *
   * @param {Object} hiddenModule Vendor hidden module data
   *
   * @return {String} vendor hidden module keys
   */
  getModuleKeys = (hiddenModule = []) => {
    const vendorModule =
      hiddenModule &&
      hiddenModule.map((item) => {
        if (item === "Products") {
          return "product";
        }
        if (item === "Orders") {
          return "order";
        }
        if (item === "Payments") {
          return "payment";
        }
        return false;
      });
    return vendorModule;
  };

  getQueryParams = (url) => QueryString.parse(url);

  /**
   * @desc To generate random password
   *
   * @return password
   */
  generateRandomPassword = () => cryptoJS.randomBytes(8).toString("hex");

  /**
   * @desc To return data from the key object
   * @param res Object
   * @param key String/Array of String
   * @return data/false
   *
   */
  getResponseData = (res = false, key = "") => {
    if (!(res && key)) {
      return false;
    }

    if (Array.isArray(key)) {
      const result = {};
      key.forEach((item) => {
        let data = false;
        if (res[item] && res[item].status === constant.OK) {
          data = { ...res[item].data };
        }
        result[item] = data;
      });
      return result;
    }
    if (typeof key === "string" && res[key].status === constant.OK) {
      return res[key].data;
    }
    return false;
  };

  /**
   * @desc To get error message
   * @param err error object
   * @param res response object
   *
   * @return error
   *
   */
  getResponseError = (res, key) => {
    if (!(res && key && res[key] && res[key].status === constant.ERROR)) {
      return false;
    }
    return res[key].error;
  };

  /**
   * @desc To get url of shop - shopify
   * @param shop
   * @param string - to append to url
   *
   * @return url
   *
   */
  getShopifyURL = (shop, string = "") => {
    const isValidShop = this.validShop(shop);
    const updatedShop = !isValidShop ? `${shop}.myshopify.com` : shop;
    const clientId = config.shopifyAPIKey;
    const url = `https://${updatedShop}/admin/apps${string ? `/${clientId}${string}` : ""}`;
    return url;
  };

  /**
   * @desc To get seller plan
   *
   * @param {Object} user User data
   *
   * @return {String} seller plan(free, bootstrap, growth, pro, customPlan)
   */
  getSellerPlan = (user) => this.isSeller(user) && user.plan && (user.plan.code || user.plan.name);

  /**
   * @desc To return hex code of HSL values
   * @param h
   * @param s
   * @param l
   *
   * @return
   *
   */
  hslToHex = (h, s, l) => {
    const hue = h / 360;
    const saturation = s / 100;
    const lightness = l / 100;
    let r;
    let g;
    let b;
    if (s === 0) {
      // eslint-disable-next-line no-multi-assign
      r = g = b = lightness; // achromatic
    } else {
      const hue2rgb = (p, q, w) => {
        let t = w;
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
      const q = lightness < 0.5 ? lightness * (1 + saturation) : lightness + saturation - lightness * saturation;
      const p = 2 * lightness - q;
      r = hue2rgb(p, q, hue + 1 / 3);
      g = hue2rgb(p, q, hue);
      b = hue2rgb(p, q, hue - 1 / 3);
    }
    const toHex = (x) => {
      const hex = Math.round(x * 255).toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  hexToHSL = (hex) => {
    let hexCode = hex;
    hexCode = hexCode.replace("#", "");
    if (hexCode.length === 3) {
      hexCode = this.updateHexCodeValue(hexCode);
    }
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexCode);
    if (result && result.length === 4) {
      let r = parseInt(result[1], 16);
      let g = parseInt(result[2], 16);
      let b = parseInt(result[3], 16);

      r /= 255;
      g /= 255;
      b /= 255;
      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      let h = (max + min) / 2;
      let s = (max + min) / 2;
      const l = (max + min) / 2;

      if (max === min) {
        h = 0;
        s = 0; // achromatic
      } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
          case g:
            h = (b - r) / d + 2;
            break;
          case b:
            h = (r - g) / d + 4;
            break;
          default:
            break;
        }
        h /= 6;
      }

      h = Math.round(360 * h);
      return {
        hue: h,
        saturation: s,
        brightness: l,
        alpha: 1,
      };
    }
    return "";
  };

  /**
   * @desc To check if user is on bootstrap plan
   * @param {Object} plan current user plan object
   *
   * @return {Boolean} true/false
   *
   */
  isBootstrapPlan = ({ plan }) => {
    return plan && constant.plan.BOOTSTRAP.includes(plan.code || plan.name);
  };

  /**
   * @desc To check if seller is branded
   *
   * @return bool
   *
   */
  isBranded = (user) => {
    if (!user) return false;
    const userRole = (user.roles && user.roles.name) || "";
    return userRole === userKey.seller && user.plan && user.plan.isBranding;
  };

  /**
   * @desc To check if browser cookies are enabled or not
   *
   * @return {Boolean} true/false
   *
   */
  isCookieEnabled = () => !!(window && window.navigator && window.navigator.cookieEnabled);

  /**
   * @desc To check if user is on free plan
   * @param {Object} plan current user plan object
   *
   * @return {Boolean} true/false
   *
   */
  isFreePlan = ({ plan }) => {
    return plan && constant.plan.FREE.includes(plan.code || plan.name);
  };

  /**
   * @desc To check if inside iframe
   *
   * @return bool
   *
   */
  isIframe = () => window.self !== window.top;

  /**
   * @desc To check if current path is a load url
   *
   * @return {Boolean} true/false
   *
   */
  isLoadUrl = (pathName) => {
    const installUrls = ["/shopify/install", "/shopify/redirect"]; // "/store/plan", "/store/load"
    return installUrls.includes(pathName);
  };

  /**
   * @desc To check if user is on pro plan
   * @param {Object} plan current user plan object
   *
   * @return {Boolean} true/false
   *
   */
   isCustomPlan = ({ plan }) => {
    return plan && constant.plan.CUSTOM_PLAN.includes(plan.code || plan.name);
  };

  /**
   * @desc To check if user is on pro plan
   * @param {Object} plan current user plan object
   *
   * @return {Boolean} true/false
   *
   */
  isProPlan = ({ plan }) => {
    return plan && constant.plan.PRO.includes(plan.code || plan.name);
  };

  /**
   * @desc To check if user is on growth plan
   * @param {Object} plan current user plan object
   *
   * @return {Boolean} true/false
   *
   */
  isGrowthPlan = ({ plan }) => {
    return plan && constant.plan.GROWTH.includes(plan.code || plan.name);
  };

  /**
   * @desc To check if user is on growth and above plan
   * @param {Object} plan current user plan object
   *
   * @return {Boolean} true/false
   *
   */
  isGrowthAndAbovePlan = ({ plan }) => {
    return this.isGrowthPlan({ plan }) || this.isProPlan({ plan });
  };

  /**
   * @desc To check if user is system admin
   *
   * @return bool
   */
  isSystemAdmin = (user) => {
    if (!user) return false;
    const userLevel = user.parentUserLevel || user.userLevel;
    return userLevel === userKey.systemAdminLevel;
  };

  /**
   * @desc To check if user is seller
   *
   * @return bool
   */
  isSeller = (user) => {
    if (!user) return false;
    const userLevel = user.parentUserLevel || user.userLevel;
    const userRole = user.parentUserRole || (user.roles && user.roles.name) || "";
    return userLevel === userKey.accountAdminLevel && userRole === userKey.seller;
  };

  /**
   * @desc To check if user is vendor
   *
   * @return bool
   */
  isVendor = (user) => {
    if (!user) return false;
    const userLevel = user.parentUserLevel || user.userLevel;
    const userRole = user.parentUserRole || (user.roles && user.roles.name) || "";
    return userLevel === userKey.accountAdminLevel && userRole === userKey.vendor;
  };

  /**
   * @desc To check if vendor is connected to any store
   *
   * @param {Object} user Object containing user data
   *
   * @return {Boolean} True/False if vendor is connected to store
   */
  isVendorStoreConnected = (user) => {
    const connectedStores = this.getVendorConnectedStores(user);
    return !!(connectedStores && connectedStores.length);
  };

  /**
   * @desc To get valid mongo id in string
   * @param id
   *
   * @return valid mongo id
   *
   */
  mongoIdAsString = (_id) => (isObject(_id) ? _id.valueOf() : _id);

  /**
   * @desc Regex which validates camel case like: validToken.
   * @param value string
   * @return Boolean
   *
   */
  validateCamelCase = (value) => {
    const camelRegex = /[A-Z]?[a-z]+[A-Z]?[a-z]*$/;
    return camelRegex.test(value);
  };

  /**
   * @desc Regex which validates input contain alphabets and , like: ml,kg,mg etc.
   * @param value number
   * @return Boolean
   *
   */
  validateMeasurement = (value) => {
    const measurementRegex = /^[a-zA-Z]+[a-zA-Z, ]*$/;
    return measurementRegex.test(value);
  };

  /**
   * @desc Regex which validates whole number like: 1, 2, 9999, etc.
   * @param value number
   * @return Boolean
   *
   */
  validateWholeNumber = (value) => {
    const priceRegex = /^(\+|\d)[0-9]*$/;
    return priceRegex.test(value);
  };

  /**
   * @desc Regex which validates whole number like: 1, 2, 9999, etc. excluding 0
   * @param value number
   * @return Boolean
   *
   */
  validateWholeNumberExcludeZero = (value) => {
    const priceRegex = /^[1-9][0-9]*$/;
    return priceRegex.test(value);
  };

  /**
   * @desc Regex which validates positive numeric values and take upto 7 digits before and 2 digits after decimal places like: 1.21, 2.23, 1234567.22, etc. excluding 0
   * @param value number
   * @return Boolean
   *
   */
  isValidPrice = (value) => {
    const endsWithDot = /^\d{0,7}(\.)?$/;
    const endsWithDecimalValue = /^\d{0,7}(\.\d{1,2})?$/;
    const result = endsWithDot.test(value) || endsWithDecimalValue.test(value);
    return result;
  };

  /**
   * @desc Regex which validates positive numeric values and can take upto 2 decimal places like: 1, 2, 3.99, 3.8, etc.
   * @param value number
   * @return Boolean
   *
   */
  validatePositiveNumericValues = (value) => {
    const endsWithDot = /^[0-9]+(\.)?$/;
    const endsWithDecimalValue = /^[0-9]+(\.[0-9]{1,2})?$/; // taking upto 2 decimal places
    const result = endsWithDot.test(value) || endsWithDecimalValue.test(value);
    return result;
  };

  validateTenDecimalPositiveNumericValues = (value) => {
    const endsWithDot = /^[0-9]+(\.)?$/;
    const endsWithDecimalValue = /^[0-9]+(\.[0-9]{1,10})?$/; // taking upto 10 decimal places
    const result = endsWithDot.test(value) || endsWithDecimalValue.test(value);
    return result;
  };

  /**
   * @desc Regex which validates number is less than 5470 like: 1, 2, 9999, etc.
   * @param value number
   * @return Boolean
   *
   */
  validateNumberMaxLimit = (value, trialMax) => {
    const trialRegex = /^(\+|\d)[0-9]{0,3} *$/;
    if (Number(value) > trialMax) {
      return false;
    }
    return trialRegex.test(value);
  };

  /**
   * @desc Regex which validates string not accept space at start but accept in middle like "market cube", "market cube 1", "1 market cube"  etc.
   * @param value string
   * @return Boolean
   *
   */
  stringNotAcceptSpaceAtStart = (value) => {
    const notAcceptSpace = /^[^-\s][a-zA-Z0-9_\s-]*$/;
    const result = notAcceptSpace.test(value);
    return result;
  };

  /**
   * @desc Regex which validates and accept string only .
   * @param value string
   * @return Boolean
   *
   */
  acceptOnlyString = (value) => {
    const onlyAcceptString = /^[a-zA-Z\s]*$/;
    const result = onlyAcceptString.test(value);
    return result;
  };

  /**
   * @desc To get url of shop - shopify
   * @param shop
   * @param string - to append to url
   *
   * @return url
   *
   */
  shopifyUrl = (shop, string = "") => {
    let url = `https://${shop}/admin/apps/${config.shopifyAPIKey}`;
    url += string;
    return url;
  };

  /**
   * @desc To set query params
   * @param contains history object
   * @param queryParams object
   *
   * @return query params
   *
   */
  setUrl = (history, queryParams, state = {}) => {
    const { location } = history;
    const search = this.setQueryParams(location, queryParams);
    history.push(`${location.pathname}?${search}`, { ...state });
  };

  /**
   * @desc To decode uri string
   * @param string - encoded uri string
   *
   * @return decoded string
   */
  decodeURI = (string) => {
    return (string && decodeURIComponent(string).replace(/\+/g, " ")) || "";
  };

  /**
   * @desc To parse url encoded string, returns array
   * @param string
   * @param sep - default ','
   *
   * @return array
   *
   */
  parseUrlString = (string, sep = ",") => {
    const decodedURI = this.decodeURI(string) || "";
    const stringArr = (decodedURI && decodedURI.split(sep)) || [];
    return stringArr;
  };

  /**
   * @desc To parse url, returns all url properties like: hostname, protocol etc
   * @param {String} uri
   *
   * @return array
   *
   */
  parseUrl = (uri) => {
    const url = new URL(uri);
    return url;
  };

  /**
   * @desc To get sort array alphabetically
   * if key is passed then sort using that key in array of objects.
   * @param {Array} array array to sort
   *
   * @return {Array} sorted array
   *
   */
  sort = (array = [], key = "") => {
    if (!key) {
      return array.sort(Intl.Collator().compare);
    }

    const compare = (item1, item2) => {
      const str1 = (item1[key] && item1[key].toLowerCase()) || "";
      const str2 = (item2[key] && item2[key].toLowerCase()) || "";

      return (str1 > str2 && 1) || (str1 < str2 ? -1 : 0);
    };

    return array.sort(compare);
  };

  /**
   * @desc To parse url, returns all url properties like: hostname, protocol etc
   * @param {String} uri
   *
   * @return array
   *
   */
  parseUrl = (uri) => {
    const url = new URL(uri);
    return url;
  };

  /**
   * @desc To return hsl value of hex code
   * @param hex
   *
   * @return
   *
   */
  updateHexCodeValue = (hex) =>
    hex
      .split("")
      .map((value) => value + value)
      .join("");

  /**
   * @desc To return shopify shop name (excluding .myshopify.com)
   * @param shop shopify store name
   *
   * @return shop/false
   *
   */
  validShop = (shop) => {
    if (!shop) {
      return false;
    }

    const isValidShop = shop.endsWith(".myshopify.com");
    const shopIndex = shop.indexOf(".myshopify.com");

    if (shopIndex <= -1 || !isValidShop) {
      return false;
    }

    return shop.slice(0, shopIndex);
  };

  /**
   * @desc replaces the multiple occurrence of multiple words in the string
   * @param string the target string which is being modified
   * @param replacementList the array of objects having key and value where
   * key is the string which is being replace with value
   *
   * @return string
   *
   */
  wordReplacer = (string, replacementList) => {
    let newString = string;
    replacementList.forEach(({ key, value }) => {
      const regex = new RegExp(key, "g");
      newString = newString.replace(regex, value);
    });
    return newString;
  };

  /**
   * @desc To create action button
   *
   * @return banner button
   *
   */
  bannerButtonAction = (showLink = false, link = false, history = false) => {
    if (!(showLink && link && link.message && link.to)) {
      return null;
    }

    const pattern = /^((http|https|ftp):\/\/)/;
    const nextPath = (e, path) => {
      e.preventDefault();
      if (pattern.test(path)) {
        window.open(path);
      } else if (history) {
        history.push(path);
      }
    };
    const action = {
      content: link.message,
      onAction: (e) => nextPath(e, link.to),
    };
    return action;
  };

  /**
   * @desc To compare to items
   *
   * @desc Item can be object, array, strings, booleans, integers, etc
   *
   * @return boolean
   *
   */
  isItemEqual = (item1, item2) => isEqual(item1, item2);

  /**
   * @desc To get formatted CMS data
   *
   * @param object cmsData
   * @return object formatted CMS data
   */
  getFormattedCMS = (cmsData = {}) => {
    const formattedCMSData = {};
    const languageCode = "en";
    Object.keys(cmsData).forEach((key) => {
      if (cmsData[key] && Array.isArray(cmsData[key])) {
        const keyData = {};
        cmsData[key].forEach((element) => {
          keyData[element.code] = element[languageCode];
        });
        formattedCMSData[key] = keyData;
      }
    });
    return formattedCMSData;
  };

  /**
   * @desc To get query params
   * @param location
   *
   * @return query params
   *
   */
  queryParamsFromLocation = ({ location }) => {
    const search = (location && location.search) || "";
    const dataToFetch = (search && this.getQueryParams(search)) || {};

    return dataToFetch;
  };

  /**
   * @desc To set query params
   * @param location object
   * @param queryParams object
   *
   * @return query params
   *
   */
  setQueryParams = (location, queryParams) => {
    let { search } = location; // could be '?foo=bar'
    const trimmedSearch = search.trim();
    const params = new URLSearchParams(trimmedSearch);

    Object.keys(queryParams).forEach((key) => {
      params.set(key, queryParams[key]);
    });
    search = params.toString();
    return search.replace("%40", "@");
  };

  /* @desc To check if bigCommerce user
   * @param url
   *
   * @return bool
   *
   */
  isBigCommerceUser = (user) => {
    const { ecommercePlatform } = user || {};
    return ecommercePlatform && ecommercePlatform === "bigCommerce";
  };

  /* @desc To check if ecommerce user
   * @param url
   *
   * @return bool
   *
   */
  isEcommerceUser = (user) => {
    const { ecommercePlatform } = user;
    return ecommercePlatform;
  };

  /* @desc To check if pass is enabled on environment basis or for provided userId
   * @param currentUser
   *
   * @return bool
   *
   */
  isRotaroUser = (currentUser) => {
    const userId = currentUser.introductorySeller || currentUser._id;

    return !config.isProd || (config.rotaroUserId && config.rotaroUserId.includes(userId));
  };

  /* @desc To check if pass is enabled on environment basis or for provided userId
   * @param userId
   *
   * @return bool
   *
   */
  isPAASEnabled = (userId) => {
    return config.isPAASEnable || config.paasUserId.includes(userId);
  };

  /* @desc To check if lineitem fulfillment is service type
   * @param fulfillmentType and properties
   *
   * @return bool
   *
   */
  isServiceLineItem = (fulfillmentType, properties) => {
    return fulfillmentType === constant.SERVICE && properties && properties.length;
  };

  /* @desc To check if wooCommerce user
   * @param url
   *
   * @return bool
   *
   */
  isWooCommerceUser = (user) => {
    const { ecommercePlatform } = user;
    return ecommercePlatform && ecommercePlatform === "wooCommerce";
  };

  /* @desc To check if valid domain url path
   *
   * @param url
   *
   * @return bool
   */
  isValidDomainPath = (url) => {
    const urlRegex = /^(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    return urlRegex.test(url);
  };

  /* @desc To check if valid url
   * @param url
   *
   * @return bool
   *
   */
  isValidUrl = (url) => {
    // eslint-disable-next-line max-len
    const validURLRegex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    return validURLRegex.test(url);
  };

  /* @desc To check if valid domain
   * @param shopDomain
   *
   * @return bool
   *
   */
  isValidSubDomain = (shopDomain) => {
    const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi;
    return domainRegex.test(shopDomain);
  };

  /**
   * @desc To get badge type
   * @param value status
   *
   * @return array
   *
   */
  getBadgeType = (value) => {
    const badgeType = {
      accepted: constant.SUCCESS,
      bounced: constant.CRITICAL,
      alpha: constant.ATTENTION,
      appinstall: constant.SUCCESS,
      approved: constant.SUCCESS,
      dispatched: constant.SUCCESS,
      appuninstall: constant.WARNING,
      autoReject: constant.CRITICAL,
      beta: constant.INFO,
      create: constant.ATTENTION,
      critical: constant.CRITICAL,
      completed: constant.SUCCESS,
      closed: "",
      dispute: constant.ATTENTION,
      error: constant.WARNING,
      firstinvite: constant.INFO,
      firstlogin: constant.INFO,
      firstorder: constant.INFO,
      firstproduct: constant.INFO,
      firstvendor: constant.INFO,
      fulfilled: "",
      invited: constant.INFO,
      joined: constant.SUCCESS,
      live: constant.SUCCESS,
      "partially-shipped": constant.WARNING,
      "needs review": constant.ATTENTION,
      noShow: constant.WARNING,
      new: constant.INFO,
      onhold: constant.WARNING,
      paid: constant.SUCCESS,
      pending: constant.ATTENTION,
      progress: constant.ATTENTION,
      published: constant.SUCCESS,
      refunded: constant.WARNING,
      rejected: constant.CRITICAL,
      return: constant.CRITICAL,
      revoked: constant.WARNING,
      sellersignup: constant.ATTENTION,
      shipped: constant.NEW,
      suppliersignup: constant.ATTENTION,
      synced: constant.SUCCESS,
      unfulfilled: constant.ATTENTION,
      unpaid: constant.INFO,
      "shipped by vendor": constant.INFO,
      "ready to pick": constant.INFO,
      "rejected by vendor": constant.CRITICAL,
      "accepted by operator": constant.SUCCESS,
      "fulfilled by operator": constant.NEW,
    };

    const retVal = value ? badgeType[value.toLowerCase()] : "";
    return retVal || value;
  };

  /**
   * @desc To format date
   * @param date
   *
   * @return date
   *
   */
  formatDate = (date, isTimeStamp = true) => {
    if (!date) return "";
    return isTimeStamp
      ? `${moment(date).format("LL")}  at ${moment(date).format("h:mma")} (GMT${moment().format("Z")})`
      : `${moment(date).format("LL")}`;
  };

  lastUpdateDate = (lastUpdatedDate) => {
    const lastDate = this.formatDate(lastUpdatedDate);
    if (!lastDate) {
      return "";
    }
    return `Last updated at: On ${lastDate}`;
  };

  yearFirstDateFormat = (date) => {
    if (!date) {
      return "";
    }

    return `${moment(date).format("YYYY-MM-DD")}`;
  };

  dayFirstDateFormat = (date) => {
    if (!date) {
      return "";
    }

    return `${moment(date).format("MM-DD-YYYY")}`;
  };

  /**
   * @desc To return first letter in upper case
   * @param string
   *
   * @return string
   *
   */
  ucFirst = function ucFirst(string) {
    if (!string) {
      return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  /* @desc To get current selected language from code
   *
   * @param string langCode (en, es, fr, etc)
   * @return string Language (English, Spanish, French, etc)
   */

  getLanguage = (langCode) => {
    const language = {
      en: "English",
      es: "Spanish",
    };
    return language[langCode];
  };

  /**
   * @desc To return five digit Random Number with Product Title
   *
   * @return string Language (English, Spanish, French, etc)
   */
  customHandleGenerator = (productTitle) => {
    // e.g mc-(5 digit random)-product-title
    const fiveDigitRandomNum = Math.floor(100000 + Math.random() * 900000);
    const Handler = `mc-${fiveDigitRandomNum}-${productTitle
      .trim()
      .replace(/[^\w\s]/gi, "")
      .replace(/\s+/g, "-")
      .toLowerCase()}`;
    return Handler;
  };

  /* @desc To convert values of object key string to Integer.
   *
   * @param object objData
   * @return object keyData ({abc:10}) here abc is a key in objData and 10 is default value.
   * if condition false it return default value
   */
  convertToInt = (objData, keyData) => {
    const prevObj = objData;
    if (Object.keys(prevObj).length > 0) {
      Object.keys(keyData).map((key) => {
        if (Object.keys(prevObj).length > 0 && Object.prototype.hasOwnProperty.call(prevObj, `${key}`)) {
          const convertedVal =
            (Object.prototype.hasOwnProperty.call(prevObj, `${key}`) && parseInt(prevObj[key], 10)) || keyData[key];
          prevObj[key] = convertedVal;
        }
        return prevObj;
      });
    }
    return prevObj;
  };

  /**
   * @desc To transform brand handle
   * @param value
   *
   * @return brandHandle
   *
   */
  generateBrandHandle = (value) => {
    const brandHandle =
      value &&
      value.length &&
      value
        .trim()
        .toLowerCase()
        .replace(/[^a-z0-9-_ ]/g, "")
        .replace(/ /g, "-");
    return brandHandle;
  };

  /**
   * @desc To get badge status
   * @param value status
   *
   * @return array
   *
   */
  getBadgeStatus = (value) => {
    const retVal = value ? displayStatus[value.toUpperCase()] : "";
    return retVal || value;
  };

  /**
   * @desc To get plan badge status
   * @param value status
   *
   * @return string
   *
   */
  getPlanBadge = (value) => {
    const retVal = value ? displayPlan[value.toUpperCase()] : "";
    return retVal || value;
  };

  /**
   * @desc To get value in Interger
   * @param value numeric or string
   *
   * @return parsed value if given value is string then return 0 if not return Interger value.
   *
   */
  getInterger = (value, base) => {
    const parsed = parseInt(value, base);
    if (Number.isNaN(parsed)) {
      return 0;
    }
    return parsed;
  };

  /**
   * @desc To get initial letter  of string.
   * @param name contains string
   *
   * @return name
   */
  getInitials = (name) => {
    return name
      .split(" ")
      .map((surnameOrFamilyName) => {
        return surnameOrFamilyName.slice(0, 1).toUpperCase();
      })
      .join("");
  };

  // /**
  //  * @desc To address and city of user
  //  * @param user object
  //  *
  //  * @return object
  //  *
  //  */
  // getFullAddress = function getAddress({ address, city, country, pinCode }) {
  //   const addressFormatted = address ? `${address}, ` : "";
  //   return {
  //     addressFormatted,
  //     city,
  //     country,
  //     pinCode,
  //   };
  // };

  /**
   * @desc To get Service badge Text if product is service
   * @param isService true or false
   *
   * @return Text "Service"
   *
   */
  getServiceBadge = (isService) => {
    const retVal = isService ? productVersion[IS_SERVICE] : "";
    return retVal || "";
  };

  /**
   * @desc To get vendor connected stores
   *
   * @param {Object} user Object containing user data
   *
   * @return {Array} array of connected stores
   */
  getVendorConnectedStores = (user) => {
    const isVendor = this.isVendor(user);
    if (!isVendor) {
      return [];
    }

    const { shop, shopifyAccessToken, storeConfig = {} } = user;

    const stores = Object.keys(storeConfig || {});
    const connectedStores = stores.filter((store) => {
      const storeData = storeConfig[store];
      return storeData && storeData.shop && storeData.accessKey && storeData.accessToken;
    });

    if (shop && shopifyAccessToken) {
      connectedStores.push(constant.SHOPIFY);
    }

    return connectedStores;
  };

  /**
   * @desc To validate json
   * @param obj contains json data
   *
   * @return true/false
   */
  isValidJSON = (obj) => !isError(attempt(JSON.parse, obj));

  /**
   * @desc To parse the data
   * @param data contains json data
   *
   * @return data
   */
  parseData = (data) => {
    return JSON.parse(JSON.stringify(data));
  };

  /**
   * @desc To cloneData the data
   * @param data contains json data
   *
   * @return data
   */
  cloneData = (data) => {
    return JSON.parse(JSON.stringify(data));
  };

  /**
   * @desc To removeDuplicate data from array of objects
   * @param data contains array of object
   *
   * @return data
   */
  removeDuplicateData = (data) =>
    data.filter((item, idx, self) => idx === self.findIndex((subItem) => subItem.id === item.id));

  /**
   * @desc To get price upto 2 decimals
   * @param price price
   *
   * @return newPrice price upto 2 decimals
   *
   */
  getPrice = (price) => {
    const newPrice = parseFloat(price || constant.value.ZERO).toFixed(2);
    return newPrice;
  };

  formatPrice = (price) => {
    const newPrice = parseFloat(price || 0).toFixed(2);
    return newPrice;
  };

  /**
   * @desc To check valid PDF, DOC and DOCX link
   * @param link link
   *
   * @return bool
   *
   */

  isValidDocLink = (link) => {
    // eslint-disable-next-line no-useless-escape
    const pdfRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+?\.pdf$/gm;
    // eslint-disable-next-line no-useless-escape
    const docRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+?\.doc$/gm;
    // eslint-disable-next-line no-useless-escape
    const docxRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+?\.docx$/gm;
    const isPdfLink = pdfRegex.test(link);
    const isDocLink = docRegex.test(link);
    const isDocxLink = docxRegex.test(link);
    return isPdfLink || isDocLink || isDocxLink;
  };

  /**
   * @desc To get template tags value
   * @param tags
   *
   * @return tagsFound
   *
   */
  templateTags = function templateOptions(content) {
    const regularExp = /{{\w+}}/g;

    const tagsFound = content.match(regularExp) || []; // an array to collect the strings that are found in content

    return tagsFound;
  };

  /**
   * @desc To validate email content
   * @param actualTags
   * @param foundTags
   *
   * @return isValidContent
   *
   */
  validateTags = function validateTags(actualTags, foundTags) {
    let isValidContent = true;
    const sortActualTags = actualTags.sort();
    const sortFoundTags = foundTags.sort();
    if (JSON.stringify(sortActualTags) !== JSON.stringify(sortFoundTags)) {
      isValidContent = false;
    }

    return isValidContent;
  };

  /**
   * @desc To get description message
   * @param value activity
   *
   * @return array
   *
   */
  activityDescription = function activityDescription({ entity, entityType, status, error, cms }) {
    if (!entity) {
      return constant.NO_ERROR;
    }
    const baseCms = "section.activityView.".trim();

    switch (entityType) {
      case constant.PRODUCTS:
        if (status === constant.ERROR || status === constant.DUPLICATE) {
          return `${cms(`${baseCms}product.failure`, { entity: `${entity}`, error: `${error}` })}`;
        }
        return `${cms(`${baseCms}product.success`, { entity: `${entity}` })}`;
      case constant.SUPPLIERS:
        if (status === constant.ERROR || status === constant.DUPLICATE) {
          return `${cms(`${baseCms}supplier.failure`, { entity: `${entity}`, error: `${error}` })}`;
        }
        return `${cms(`${baseCms}supplier.success`, { entity: `${entity}` })}`;
      case constant.INVITE:
        if (status === constant.ERROR || status === constant.DUPLICATE) {
          return `${cms(`${baseCms}invite.failure`, { entity: `${entity}`, error: `${error}` })}`;
        }
        return `${cms(`${baseCms}invite.success`, { entity: `${entity}` })}`;
      case constant.INVITE_CSV:
        if (status === constant.ERROR || status === constant.DUPLICATE) {
          return `${cms(`${baseCms}inviteCsv.failure`, { entity: `${entity}`, error: `${error}` })}`;
        }
        return `${cms(`${baseCms}inviteCsv.success`, { entity: `${entity}` })}`;
      case constant.STRIPE:
        if (status === constant.ERROR || status === constant.DUPLICATE) {
          return `${cms(`${baseCms}stripe.failure`, { entity: `${entity}`, error: `${error}` })}`;
        }
        return `${cms(`${baseCms}stripe.success`, { entity: `${entity}` })}`;
      case constant.PAYPAL:
        if (status === constant.ERROR || status === constant.DUPLICATE) {
          return `${cms(`${baseCms}paypal.failure`, { entity: `${entity}`, error: `${error}` })}`;
        }
        return `${cms(`${baseCms}paypal.success`, { entity: `${entity}` })}`;
      default:
        return `${entity}`;
    }
  };

  /**
   * @desc Returns newZealand post api url
   *
   * @return string
   *
   */
  getNewZeaLandPostAPIUrl = (consignmentId) => {
    const url = `${newZeaLandPostAPIUrl}parcellabel/v3/labels/${consignmentId}?format=PDF`;
    return url;
  };

  /**
   * @desc Returns list of object
   * @param data
   * @param codes
   *
   * @return array
   *
   */
  getAllowedSetting = (data) => {
    let updatedData = data;
    const notAllowedSettingCodes = [
      "billing",
      "creditNote",
      // "payments",
      "product",
      // "provider",
      "reportSetting",
      "tax",
      // "fulfilment",
      "notifications",
      // "order",
    ];
    updatedData = updatedData.filter((item) => !notAllowedSettingCodes.includes(item.code));
    return updatedData;
  };

  getAllowedVendorSetting = (data) => {
    let updatedData = data;
    const notAllowedSettingCodes = [
      "billing",
      "creditNote",
      // "fulfilment",
      "order",
      // "product",
      "reportSetting",
      // "tax",
      "notifications",
      "payments",
    ];
    updatedData = updatedData.filter((item) => !notAllowedSettingCodes.includes(item.code));
    return updatedData;
  };

  /**
   * @desc Returns list of object
   * @param data
   * @param code
   * @param cms
   *
   * @return array
   *
   */
  allowedSettingList = (code, data) => {
    let updatedList = data;
    const notAllowedList = {
      branding: ["customize"],
      billing: ["billingSetting"],
      creditNote: ["creditNote"],
      fulfilment: ["address", "carrier"],
      notifications: ["digest", "emailTemplate"],
      order: [
        "dynamicDiscount",
        "orderExportSetting",
        "orderExportSettings",
        "orderSettings",
        "orderSettings",
        "packingSlipSetting",
        "orderLiveConnect",
        "setting",
        // "commission",
      ],
      payments: [
        "currencyPayoutSetting",
        "currencyRateSetting",
        "invoiceSetting",
        "paymentExportSetting",
        // "terms",
        "paymentService",
      ],
      product: [
        "attachment",
        "discountSetting",
        "discount",
        "markupSettings",
        "metafield",
        "productApprovalSetting",
        "productFormSetting",
        "productPriceSetting",
        // "productSettings",
        "productTagSetting",
      ],
      provider: [
        "holidaySetting",
        "vendorAdvance",
        "vendorReview",
        "vendorSubscription",
        "managePlans",
        "manageExport",
      ], // "vendor", "vendorAccess"
      reportSetting: ["reportSetting"],
      tax: [
        "tax",
        // "lakeTaxRate",
      ],
      user: ["holidaySetting"],
    };

    const codes = notAllowedList[code] || [];
    updatedList = updatedList.filter((item) => !codes.includes(item.code));
    return updatedList;
  };

  /**
   * @desc Returns is volumetric weight is large or not
   *
   * @return boolean
   *
   */
  isLargeVolumetricWeight = (length, width, height) => {
    let weight = (length * width * height) / 5000;
    weight = weight.toFixed(2);
    const splitWeight = weight.toString().split(".");
    let number = weight;
    if (splitWeight && splitWeight.length) {
      number = splitWeight && splitWeight[1];
      number /= 10;
      number = Math.round(number);
      if (number > 5) {
        weight = Math.round(weight);
      }
    }
    if (weight > 180) {
      return true;
    }
    return false;
  };

  /* @desc To check if valid domain
   * @param shopDomain
   *
   * @return bool
   *
   */
  isValidSubDomain = (shopDomain) => {
    const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi;
    return domainRegex.test(shopDomain);
  };

  /* @desc To check if ecommerce user
   * @param url
   *
   * @return bool
   *
   */
  isEcommerceUser = (user) => {
    const { ecommercePlatform } = user;
    return ecommercePlatform;
  };

  /**
   * @desc Returns list of object
   * @param data
   *
   * @return array
   *
   */
  getAllowedNotificatonSetting = (data) => {
    const updatedData = {};
    const notAllowedNotificatonSettingCodes = [
      "newOrderSeller",
      "packingSlip",
      "productBulkUploadNotification",
      "productBulkUploadNotificationError",
      "productsReviewSeller",
      "productsEditSeller",
      "productsResubmitSeller",
      "supplierAcceptsOrder",
      // "supplierJoined",
      "supplierRejectsOrder",
      "vendorBulkUploadNotification",
      "vendorBulkUploadNotificationError",
    ];
    Object.keys(data).map((code) => {
      if (!notAllowedNotificatonSettingCodes.includes(code)) {
        updatedData[code] = data[code];
      }
      return true;
    });
    return updatedData;
  };

  /**
   * @desc Returns list of object
   * @param data
   *
   * @return array
   *
   */
  getStoreFulfillmentData = (data) => {
    const updatedData = [];
    if (data && data.length) {
      data.map((item) =>
        item.items.map((item1) =>
          updatedData.push({
            trackingNumber: item.trackingNumber,
            trackingCompany: item.comments || item.shippingMethod,
            storeLineItemId: item1.storeProductId,
          })
        )
      );
    }
    return updatedData;
  };

  /**
   * @desc Returns list of object
   * @param data
   * @param lineData
   *
   * @return array
   *
   */
  getLineItemMapData = (data, lineData) => {
    lineData.map((item) => {
      const temp = data.find((item1) => item1.storeLineItemId === item.storeLineItemId);
      if (temp) {
        item.trackingCompany = temp.trackingCompany;
        item.trackingNumber = temp.trackingNumber;
      }
      return null;
    });
    return lineData;
  };

  /**
   * @desc Returns list of object
   * @param data
   *
   * @return array
   *
   */
  getAllowedVendorNotificatonSetting = (data) => {
    const updatedData = {};
    const notAllowedNotificatonSettingCodes = [
      "newOrderSupplier",
      "packingSlip",
      "productBulkUploadNotification",
      "productBulkUploadNotificationError",
      "productsReviewSupplier",
      "productsApprovedSupplier",
      "productsEditApproved",
      "productsDeleteSupplier",
      "productsEditSupplier",
      "productsRejectedSupplier",
      "sellerAcceptsOrder",
      "sellerRejectsOrder",
      "shipmentBySeller",
      // "vendorProfileUpdate",
    ];
    Object.keys(data).map((code) => {
      if (!notAllowedNotificatonSettingCodes.includes(code)) {
        updatedData[code] = data[code];
      }
      return true;
    });
    return updatedData;
  };

  /**
   *  @desc Returns invoice setting for invoice slip
   *  @param userSetting
   *  @param invoiceSlipCMS
   *
   *  @return Object
   */
  getInvoiceSlipSettings = (userSetting = {}, invoiceSlipCMS) => {
    const {
      deliverTo,
      discount,
      fulfillBy,
      orderPayout,
      orderTotal,
      price,
      product,
      purchaseFrom,
      quantity,
      sellerCommission,
      shipping,
      sku,
      // status,
      subtotal,
      tax,
      totalDeduction,
      totalVendorPayout,
      totalOrderTax,
      totalAmount,
      totalDeductionAmount,
      totalOrderShipping,
      totalDeductionTax,
      totalOrderAmount,
    } = userSetting || {};

    const invoiceSetting = {
      purchaseFrom: {
        label: purchaseFrom.label || invoiceSlipCMS("label.title"),
        isHide: purchaseFrom.isHide,
      },
      deliverTo: {
        label: deliverTo.label || invoiceSlipCMS("label.to"),
        isHide: deliverTo.isHide,
      },
      product: {
        label: product.label || invoiceSlipCMS("label.item"),
        isHide: false,
      },
      sku: {
        label: sku.label || invoiceSlipCMS("label.sku"),
        isHide: sku.isHide,
      },
      quantity: {
        label: quantity.label || invoiceSlipCMS("label.quantity"),
        isHide: false,
      },
      price: {
        label: price.label || invoiceSlipCMS("label.price"),
        isHide: false,
      },
      tax: {
        label: tax.label || invoiceSlipCMS("label.tax"),
        isHide: tax.isHide,
      },
      discount: {
        label: discount.label || invoiceSlipCMS("label.discount"),
        isHide: discount.isHide,
      },
      // status: {
      //   label: status.label || invoiceSlipCMS("label.status"),
      //   isHide: status.isHide,
      // },
      subtotal: {
        label: subtotal.label || invoiceSlipCMS("label.subTotal"),
        isHide: subtotal.isHide,
      },
      shipping: {
        label: shipping.label || invoiceSlipCMS("label.shipping"),
        isHide: shipping.isHide,
      },
      sellerCommission: {
        label: sellerCommission.label || invoiceSlipCMS("label.sellerCommission"),
        isHide: sellerCommission.isHide,
      },
      totalDeduction: {
        label: totalDeduction.label || invoiceSlipCMS("label.totalDeduction"),
        isHide: totalDeduction.isHide,
      },
      orderTotal: {
        label: orderTotal.label || invoiceSlipCMS("label.orderTotal"),
        isHide: orderTotal.isHide,
      },
      orderPayout: {
        label: orderPayout.label || invoiceSlipCMS("label.orderPayout"),
        isHide: orderPayout.isHide,
      },
      totalVendorPayout: {
        label: totalVendorPayout.label || invoiceSlipCMS("label.totalVendorPayout"),
        isHide: totalVendorPayout.isHide,
      },
      totalOrderTax: {
        label: totalOrderTax?.label || invoiceSlipCMS("label.totalOrderTax"),
        isHide: totalOrderTax?.isHide,
      },
      totalAmount: {
        label: totalAmount?.label || invoiceSlipCMS("label.totalAmount"),
        isHide: totalAmount?.isHide,
      },
      totalOrderShipping: {
        label: totalOrderShipping?.label || invoiceSlipCMS("label.totalOrderShipping"),
        isHide: totalOrderShipping?.isHide,
      },
      totalDeductionTax: {
        label: totalDeductionTax?.label || invoiceSlipCMS("label.totalDeductionTax"),
        isHide: totalDeductionTax?.isHide,
      },
      totalDeductionAmount: {
        label: totalDeductionAmount?.label || invoiceSlipCMS("label.totalDeductionAmount"),
        isHide: totalDeductionAmount?.isHide,
      },
      totalOrderAmount: {
        label: totalOrderAmount?.label || invoiceSlipCMS("label.totalOrderAmount"),
        isHide: totalOrderAmount?.isHide,
      },
      fulfillBy: {
        label: fulfillBy.label || invoiceSlipCMS("label.fulfill"),
        isHide: fulfillBy.isHide,
      },
    };

    return invoiceSetting;
  };

  /**
   *  @desc Returns ture if fulfillment shippyPro, sendabox, ups, canadaPost
   *  @param response
   *
   *  @return boolean
   */

  isShowProvinceCode = (fulfillmentSetting = {}) => {
    const { shippingOption = "" } = (fulfillmentSetting && fulfillmentSetting.dropship) || {};
    return (
      shippingOption &&
      [constant.SHIPPY_PRO, constant.SENDABOX, constant.UPS_SMALL, constant.CANADA_POST, constant.SHIPPO].includes(shippingOption)
    );
  };

  /**
   *  @desc Returns text in the Banner at the Payment Connect page
   *  @param isPaypalConnected
   *  @param isStripeConnected
   *  @param sellerBrandName
   *
   *  @return string
   */

  getPaymentBannerText = (isPaypalConnected, isStripeConnected, sellerBrandName) => {
    if (!isPaypalConnected) {
      return `${sellerBrandName} only accepts Stripe Connect. It is essential that you connected a 
      dedicated Stripe Account to ${sellerBrandName} which is only used for payouts from ${sellerBrandName}.`;
    }
    if (!isStripeConnected) {
      return `${sellerBrandName} only accepts PayPal Connect. It is essential that you connected a 
      dedicated PayPal Account to ${sellerBrandName} which is only used for payouts from ${sellerBrandName}.`;
    }
    if (isPaypalConnected && isStripeConnected) {
      return `${sellerBrandName} accepts both Stripe Connect & PayPal Connect. It is essential that you connected 
      either a Paypal Account or a Stripe Account to ${sellerBrandName} which is only used 
      for payouts from ${sellerBrandName}.`;
    }
  };

  /*
   * @desc To get all currency symbols
   *
   * @return {Array} value, label pair of currency code and symbol
   */
  getCurrencySymbols = () => {
    const valueLabelPair = [];
    for (const [value] of Object.entries(currencyData)) {
      valueLabelPair.push({ value, label: value });
    }
    return valueLabelPair;
  };

  /**
   * @desc To get currency symbol
   * @param {String} currencyCode currency name
   *
   * @return currency symbol
   */
  getCurrencySymbol = (currencyCode) => {
    if (!currencyCode) {
      return "$";
    }

    return currencyData[currencyCode];
  };

  /**
   * @desc To Print Invoice With toggle of chat and language icons
   * @return windows.print()
   */

  printInvoice = () => {
    const element = document.getElementById('hide-element');
    element.classList.add('hide-element');
    localizeHelper.hide();
    chatHelper.hide();
    window.print();
    chatHelper.show();
    localizeHelper.show();
    element.classList.remove('hide-element');
  };

  transformGAData = (dataValues, isAge = false) => {
    if(!(dataValues && dataValues.length)) {
      return false;
    }

    const labelValue = (isAge && "Age ") || "";

    const labelResponse = dataValues.map(data => (data && data.label) && labelValue + data.label);
    const valueResponse = dataValues.map(data => data.value);
    return {
      label: labelResponse,
      value: valueResponse
    };
  }

  acceptOnlyNumber = (value) => {
    const result = value.replace(/[^\d]/g, "");
    return result;
  };
}

export default new BaseHelper();
