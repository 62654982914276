import React, { useContext, useEffect, useState } from "react";
import { useQuery, useMutation } from "react-apollo";
import {
  ActionList,
  Badge,
  Button,
  Caption,
  Card,
  Collapsible,
  FormLayout,
  Layout,
  Link,
  Modal,
  Popover,
  ResourceItem,
  ResourceList,
  Stack,
  TextField,
  TextStyle,
  Thumbnail,
  Tooltip,
} from "@shopify/polaris";

import { QuickSaleMajor, ReturnMinor, ViewMajor, WholesaleMajor } from "@shopify/polaris-icons";

import { baseHelper, imageHelper, statusHelper, errorHelper } from "lib/helpers";
import { UPDATE_ORDER_LINE_DELIVERY_STATUS } from "app/orders/apollo/mutations";
import GET_ORDER_LINE_VIEW from "app/orders/apollo/queries/lineItem";
import { NotFound } from "layout/private/components";
import { Spinner, Toast } from "lib/components";
import constant from "lib/constant/constant";
import listData from "app/orders/modules/operator/features/list/orderListData";
import { PrivateContext } from "lib/context";
import OperatorLineReturn from "app/orders/modules/operator/features/lineReturn/index";
import OrderVersioning from "app/orders/modules/generic/orderVersioning/orderVersioning";
import { StyledOrderVersioning } from "asset/styles/globalStyle";

const LineItem = (props) => {
  const {
    active,
    setActive,
    cms,
    disable,
    expanded,
    historyActive,
    id: orderViewId,
    initialTrackingCompany,
    initialTrackingNumber,
    initiateReturn = { showModal: false, selectedOrder: false },
    /* isThirdPartyShipping = "", */
    isEnabledData,
    loadingOrderLineTracking,
    moneyFormat,
    orderId,
    role,
    setBanner,
    setExpanded,
    setHistoryActive,
    setDisable,
    setInitialTrackingCompany,
    setInitialTrackingNumber,
    setTrackingModal,
    showHistory,
    toggleModal,
    trackingModal,
    updateOrderLine,
    getOrderData,
  } = props;

  const {
    history,
    currentUser: {
      roles: { name },
    },
  } = useContext(PrivateContext);
  const { mainLink } = listData(cms);

  const {
    ACCEPT,
    ACCEPTED,
    ACKNOWLEDGE,
    CLOSED,
    COLLECT,
    COLLECTED,
    CREATE,
    DROPSHIP,
    FULFILLED,
    ITEM,
    ITEMS,
    NEW,
    PARTIAL_COLLECT,
    PARTIAL_REFUND,
    PARTIAL,
    REFUND,
    REJECT,
    REJECTED,
    RESERVE,
    SERVICE,
    SHIPPED,
    UNFULFILLED,
    value,
    VENDOR,
    VENDORS,
  } = constant;
  const [selectedItems, setSelectedItems] = useState([]);
  const [viewLineOrderData, setViewLineOrderData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [product, setProduct] = useState();
  const [totalOrderItem, setTotalOrderItem] = useState();
  const [totalOrderVendor, setTotalOrderVendor] = useState();
  const [popoverActive, setPopoverActive] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [message, setMessage] = useState("");

  const { loading: loadingOrderLineView, data: dataOrderLineView, refetch: refetchOrderLineView } = useQuery(
    GET_ORDER_LINE_VIEW,
    {
      variables: { input: { orderId } },
    }
  );

  const [updateOrderDelivery, { loading: loadingUpdateOrderDelivery }] = useMutation(
    UPDATE_ORDER_LINE_DELIVERY_STATUS,
    {
      refetchQueries: [
        {
          query: GET_ORDER_LINE_VIEW,
          variables: { input: { orderId } },
        },
      ],
    }
  );

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 2000);
  }, [message]);

  useEffect(() => {
    if (dataOrderLineView) {
      const orderLineView = baseHelper.getResponseData(dataOrderLineView, constant.gql.GET_ORDER_LINE_VIEW);
      const orderLineViewError = baseHelper.getResponseError(dataOrderLineView, constant.gql.GET_ORDER_LINE_VIEW);
      if (orderLineView) {
        const { productId } = orderLineView;
        setViewLineOrderData(orderLineView);
        setProduct(productId);
        setTotalOrderItem(orderLineView.length);
        const vendorsSum = [];
        orderLineView.filter(({ vendor }) => {
          vendorsSum.push(vendor);
          return vendorsSum;
        });
        const vendorCount = [...new Set(vendorsSum)];
        setTotalOrderVendor(vendorCount.length);
        const totalItemPrice =
          orderLineView && orderLineView.map(({ price, actualQuantity }) => parseFloat(price * actualQuantity));
        const totalAmount = totalItemPrice.reduce((cur, acc) => cur + acc, constant.value.ZERO);
        if (totalAmount) {
          setTotalPrice(totalAmount);
        }
      }
      if (orderLineViewError) {
        setIsError(true);
      }
    }
  }, [dataOrderLineView, setTotalOrderItem, setTotalOrderVendor, getOrderData]);

  if (loadingOrderLineView || !totalOrderItem || !totalOrderVendor || loadingUpdateOrderDelivery) {
    return <Spinner size="small" />;
  }

  const renderPopUp = (shopifyLineItemId, item) => {
    const { status } = item;
    if (status === REJECT) {
      toggleModal(shopifyLineItemId);
    }
    return true;
  };

  const showReturnStatus = (lineReturnStatus, item) => {
    const { shopifyLineItemId, return: orderReturn, actualQuantity, quantity, returnedQuantity, isReturn } = item;
    const buttonShown = (
      <Stack.Item>
        <Button
          outline
          size="slim"
          destructive={false}
          onClick={() => renderPopUp(shopifyLineItemId, orderReturn)}
          key={`resourceListOrder${shopifyLineItemId}`}
        >
          {lineReturnStatus}
        </Button>
      </Stack.Item>
    );
    const { status } = orderReturn;

    const badgeShown =
      returnedQuantity < (actualQuantity || quantity) && orderReturn && status === ACCEPT && isReturn ? (
        <Badge status={baseHelper.getBadgeType(constant.RETURN)}>
          {statusHelper.getBadgeStatus(constant.PARTIAL_RETURN)}
        </Badge>
      ) : (
        <Badge status={baseHelper.getBadgeType(orderReturn.status)}>{baseHelper.ucFirst(lineReturnStatus)}</Badge>
      );

    if (status === CREATE || status === ACCEPT || status === ACKNOWLEDGE) {
      return badgeShown;
    }
    return buttonShown;
  };

  const renderItem = (item) => {
    const {
      _id: id,
      actualQuantity,
      fulfillableQuantity,
      fulfillmentStatus: lineItemFulfillmentStatus,
      fulfillmentType,
      image,
      isAutoExpiry,
      isAutoReject,
      isCampaign,
      isConsignmentPick,
      isDelivered,
      isRefund,
      isReturn: isReturnAccepted,
      isVendorFulfilled,
      moneyFormat: lineItemMoneyFormat,
      name: productName,
      orderId: lineItemOrderId,
      orderNumber,
      price,
      quantity,
      rejectedByVendor = false,
      return: orderReturn,
      returnedQuantity,
      shopifyLineItemId,
      /* title, */
      vendor,
      vendorId,
    } = item;

    const updateOrderDeliveryAction = async () => {
      const { id } = props;
      const formData = {
        orderLineArray: [
          {
            id: item._id,
          },
        ],
        isSingle: true,
      };

      await updateOrderDelivery({ variables: { input: { ...formData } } })
        .then((res) => {
          setSelectedItems([]);
          if (res) {
            const resData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_ORDER_LINE_DELIVERY_STATUS);
            const responseDataError = baseHelper.getResponseError(
              res.data,
              constant.gql.UPDATE_ORDER_LINE_DELIVERY_STATUS
            );
            if (resData) {
              setMessage(cms("message.success.markLineDeliver"));
              setBanner({ isOpen: false, title: "", status: constant.CRITICAL });
            }
            if (responseDataError) {
              setBanner({ isOpen: true, title: responseDataError, status: constant.CRITICAL });
            }
          }
        })
        .catch((exception) => {
          setBanner({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
        });
    };

    let { fulfillmentStatus: orderFulfillmentStatus, status } = item;

    const fulfillmentStatus =
      [constant.PICKUP, constant.CONSIGNMENT].includes(fulfillmentType) && isVendorFulfilled
        ? (isConsignmentPick && cms("section.orderList.label.ready")) || cms("section.orderList.label.ship")
        : orderFulfillmentStatus;

    const isRejected =
      (status === NEW || status === CLOSED) &&
      lineItemFulfillmentStatus === UNFULFILLED &&
      fulfillableQuantity === value.ZERO;
    if (isRejected) {
      status = REJECTED;
    }

    const isReserve = [SHIPPED, CLOSED].includes(status) && fulfillmentType === RESERVE;
    const isCollect = [SHIPPED, CLOSED].includes(status) && fulfillmentType === COLLECT;
    const isDropship = [SHIPPED, CLOSED].includes(status) && fulfillmentType === DROPSHIP;

    if (isDropship && !isReturnAccepted) {
      status = SHIPPED;
    }
    if (isReserve || isCollect) {
      status = COLLECTED;
    }

    const statusType =
      status === ACCEPTED && [RESERVE, COLLECT].includes(fulfillmentType) && fulfillmentStatus === PARTIAL;

    if (statusType) {
      status = COLLECTED;
    }
    const isPartialRefund = isRefund && !!actualQuantity;
    const isFullRefund = isRefund && !actualQuantity;

    if (isPartialRefund) {
      orderFulfillmentStatus = PARTIAL_REFUND;
    }

    if (isFullRefund) {
      orderFulfillmentStatus = REFUND;
    }

    const updatedQuantity = parseInt(actualQuantity, 10) - parseInt(fulfillableQuantity, 10);

    const checkOrderLineStatus = [RESERVE, COLLECT].includes(fulfillmentType) && isRefund && updatedQuantity;

    if (checkOrderLineStatus) {
      status = PARTIAL_COLLECT;
    }

    let isReturnInitatied = false;
    let lineReturnStatus = "";
    const isReturn = orderReturn && Object.keys(orderReturn).length;
    if (isReturn && orderReturn.status) {
      const { status: orderReturnStatus } = orderReturn;
      isReturnInitatied = true;
      lineReturnStatus = listData(cms).lineReturnStatus[orderReturnStatus];
    }

    const size = constant.LARGE;
    const thumbnailImage = image
      ? imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL })
      : constant.NOIMAGESNAP;
    const thumbnail = <Thumbnail source={thumbnailImage} size={size} />;
    const togglePopoverActive = () => {
      setPopoverActive({ [shopifyLineItemId]: !popoverActive[shopifyLineItemId] });
    };
    const activator = (
      <Button onClick={togglePopoverActive} disclosure>
        Manage
      </Button>
    );

    const manageActions = (value) => {
      if (value === constant.VIEW_ID) {
        history.push(`${mainLink.orderNumber}${lineItemOrderId}`);
      }
      if (value === constant.RETURN) {
        toggleModal(shopifyLineItemId);
      }
    };

    const addTrackingDetails = () => {
      if (!isEnabledData && !initialTrackingCompany) {
        setTrackingModal(false);
        setBanner({
          isOpen: true,
          title: cms("section.orderList.label.trackingCompanyRequired"),
          status: constant.CRITICAL,
        });
        return false;
      }

      if (!isEnabledData && !initialTrackingNumber) {
        setTrackingModal(false);
        setBanner({
          isOpen: true,
          title: cms("section.orderList.label.trackingNumberRequired"),
          status: constant.CRITICAL,
        });
        return false;
      }

      const data = {
        trackingNumber: initialTrackingNumber.trim(),
        trackingCompany: initialTrackingCompany.trim(),
      };
      data.id = orderId;
      data.lineItemId = id;
      updateOrderLine({ variables: { input: data } });
      return null;
    };

    const acceptOnlyValidInput = (value) => {
      return baseHelper.stringNotAcceptSpaceAtStart(value) && value;
    };

    const option = [
      {
        content: constant.VIEW_LABEL,
        icon: ViewMajor,
        onAction: () => manageActions(constant.VIEW_ID),
      },
    ];

    const returnOption = {
      content: cms("section.orderList.button.initiateReturn"),
      icon: ReturnMinor,
      onAction: () => manageActions(constant.RETURN),
      disabled:
        item.disbaled ||
        (orderReturn && orderReturn.status !== constant.ACCEPT) ||
        returnedQuantity >= (actualQuantity || quantity) ||
        lineItemFulfillmentStatus !== FULFILLED ||
        (orderReturn && orderReturn.status === constant.ACCEPT && !isReturnAccepted) ||
        isReserve ||
        isCollect ||
        isFullRefund ||
        false,
    };

    const fulfillOption = {
      content: cms("section.orderList.button.fulfillLineItem"),
      icon: QuickSaleMajor,
      onAction: () => {
        togglePopoverActive();
        setTrackingModal({ [shopifyLineItemId]: !trackingModal[shopifyLineItemId] });
      },
      disabled:
        fulfillmentStatus === FULFILLED ||
        status !== ACCEPTED ||
        isVendorFulfilled ||
        [RESERVE, COLLECT, SERVICE].includes(fulfillmentType) ||
        false,
    };

    const deliveredOption = {
      content: cms("section.orderList.button.markedAsDelivered"),
      icon: WholesaleMajor,
      onAction: () => {
        togglePopoverActive();
        updateOrderDeliveryAction();
      },
    };

    if (role !== "vendor") {
      if (fulfillmentType !== constant.SERVICE) {
        option.push(returnOption);
      }

      if (!isCampaign) {
        option.push(fulfillOption);
      }
    }

    if (
      status === constant.CLOSED &&
      !isReturn &&
      [constant.CONSIGNMENT, constant.PICKUP].includes(fulfillmentType) &&
      !isDelivered
    ) {
      status = SHIPPED;
    }

    if (
      [constant.SHIPPED, constant.CLOSED].includes(status) &&
      (!isReturnInitatied ||
        (returnedQuantity < (quantity || actualQuantity) && orderReturn && orderReturn.status === constant.ACCEPT)) &&
      [constant.FULFILLED, constant.SHIPPED_BY_VENDOR, constant.READY_TO_PICK].includes(fulfillmentStatus) &&
      [constant.DROPSHIP, constant.CONSIGNMENT, constant.PICKUP, constant.HYBRID].includes(fulfillmentType) &&
      !isDelivered
    ) {
      option.push(deliveredOption);
    }

    const pageContent = {
      label: {
        status: cms("section.orderList.label.status"),
        fulfillmentStatus: cms("section.orderList.label.fulfillmentStatus"),
      },
    };
    const quantityDisplay = item && item.status === REJECTED ? quantity : actualQuantity;

    const fulfillment = () => {
      return (
        <Modal
          open={trackingModal[shopifyLineItemId]}
          onClose={() => {
            setTrackingModal(false);
            setInitialTrackingNumber("");
            setInitialTrackingCompany("");
          }}
          title={cms("section.orderList.label.addTrackingDetails")}
          primaryAction={{
            content: cms("section.orderList.label.saveTrackingDetails"),
            onAction: () => addTrackingDetails(),
            loading: loadingOrderLineTracking,
          }}
          sectioned
        >
          <Modal.Section>
            <FormLayout>
              <TextField
                label={cms("section.orderList.label.trackingCompany")}
                value={initialTrackingCompany}
                onChange={(val) => {
                  setInitialTrackingCompany(acceptOnlyValidInput(val));
                }}
              />
              <TextField
                label={cms("section.orderList.label.trackingNumber")}
                value={initialTrackingNumber}
                onChange={(val) => {
                  setInitialTrackingNumber(acceptOnlyValidInput(val));
                }}
              />
            </FormLayout>
          </Modal.Section>
        </Modal>
      );
    };

    return (
      <ResourceItem accessibilityLabel={`View details for ${id}`}>
        {fulfillment()}
        <Card.Section>
          <Stack sectioned>
            <Stack.Item>{thumbnail}</Stack.Item>
            <Stack.Item fill>
              <div className="card-description ">
                <Link removeUnderline onClick={() => history.push(`${mainLink.orderNumber}${orderViewId}`)}>
                  {productName}
                </Link>
                <Caption>
                  <TextStyle variation="subdued">
                    {`${cms("section.orderList.label.vendor")}: `}
                    <Link removeUnderline url={`/providers/view/${vendorId}`}>
                      {vendor}
                    </Link>
                  </TextStyle>
                </Caption>
                <Stack>
                  {isDelivered && !isReturn ? (
                    <Stack.Item>
                      <Tooltip content={pageContent.label.status} preferredPosition="above">
                        <Badge
                          progress={statusType ? constant.PARTIAL_COMPLETE : ""}
                          status={baseHelper.getBadgeType(status)}
                        >
                          {constant.DELEVERED}
                        </Badge>
                      </Tooltip>
                    </Stack.Item>
                  ) : (
                    !isRefund &&
                    !isReturn &&
                    !isAutoExpiry && (
                      <Stack.Item>
                        <Tooltip content={pageContent.label.status} preferredPosition="above">
                          <Badge
                            progress={statusType ? constant.PARTIAL_COMPLETE : ""}
                            status={baseHelper.getBadgeType(status)}
                          >
                            {status === PARTIAL_COLLECT ? COLLECTED : baseHelper.ucFirst(status)}
                          </Badge>
                        </Tooltip>
                      </Stack.Item>
                    )
                  )}
                  {isRefund && isAutoReject && (
                    <Stack.Item>
                      <Tooltip content={pageContent.label.status} preferredPosition="above">
                        <Badge status={baseHelper.getBadgeType(status)}>
                          {baseHelper.ucFirst(constant.AUTO_REJECT)}
                        </Badge>
                      </Tooltip>
                    </Stack.Item>
                  )}
                  {isRefund && updatedQuantity && (
                    <Stack.Item>
                      <Tooltip content={pageContent.label.status} preferredPosition="above">
                        <Badge
                          progress={status === PARTIAL_COLLECT ? constant.PARTIAL_COMPLETE : ""}
                          status={baseHelper.getBadgeType(status)}
                        >
                          {status === PARTIAL_COLLECT ? COLLECTED : baseHelper.ucFirst(status)}
                        </Badge>
                      </Tooltip>
                    </Stack.Item>
                  )}
                  {isAutoExpiry && (
                    <Stack.Item>
                      <Tooltip content={pageContent.label.status} preferredPosition="above">
                        <Badge status={baseHelper.getBadgeType(status)}>{baseHelper.ucFirst(constant.NO_SHOW)}</Badge>
                      </Tooltip>
                    </Stack.Item>
                  )}
                  {
                    // eslint-disable-next-line no-nested-ternary
                    isRefund ? (
                      <Stack.Item>
                        <Tooltip content={pageContent.label.fulfillmentStatus} preferredPosition="above">
                          <Badge
                            progress={orderFulfillmentStatus === PARTIAL_REFUND ? constant.PARTIAL_COMPLETE : ""}
                            status={baseHelper.getBadgeType(orderFulfillmentStatus)}
                          >
                            {orderFulfillmentStatus === PARTIAL_REFUND
                              ? REFUND
                              : baseHelper.ucFirst(orderFulfillmentStatus)}
                          </Badge>
                        </Tooltip>
                      </Stack.Item>
                    ) : !isReturn ? (
                      <Stack.Item>
                        <Tooltip content={pageContent.label.fulfillmentStatus} preferredPosition="above">
                          <Badge status={baseHelper.getBadgeType(lineItemFulfillmentStatus)}>
                            {baseHelper.ucFirst(lineItemFulfillmentStatus)}
                          </Badge>
                        </Tooltip>
                      </Stack.Item>
                    ) : (
                      <Stack.Item>
                        {lineItemFulfillmentStatus === FULFILLED &&
                          isReturnInitatied &&
                          showReturnStatus(lineReturnStatus, item)}
                      </Stack.Item>
                    )
                  }
                  {rejectedByVendor && (
                    <Stack.Item>
                      <Tooltip content="Rejected By Vendor" preferredPosition="above">
                        <Badge status={constant.CRITICAL}>{constant.REJECTED_BY_VENDOR}</Badge>
                      </Tooltip>
                    </Stack.Item>
                  )}
                </Stack>
              </div>
            </Stack.Item>
            <Stack.Item>
              <TextStyle>{quantityDisplay}</TextStyle>
            </Stack.Item>
            <Stack.Item>
              <Tooltip content={`${(price * quantityDisplay).toFixed(2)}`}>
                <span className="fix-content">{`${lineItemMoneyFormat}${(price * quantityDisplay).toFixed(2)}`}</span>
              </Tooltip>
              <br />
              <br />
              <div className="manage-button">
                <Popover
                  active={popoverActive[shopifyLineItemId]}
                  activator={activator}
                  onClose={() => setPopoverActive({ [shopifyLineItemId]: !popoverActive[shopifyLineItemId] })}
                  setActive={() => {
                    setPopoverActive({ [shopifyLineItemId]: !popoverActive[shopifyLineItemId] });
                  }}
                >
                  <ActionList items={option} />
                </Popover>
              </div>
              <br />
              <Button
                plain
                monochrome
                disclosure={historyActive[id] ? constant.UP : constant.DOWN}
                onClick={() => {
                  setHistoryActive((prev) => {
                    return {
                      ...prev,
                      [id]: !historyActive[id],
                    };
                  });
                  setExpanded((prev) => {
                    return {
                      ...prev,
                      [id]: !expanded[id],
                    };
                  });
                }}
              >
                {cms("section.orderVersion.button.history")}
              </Button>
            </Stack.Item>
          </Stack>
          <StyledOrderVersioning>
            <div className="itemcollapse-history">
              <Collapsible
                open={historyActive && historyActive[id]}
                id="timeline"
                transition={{ duration: "150ms", timingFunction: "ease" }}
              >
                <OrderVersioning cms={cms} lineItemId={id} orderId={orderId} />
              </Collapsible>
            </div>
          </StyledOrderVersioning>
        </Card.Section>
        <Toast message={message} />
      </ResourceItem>
    );
  };

  const renderModal = () => {
    const { showModal, selectedOrder = false } = initiateReturn;

    const orderToInitiate = viewLineOrderData.find((element) => {
      return element.shopifyLineItemId === selectedOrder;
    });

    if (!orderToInitiate) {
      return null;
    }

    return (
      <OperatorLineReturn
        orderToInitiate={orderToInitiate}
        showModal={showModal}
        disable={disable}
        setDisable={setDisable}
        onClose={() => toggleModal()}
        refetch={refetchOrderLineView}
      />
    );
  };

  return (
    <>
      {renderModal()}
      {isError && (
        <Layout.Section>
          <div style={{ display: "flex" }}>
            <NotFound />
          </div>
        </Layout.Section>
      )}
      <div className="card-header">
        <Card sectioned>
          <Stack>
            <Stack.Item>Product</Stack.Item>
            <Stack.Item fill>Description</Stack.Item>
            <Stack.Item>Quantity</Stack.Item>
            <Stack.Item>Price</Stack.Item>
          </Stack>
        </Card>
      </div>
      <ResourceList items={viewLineOrderData} renderItem={renderItem} selectedItems={selectedItems} />
      <div className="card-footer">
        <Card sectioned>
          <Stack>
            <Stack.Item fill>
              <TextStyle variation="strong">Total</TextStyle>
            </Stack.Item>
            <Stack.Item>
              <span className="light-text">
                {role === "vendor"
                  ? `(${totalOrderItem} ${totalOrderItem > 1 ? ITEMS : ITEM})`
                  : `(${totalOrderItem} ${totalOrderItem > 1 ? ITEMS : ITEM} from ${totalOrderVendor} ${
                      totalOrderVendor > 1 ? VENDORS : VENDOR
                    })`}
              </span>
              <TextStyle variation="strong">{`${moneyFormat}${totalPrice.toFixed(2)}`}</TextStyle>
            </Stack.Item>
          </Stack>
        </Card>
      </div>
    </>
  );
};

export default LineItem;
