import React, { useContext, useState } from "react";
import { Card, Layout, Link, TextContainer } from "@shopify/polaris";
import {
  ActivitiesMajor,
  AddProductMajor,
  CustomerPlusMajor,
  CollectionsMajor,
  ChatMajor,
} from "@shopify/polaris-icons";

import { PlanCard } from "app/plans";
import { QuickLink, QuickReport } from "app/reports";
import { TwoColumnLayout } from "layout/private/components";
import { PrivateContext } from "lib/context";
import { Banner, ModalPopup, PermissionBanner, SkeletonList, Toast } from "lib/components";

// import helper
import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import { Activity } from "app/userManagement/modules";
import { withErrorBoundary } from "lib/hoc";
import UpgradAppPermission from "app/appPermission/modules/UpgradAppPermission";

import OperatorExportReporting from "app/dashboard/modules/operator/export/export";
import ApiAccess from "./apiAccess/apiAccess";

const OperatorDashboard = () => {
  const { isLoading, cms = {}, currentUser, isShopifyCustomOperator } = useContext(PrivateContext);

  const { additionalStoreInfo } = currentUser;
  const { billing } = additionalStoreInfo || {};
  const isShopifySubscription = !!(billing && billing.id);

  const { ecommercePlatform, fulfillmentScopeExists = false, plan: { price = 0, isPAYE = false } = {} } = currentUser;
  const [isExportModal, setIsExportModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [appUpdate, setAppUpdate] = useState(storageHelper.get("customAppUpdate"));
  const [isOpen, setIsOpen] = useState(false);

  const apiAccessPricePlan = [899, 1299];

  const handleShow = () => {
    setIsExportModal(true);
  };

  // eslint-disable-next-line consistent-return
  const getSecondaryComponent = () => {
    if (cms) {
      const linkData = [
        {
          caption: cms("common.actions.dashboard.caption.activity"),
          label: cms("common.actions.dashboard.label.activity"),
          link: "/activity",
          icon: ActivitiesMajor,
        },
        {
          caption: cms("common.actions.dashboard.caption.addProducts"),
          label: cms("common.actions.dashboard.label.addProducts"),
          link: ecommercePlatform ? "/product-lake/add" : "/products/add",
          icon: AddProductMajor,
        },
        {
          caption: cms("common.actions.dashboard.caption.addVendors"),
          label: cms("common.actions.dashboard.label.addVendors"),
          link: "/providers/add",
          icon: CustomerPlusMajor,
        },
        {
          caption: cms("common.actions.dashboard.caption.contactUs"),
          label: cms("common.actions.dashboard.label.contactUs"),
          link: "/contact-us",
          icon: ChatMajor,
        },
        {
          onClick: handleShow,
          caption: cms("common.actions.dashboard.caption.report"),
          id: "exportOrder",
          label: cms("common.actions.dashboard.label.report"),
          icon: CollectionsMajor,
        },
      ];

      return (
        <>
          {isPAYE && (
            <ApiAccess
              apiAccessPricePlan={apiAccessPricePlan}
              price={price}
              setBanner={setBanner}
              setSuccessMessage={setSuccessMessage}
            />
          )}
          {apiAccessPricePlan.includes(price) && !isPAYE && (
            <ApiAccess
              apiAccessPricePlan={apiAccessPricePlan}
              price={price}
              setBanner={setBanner}
              setSuccessMessage={setSuccessMessage}
            />
          )}
          <PlanCard />
          <QuickLink link={linkData} />
          <QuickReport />
        </>
      );
    }
  };
  const closeBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const handleClose = () => {
    // storageHelper.set("customAppUpdate", "No");
    storageHelper.set("customAppUpdate", "updated");
    setIsOpen(false);
  };

  const getComponent = () => {
    if (isLoading && !price) {
      return <SkeletonList />;
    }
    return (
      <>
        {banner.isOpen && (
          <>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              onDismiss={() => closeBanner()}
            />
            <br />
          </>
        )}
        <Activity />
        <OperatorExportReporting isOpenModal={isExportModal} setIsOpenModal={setIsExportModal} />
        <Toast message={successMessage} />
      </>
    );
  };

  const showBanner = () => {
    return (
      <Layout.Section>
        <Banner
          isOpen
          action={{ content: "Migrate to Custom App", onAction: () => setIsOpen(true) }}
          title={cms("common.popup.migrate.title")}
          status="critical"
        >
          <p>{cms("common.popup.migrate.description")}</p>
        </Banner>
        <ModalPopup open={isOpen} onClose={handleClose} title={cms("common.popup.migrate.banner.title")}>
          <Card>
            <TextContainer>
              <Banner
                isOpen
                status="critical"
                action={{
                  content: `${cms("common.appPermission.label.aboutCustom")}`,
                  url: constant.ABOUT_OPERATOR_APP_MIGRATE,
                }}
              >
                <TextContainer>{cms("common.popup.migrate.description")}</TextContainer>
              </Banner>
            </TextContainer>
          </Card>
          <UpgradAppPermission />
        </ModalPopup>
        <br />
      </Layout.Section>
    );
  };

  return (
    <>
      {!isLoading && !isShopifyCustomOperator && appUpdate === "updated" && showBanner()}
      {!isLoading && isShopifyCustomOperator && !fulfillmentScopeExists && <PermissionBanner />}
      {isShopifySubscription && (
        <Layout.Section>
          <Banner isOpen status="info" title="IMPORTANT NOTICE">
            Dear valued customer please note that Marketcube is switching its billing form being Shopify management to a
            self managed model. This will require you to respond to an email we will send you to allow you to add your
            card details against out direct, stripe based, billing platform. The billing will need to be switched over
            before the 30th of December 2022, ready for the start of 2023. Failure to do so will limit access to the
            platform, however, our team is on hand and available to help you through this transition.
          </Banner>
        </Layout.Section>
      )}
      <TwoColumnLayout primary={getComponent()} secondary={getSecondaryComponent()} />,
    </>
  );
};

export default withErrorBoundary(OperatorDashboard);
