import React, { useContext, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import {AppProvider,
  Badge,
  Frame,
  Layout,
  Link,
  Page,
  TopBar,
  DisplayText,
  Icon,
  SkeletonDisplayText,
  Stack,
  TextStyle,
  Button,
  Card,
  Modal,
  TextContainer,
} from "@shopify/polaris";
import { ThemeProvider } from "asset/styles/themeStyle";
import { ExternalMinor } from "@shopify/polaris-icons";
import enTranslations from "@shopify/polaris/locales/en.json";
import { PrivateContext, PrivateBrandingContext } from "lib/context";
import { Banner, Spinner, SkeletonAnnotated } from "lib/components";
import { Plan } from "app/plans";
import { baseHelper, storageHelper, accessHelper, imageHelper, chatHelper, yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";

// NOTE: Upcoming onboarding component
import { StepperOnboarding } from "app/onboarding/modules";
import AppPermission from "app/appPermission/modules";
import UpgradAppPermission from "app/appPermission/modules/UpgradAppPermission";

import SEO from "lib/components/seo/seo";
import privatePageContent from "routes/private/pageContent/pageContent";
import { MainHeader, LoadingWrapper } from "layout/generic/genericStyle";
import PopupModal from "./components/popupModal/popupModal";
import InformationModal from "./components/informationModal/informationModal";

import { Tabs, Footer, Header, NotAllowed, NotFound } from "./components";

// import styles
import { StyledPage } from "./privateStyle";

const PrivateLayout = (props) => {
  const {
    checkIfCustomOnboard,
    cms,
    currentUser,
    history,
    isChargeBeeSeller = false,
    isChargeBeeLoading,
    isFullWidth = false,
    isLoading,
    isNotAllowed = false,
    isNotFound = false,
    isShopifyCustomOperator = false,
    isShopConnected = false,
    isShopifyOldVendor,
    isStripeSeller = false,
    isTabsHidden = false,
    match = {},
    pageData = {},
  } = useContext(PrivateContext);
  const { ecommercePlatform = "" } = currentUser || {};

  let menuColor;
  let headingColor;
  let contentColor;
  let toastBackground;
  let toastText;

  if (currentUser && currentUser.style) {
    menuColor = currentUser.style.menuTabColor || "";
    headingColor = currentUser.style.headingColor || "";
    contentColor = currentUser.style.contentColor || "";
    toastBackground = currentUser.style.toastBackground || "";
    toastText = currentUser.style.toastText || "";
  }
  const { branding, brandingLoading = false } = useContext(PrivateBrandingContext);
  const {
    brand,
    brandName: sellerBrandName,
    footerText: brandingFooterText,
    headerColor: brandingHeaderColor = "",
    headerFont: brandingHeaderFontColor = "",
    isDefaultLogo = false,
    isHideMc = false,
    logoUrl: brandingLogo = "",
  } = branding || {};

  const { title = "", breadcrumbs = [], pageClassName = "", subtitle = "" } = pageData;
  const [logoutLoading, setLogoutLoading] = useState(false);
  const { children = null, setIsOnboarding, onboardingTab } = props;
  const { userKey, url, VENDOR, LOGO: logo } = constant;
  const [headerColor, setHeaderColor] = useState(storageHelper.get("headerColor"));

  const [headerFontColor, setHeaderFontColor] = useState(storageHelper.get("headerFontColor"));
  const [hideMcDetails, setHideMcDetails] = useState(storageHelper.get("hideMcDetails"));
  const [logoUrl, setLogoUrl] = useState(storageHelper.get("logoUrl"));
  const [faviconURL, setFaviconURL] = useState(storageHelper.get("faviconURL"));
  const [footerText, setFooterText] = useState(storageHelper.get("footerText"));
  const [customAppUpdate, setCustomAppUpdate] = useState(storageHelper.get("customAppUpdate"));

  const { favicon } = brand || {};
  const { imageUrl: brandingFaviconURL = "" } = favicon || {};

  const isOnboardingCompleted =
    (currentUser && currentUser.setupProgress === null) || typeof storageHelper.get("setupProgress") === "undefined";
  const onboardingStep = currentUser && currentUser.setupProgress;

  const isVendor = baseHelper.isVendor(currentUser);
  const isSeller = baseHelper.isSeller(currentUser);

  const { plan = {} } = currentUser || {};
  const isBigCommerceSeller = baseHelper.isBigCommerceUser(currentUser);
  const { isDuePayment = false, paymentStatus = "" } = plan || {};
  const isOpenModal = isDuePayment || (paymentStatus && ["nonRenewing", "paused", "cancelled"].includes(paymentStatus));

  // When Vendor has readonly permission
  const { name } = currentUser && currentUser.roles;
  const onboardingNotRequired =
    (currentUser && name === VENDOR && currentUser.isReadOnly) || typeof currentUser.isReadOnly === "undefined";

  if (!(brandingLoading || isLoading)) {
    if (hideMcDetails || currentUser.isHideMcInfo) {
      chatHelper.hide();
    }
    if (!hideMcDetails || !currentUser.isHideMcInfo) {
      chatHelper.show();
    }
  }

  const theme = {
    colors: {
      topBar: {
        // background: headerColor && headerColor !== "" ? headerColor : "#36454f",
        // color: headerFontColor && headerFontColor !== "" ? headerFontColor : "",
      },
    },
  };

  const { path = "/" } = match;
  const setChatWindowZindex = (zIndex) => {
    // const chat = document.getElementById("tidio-chat-iframe");
    // if (chat) {
    // chat.style.zIndex = zIndex;
    // }
    const vendorChat = document.getElementById("__talkjs_launcher");
    if (vendorChat) {
      vendorChat.style.zIndex = zIndex;
    }
  };
  useEffect(() => {
    const currentStep = onboardingTab || (currentUser && currentUser.setupProgress) || "welcome";

    const sellerTabCMS = {
      welcome: null,
      about: null,
      businessInfo: null,
      commission: { cms: "commission", module: "setting" },
      term: { cms: "terms", module: "setting" },
      vendor: { cms: "addVendor", module: "provider" },
    };
    const vendorTabCMS = {
      welcome: null,
      updateProfile: { cms: "editProfile", module: "setting" },
      // product: { cms: "addProductV1", module: "product" },
    };
    if (!isOnboardingCompleted) {
      if (isSeller) {
        setIsOnboarding(sellerTabCMS[currentStep]);
      }
      if (isVendor) {
        setIsOnboarding(vendorTabCMS[currentStep]);
      }
    }
  }, [isOnboardingCompleted, currentUser, isSeller, setIsOnboarding, isVendor, onboardingTab]);

  useEffect(() => {
    /*
    brandingFooterText should have some value &&
    brandingFooterText should not be equal to footerText
    */
    if (brandingFooterText && footerText !== brandingFooterText) {
      storageHelper.set("footerText", brandingFooterText);
      setFooterText(brandingFooterText);
    }
    /*
    brandingHeaderColor should have some value &&
    brandingHeaderColor should not be equal to headerColor
    */
    if (brandingHeaderColor && brandingHeaderColor.trim() !== "" && headerColor !== brandingHeaderColor) {
      storageHelper.set("headerColor", brandingHeaderColor);
      setHeaderColor(brandingHeaderColor);
    }
    /*
    brandingHeaderFontColor should have some value &&
    brandingHeaderFontColor should not be equal to headerFontColor
    */
    if (
      brandingHeaderFontColor &&
      brandingHeaderFontColor.trim() !== "" &&
      headerFontColor !== brandingHeaderFontColor
    ) {
      storageHelper.set("headerFontColor", brandingHeaderFontColor);
      setHeaderFontColor(brandingHeaderFontColor);
    }

    /*
    (brandingLogo should have some value &&
    brandingLogo should not be equal to logoUrl)
    OR isDefaultLogo is true and brandingLogo should not be equal to logoUrl
    */
    if ((isDefaultLogo || (brandingLogo && brandingLogo.trim() !== "")) && brandingLogo !== logoUrl) {
      storageHelper.set("logoUrl", brandingLogo);
      setLogoUrl(brandingLogo);
    }

    if (!(isLoading || brandingLoading) && brandingFaviconURL !== faviconURL) {
      storageHelper.set("faviconURL", brandingFaviconURL);
      setFaviconURL(brandingFaviconURL);
    }

    // show/hide support chat according to branding values

    if (!(brandingLoading || isLoading)) {
      setHideMcDetails(isHideMc);
    }
    // It is overlapping with advance filter window
    setChatWindowZindex("999");
  }, [
    brandingHeaderColor,
    brandingHeaderFontColor,
    brandingLogo,
    brandingLoading,
    logoUrl,
    headerColor,
    headerFontColor,
    brandingFaviconURL,
    brandingFooterText,
    faviconURL,
    footerText,
    isDefaultLogo,
    isOnboardingCompleted,
    setIsOnboarding,
    onboardingTab,
    onboardingStep,
    isVendor,
    isSeller,
    isHideMc,
    isLoading,
  ]);
  useEffect(() => {
    if (
      isSeller &&
      ecommercePlatform !== "bigCommerce" &&
      !currentUser.setupProgress &&
      !isShopifyCustomOperator &&
      storageHelper.get("customAppUpdate") !== "updated"
    ) {
      storageHelper.set("customAppUpdate", "No");
    }
    if (isSeller && ecommercePlatform !== "bigCommerce" && !currentUser.setupProgress && isShopifyCustomOperator) {
      storageHelper.remove("customAppUpdate");
    }
    if (isShopConnected && isShopifyOldVendor && storageHelper.get("customVendorAppUpdate") !== "updated") {
      storageHelper.set("customVendorAppUpdate", "No");
    }
    if (isShopConnected && !isShopifyOldVendor) {
      storageHelper.remove("customVendorAppUpdate");
    }
  }, [
    checkIfCustomOnboard,
    isSeller,
    isShopifyCustomOperator,
    isShopConnected,
    isShopifyOldVendor,
    currentUser,
    ecommercePlatform,
  ]);
  const getPageTitle = () => {
    if (isLoading) {
      return null;
    }
    const isAdmin = currentUser.role === userKey.systemAdminRole;
    if (isAdmin) {
      return `${cms("title")}, System Admin`;
    }
    const fullName = baseHelper.getFullName(currentUser);
    return fullName ? `${cms("common.label.goodDay")}, ${fullName}` : `${cms("title")}`;
  };

  const getSubTitle = (pageSubTitle = "") => {
    const subTitle = pageSubTitle;
    return subTitle;
  };

  const getPrimaryAction = (primaryAction) => {
    if (!primaryAction || isLoading) {
      return null;
    }
    if (primaryAction.id === privatePageContent.dashboard.primaryAction.id && hideMcDetails) {
      // hide help center icon
      return null;
    }
    const content = (
      <Stack>
        <Icon source={ExternalMinor} />
        <div className="help_text">
          <TextStyle>{cms("common.button.help")}</TextStyle>
        </div>
      </Stack>
    );
    const updatedPrimaryAction = { ...primaryAction };
    if (!updatedPrimaryAction.onAction) {
      updatedPrimaryAction.onAction = () => history.push(primaryAction.url);
      delete updatedPrimaryAction.url;
      if (primaryAction.link) {
        updatedPrimaryAction.url = primaryAction.link;
        updatedPrimaryAction.content = content;
        delete updatedPrimaryAction.onAction;
      }
    }
    return updatedPrimaryAction;
  };

  const getUserRole = () => {
    if (isLoading) {
      return null;
    }

    const userPlan = (currentUser.plan && (currentUser.plan.code || currentUser.plan.name)) || "";
    const isPAYE = (currentUser.plan && currentUser.plan.isPAYE) || "";

    return [
      <Badge status={constant.SUCCESS} key="pageUserRoleBadge">
        {baseHelper.ucFirst(accessHelper.getUserRole(currentUser))}
      </Badge>,
      isSeller && userPlan && !isPAYE && (
        <Badge status={constant.SUCCESS} key="pagePlanBadge">
          {baseHelper.getPlanBadge(userPlan)}
        </Badge>
      ),
      currentUser.isReadOnly && (
        <Badge status={constant.WARNING} key="pageReadOnlyBadge">
          {baseHelper.ucFirst(constant.READ_ONLY)}
        </Badge>
      ),
    ];
  };

  if (logoutLoading) {
    return <Spinner isFullPage />;
  }
  function CustomAppUpdateModle() {
    const [appUpdate, setAppUpdate] = useState(storageHelper.get("customAppUpdate"));

    const handleClose = () => {
      setAppUpdate(!appUpdate);
      // storageHelper.set("customAppUpdate", "No");
      storageHelper.set("customAppUpdate", "updated");

      history.push("/");
    };
    useEffect(() => {
      if (appUpdate === "No") {
        // document.getElementsByClassName("Polaris-Modal-CloseButton")[0].style.display = "none";
      }
    }, [appUpdate]);
    if (appUpdate === "No") {
      return (
        <div style={{ height: "500px" }}>
          <Modal
            open={appUpdate}
            onClose={handleClose}
            title="Upgrade to App permission"
            // primaryAction={{
            //   content: "Submit",
            //   onAction: handleChange,
            // }}
          >
            <Card>
              <Modal.Section>
                <TextContainer>
                  <Banner
                    isOpen
                    status="critical"
                    action={{
                      content: `${cms("common.appPermission.label.aboutCustom")}`,
                      url: constant.ABOUT_OPERATOR_APP_MIGRATE,
                    }}
                  >
                    <TextContainer>{cms("common.popup.migrate.description")}</TextContainer>
                  </Banner>
                </TextContainer>
              </Modal.Section>
            </Card>
            <UpgradAppPermission />
          </Modal>
        </div>
      );
    }
    return null;
  }

  const getChildren = () => {
    // storageHelper.set("customAppUpdate", "No");
    if (currentUser && isChargeBeeLoading) {
      return <SkeletonAnnotated />;
    }
    if (isSeller && !isLoading && storageHelper.get("customAppUpdate") === "No") {
      return <CustomAppUpdateModle />;
    }

    // if (isSeller && currentUser && !currentUser.ecommercePlatform && !currentUser.shopifyAccessToken) {
    //   return <AppPermission />;
    // }

    // if (isSeller && currentUser && currentUser.shopifyAccessToken && !currentUser.planId) {
    if (
      (isSeller || isBigCommerceSeller || (isVendor && (isChargeBeeSeller || isStripeSeller))) &&
      currentUser &&
      !currentUser.planId &&
      !isOnboardingCompleted
    ) {
      return (
        <Layout>
          <Plan />
        </Layout>
      );
    }

    const { admin } = userKey;
    const isSystemAdmin = storageHelper.get("userRole") === admin;
    if (isSystemAdmin || isOnboardingCompleted || onboardingNotRequired) {
      return (
        <>
          {!isTabsHidden && <Tabs />}
          <div className={`tabs-content ${pageClassName}_${storageHelper.get("userRole")}`}>
            <Layout>{children}</Layout>
            <InformationModal openModal={isOpenModal} />
          </div>
        </>
      );
    }

    return <StepperOnboarding />;
  };

  const getComponent = () => {
    if (isLoading && storageHelper.get("isInitial")) {
      return <SkeletonAnnotated />;
    }

    // if current user do not have plan id then redirect to /plan route
    const isPlanRoute = path === "/plan";

    if (!isPlanRoute && isSeller && currentUser && !currentUser.planId && currentUser.shopifyAccessToken) {
      return (
        <Redirect
          to={{
            pathname: "/plan",
          }}
        />
      );
    }

    // redirect to "/" until onboarding is completed
    const isRootRoute = path === "/";
    const { admin } = userKey;
    const isSystemAdmin = storageHelper.get("userRole") === admin;
    if (!isPlanRoute && isSeller && currentUser && currentUser.shopifyAccessToken && !currentUser.planId) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }

    if (isNotAllowed) {
      return <NotAllowed />;
    }
    if (isNotFound) {
      return <NotFound />;
    }

    return (
      <>
        <SEO
          loading={isLoading || brandingLoading}
          title={sellerBrandName}
          favicon={faviconURL}
          logo={logoUrl}
          fontType={currentUser.font}
        />
        <StyledPage className="page-wrapper">
          <Page
            breadcrumbs={breadcrumbs}
            title={
              title
                ? (isLoading && <SkeletonDisplayText size="medium" />) ||
                  (path === url.ORDER || path === url.ORDER_LAKE ? title : cms(`common.pageTitle.${title}`))
                : getPageTitle()
            }
            titleMetadata={getUserRole()}
            primaryAction={getPrimaryAction(pageData.primaryAction)}
            subtitle={getSubTitle(subtitle)}
            fullWidth={isFullWidth}
          >
            {getChildren()}
            {!isSystemAdmin && <Footer footerText={footerText || ""} />}
          </Page>
        </StyledPage>
      </>
    );
  };

  const getMenuLogo = (brandName) => {
    const url = (logoUrl && logoUrl.trim() !== "" && logoUrl) || (!brandName && logo);
    if (url) {
      return (
        <img
          className="brandLogo"
          src={imageHelper.resize({ url, type: constant.imageTypes.HEADER_LOGO })}
          alt="Brand Logo"
        />
      );
    }
    return (
      <div className="brandTitle">
        <DisplayText>{baseHelper.ucFirst(brandName)}</DisplayText>
      </div>
    );
  };

  const linkStyle = {
    textDecorationLine: "none",
    cursor: "pointer",
  };

  const menuText = (brandName) => (
    <a style={linkStyle} href="/">
      {getMenuLogo(brandName)}
    </a>
  );

  const isValidHex = (hexCode) => yupHelper.hexRegex.test(hexCode || "");

  const getPage = () => {
    const Component = getComponent();

    // if in iframe, then don't show the header
    if (baseHelper.isIframe()) {
      return Component;
    }
    const userBrandName = sellerBrandName || storageHelper.get("brandName");
    return (
      <Frame
        topBar={
          ((isLoading || brandingLoading) && (
            <LoadingWrapper>
              <SkeletonDisplayText size="extraLarge" />
            </LoadingWrapper>
          )) || (
            <MainHeader
              background={isValidHex(headerColor) && headerColor}
              color={isValidHex(headerFontColor) && headerFontColor}
              className="main-header"
            >
              <TopBar
                userMenu={<Header setLogoutLoading={setLogoutLoading} cms={cms} />}
                contextControl={menuText(userBrandName)}
              />
            </MainHeader>
          )
        }
      >
        {Component}
      </Frame>
    );
  };

  const isPlanRoute = path === "/plan";
  return (
    <ThemeProvider
      className="themeProvider"
      contentColor={contentColor}
      headingColor={headingColor}
      tabColor={menuColor}
      toastBackground={toastBackground}
      toastColor={toastText}
    >
      <AppProvider i18n={enTranslations} theme={theme}>
        {!baseHelper.isIframe() && !isPlanRoute && <PopupModal cms={cms} />}
        {getPage()}
      </AppProvider>
    </ThemeProvider>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.node,
  setIsOnboarding: PropTypes.func,
  onboardingTab: PropTypes.string,
};

PrivateLayout.defaultProps = {
  children: null,
  setIsOnboarding: () => {},
  onboardingTab: null,
};

export default PrivateLayout;
