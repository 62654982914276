// import react packages;
import React, { useState, useEffect } from "react";

// import polaris components
import { Banner, Card, Checkbox, Layout, Modal, Scrollable, Stack, Subheading, List } from "@shopify/polaris";
import constant from "lib/constant/constant";
// import config

const HelpModal = (props) => {
  const { isOpen, loading, onSubmit, setIsOpen } = props;

  return (
    <Modal
      large
      open={isOpen}
      title="Generate your Magento Access token"
      onClose={() => setIsOpen(false)}
      primaryAction={{
        content: "Done",
        onAction: () => onSubmit(),
        loading,
      }}
    >
      <Modal.Section>
        <Stack vertical>
          <Layout>
            <Layout.Section>
              <Card sectioned title="Create your Magento 2 access token">
                In your Magento 2 account: <br/> <br/>
                <List type="number">
                    <List.Item>Go to <b>System &gt; Integrations.</b></List.Item>
                    <List.Item>Click <b>Add new integration.</b></List.Item>
                    <List.Item>
                        In the integration setup: <br/>
                        a. Populate the name and email address fields, and populate your Magento 2 account password. <br />
                        b. At <b>API</b>, under <b>Resource Access</b>, select <b>Custom</b> from the drop-down menu. Then scroll down and select the <b>Catalog</b> and <b>Stores</b> checkboxes.
                    </List.Item>
                    <List.Item>Click <b>Save</b>.</List.Item>
                    <List.Item>You will then return to the <b>Integrations</b> page where you will see your new integration.</List.Item>
                    <List.Item>Next to your integration, click <b>Activate</b>, then <b>Allow</b>.</List.Item>
                    <List.Item>Magento 2 will show you your API credentials.</List.Item>
                </List>
              </Card>
            </Layout.Section>
          </Layout>
          <Banner status={constant.WARNING}>
            <b>IMPORTANT:</b> Copy and store the Access token key displayed, as you will need it in order to Connect to Magento 2. (You will not need the Consumer key, Consumer secret, or Access token secret for this integration.)
          </Banner>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

export default HelpModal;
