import React, { useContext, useEffect, useState } from "react";
import { Badge, Caption, Card, Checkbox, DataTable, Layout, PageActions, TextField, TextStyle } from "@shopify/polaris";
import { baseHelper, errorHelper } from "lib/helpers";
import { withErrorBoundary, withFeature } from "lib/hoc";
import { PrivateContext } from "lib/context";
import { Toast, Banner } from "lib/components";
import constant from "lib/constant/constant";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { UPDATE_PAYMENT_EXPORT_SETTING } from "app/setup/apollo/mutations";
import { GET_PAYMENT_EXPORT_SETTING } from "app/setup/apollo/queries";

import ExportTable from "./style";

const { gql } = constant;

const PaymentExportSetting = () => {
  const {
    CRITICAL,
    HIDDEN,
    IS_CSV,
    IS_PDF,
    MARKUP_PRICE,
    PARTIALLY_HIDDEN,
    PAYMENT_COLUMN,
    PAYMENT_TABLE_HEADING,
    SUCCESS,
    VENDOR,
    VISIBLE,
    WARNING,
    VENDOR_AMOUNT_KEY,
  } = constant;

  const { cms, currentUser, history } = useContext(PrivateContext);
  const isVendor = baseHelper.isVendor(currentUser);
  const [submitEnabled, setSubmitEnable] = useState(false);
  const [firstPush, setFirstPush] = useState(true);
  const [checked, setChecked] = useState(false);
  const [message, setMessage] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  // const [userInput, setUserInput] = useState(paymentTableData);
  const [userInput, setUserInput] = useState({
    date: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    orderNumber: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    invoiceId: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    lineItemId: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    vendor: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    product: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    sku: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    quantity: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    price: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    discount: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    markupPrice: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    paymentStatus: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    createdAt: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    tax: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    shipping: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    shippingTax: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    operatorCommission: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    commissionTax: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    orderPayout: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    actualPayoutDate: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    shippingPayout: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    totalVendorPayout: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    vendorAmount: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
    status: {
      isHideCsv: false,
      isHidePdf: false,
      label: "",
    },
  });

  const [paymentSetting, { loading: settingUpdateLoading }] = useMutation(UPDATE_PAYMENT_EXPORT_SETTING);

  const { error: errorPaymentExportSetting, data: dataPaymentExportSetting, refetch } = useQuery(
    GET_PAYMENT_EXPORT_SETTING
  );

  useEffect(() => {
    let allChecked = true;
    const newState = { ...userInput };
    const keys = Object.keys(newState);
    keys.forEach((key) => {
      const { isHideCsv, isHidePdf } = newState[key];
      if (!isHideCsv || !isHidePdf) {
        allChecked = false;
      }
    });
    if (allChecked) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [userInput]);

  useEffect(() => {
    if (dataPaymentExportSetting) {
      const paymentData = baseHelper.getResponseData(dataPaymentExportSetting, gql.GET_PAYMENT_EXPORT_SETTING);
      const paymentError = baseHelper.getResponseError(errorPaymentExportSetting, gql.GET_PAYMENT_EXPORT_SETTING);
      const data = paymentData.export;
      if (data) {
        const keys = Object.keys(userInput);
        keys.forEach((key) => {
          if (!data[key]) {
            data[key] = {
              label: "",
              isHideCsv: false,
              isHidePdf: false,
            };
          }
        });
        if (data.createdAt) {
          setUpdatedAt(paymentData.updatedAt);
          setFirstPush(false);
          setUserInput(data);
        }
        if (paymentError) {
          setBannerStatus({ isOpen: true, title: paymentError, status: constant.CRITICAL });
        }
      }
    }
  }, [dataPaymentExportSetting, errorPaymentExportSetting]);

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 2000);
  }, [message]);

  const verifyValues = () => {
    setSubmitEnable(false);
    const newData = [];
    const currentLabel = [];
    const noUncheckCsv = Object.keys(userInput)
      .filter((item) => {
        return (
          (!isVendor && item !== MARKUP_PRICE && item !== VENDOR_AMOUNT_KEY) ||
          (isVendor && item !== MARKUP_PRICE && item !== VENDOR && item !== VENDOR_AMOUNT_KEY)
        );
      })
      .map((item) => {
        newData.push(userInput[item]);
        currentLabel.push(cms(`label.${item}`));
        if (userInput[item].isHideCsv) {
          return true;
        }
        return false;
      });
    const noUncheckPdf = Object.keys(userInput)
      .filter((item) => {
        return (
          (!isVendor && item !== MARKUP_PRICE && item !== VENDOR_AMOUNT_KEY) ||
          (isVendor && item !== MARKUP_PRICE && item !== VENDOR && item !== VENDOR_AMOUNT_KEY)
        );
      })
      .map((item) => {
        if (userInput[item].isHidePdf) {
          return true;
        }
        return false;
      });

    let response = false;
    newData.forEach((item, i) => {
      newData.forEach((element, index) => {
        if (i === index) return null;
        const found = currentLabel.find((label) => label === element.label);
        if ((element.label && item.label && element.label.trim() === item.label.trim()) || found) {
          response = true;
          setBannerStatus({
            isOpen: true,
            title: cms("message.error.duplicate"),
            status: constant.CRITICAL,
          });
          return false;
        }
        return false;
      });
    });
    if (!response) {
      response = true;
      noUncheckCsv.forEach((item) => {
        if (!item) response = item;
      });
      setBannerStatus({
        isOpen: response,
        title: cms("message.error.uncheckCsv"),
        status: constant.CRITICAL,
      });
    }
    if (!response) {
      response = true;
      noUncheckPdf.forEach((item) => {
        if (!item) response = item;
      });

      setBannerStatus({
        isOpen: response,
        title: cms("message.error.uncheckPdf"),
        status: constant.CRITICAL,
      });
    }
    return response;
  };

  const handleAllChecked = (value) => {
    setSubmitEnable(true);
    setChecked(value);
    const newData = [];
    Object.keys(userInput).map((item) => {
      newData.push(userInput[item]);
    });
    setUserInput((prevState) => {
      const newState = { ...prevState };
      const keys = Object.keys(newState);
      keys.forEach((key, index) => {
        newState[key] = { isHideCsv: value, isHidePdf: value, label: newData[index].label };
      });
      return newState;
    });
  };

  const updatePaymentSetting = async (type) => {
    paymentSetting({ variables: { input: { ...userInput } } })
      .then((res) => {
        if (res) {
          const resData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_PAYMENT_EXPORT_SETTING);
          const resError = baseHelper.getResponseError(res.data, constant.gql.UPDATE_PAYMENT_EXPORT_SETTING);
          if (resData) {
            refetch();
            setMessage(cms("message.success.invoiceUpdate"));
            if (type === constant.SAVE_SMALL) {
              setMessage(cms("message.success.invoice"));
            }
          }
          if (resError) {
            setBannerStatus({ isOpen: true, title: resError, status: constant.CRITICAL });
          }
        }
      })
      .catch((exception) => {
        setBannerStatus({ isOpen: true, title: errorHelper.parse(exception), status: constant.CRITICAL });
      });
  };
  const dismissBanner = () => setBannerStatus({ isOpen: false, status: "", title: "" });

  const handleLabelChange = (val, key) => {
    const labelRegex = /[^a-zA-Z0-9 ]/;
    const updatedData = JSON.parse(JSON.stringify(userInput));
    const newData = updatedData[key];
    newData.label = val.split(labelRegex).join("");
    setUserInput({ ...updatedData, [key]: newData });
    setSubmitEnable(true);
  };

  const selectCurrentLabelsColumn = (item) => <TextStyle>{cms(`label.${item}`) || item}</TextStyle>;
  const selectNewLabelsTextField = (data, key) => (
    <>
      <TextField value={data && data.label} maxLength={35} onChange={(value) => handleLabelChange(value, key)} />
    </>
  );

  const handleCheckbox = (value, key) => {
    const updateData = userInput[key];
    setSubmitEnable(true);
    if (value === IS_CSV) setUserInput({ ...userInput, [key]: { ...updateData, isHideCsv: !updateData.isHideCsv } });
    if (value === IS_PDF) setUserInput({ ...userInput, [key]: { ...updateData, isHidePdf: !updateData.isHidePdf } });
  };

  const badges = (item) => (
    <>
      <Badge status={item.badgesStatus}>{item.badgesLable}</Badge>
    </>
  );
  const selectHideColumn = (index) => (
    <>
      <Checkbox
        label={cms("label.isCsv")}
        checked={userInput[index] && userInput[index].isHideCsv}
        // onChange={(value) => handleCheckbox("isCsv", value, index)}
        onChange={() => handleCheckbox(IS_CSV, index)}
      />{" "}
      <Checkbox
        label={cms("label.isPdf")}
        checked={userInput[index] && userInput[index].isHidePdf}
        onChange={() => handleCheckbox(IS_PDF, index)}
      />
    </>
  );

  const getRows = () => {
    return Object.keys(userInput)
      .filter((item) => {
        return (
          (!isVendor && item !== MARKUP_PRICE && item !== VENDOR_AMOUNT_KEY) ||
          (isVendor && item !== MARKUP_PRICE && item !== VENDOR && item !== VENDOR_AMOUNT_KEY)
        );
      })
      .map((item) => {
        let badge = { badgesLable: VISIBLE, badgesStatus: SUCCESS };
        const itemData = userInput[item];
        if (itemData.isHideCsv && itemData.isHidePdf) {
          badge = { badgesLable: HIDDEN, badgesStatus: CRITICAL };
        } else if (itemData.isHidePdf || itemData.isHideCsv) {
          badge = { badgesLable: PARTIALLY_HIDDEN, badgesStatus: WARNING };
        } else {
          badge = { badgesLable: VISIBLE, badgesStatus: SUCCESS };
        }
        const currentValues = selectCurrentLabelsColumn(item);
        const newValues = selectNewLabelsTextField(userInput[item], item);
        const badgesValues = badges(badge);
        const hideColumnValues = selectHideColumn(item);
        return [[currentValues], [newValues], [hideColumnValues], [badgesValues]];
      });
  };

  return (
    <Layout>
      <Layout.Section>
        {bannerStatus.isOpen && (
          <Layout.Section>
            <Banner
              isOpen={bannerStatus.isOpen}
              status={bannerStatus.status}
              title={bannerStatus.title}
              onDismiss={() => dismissBanner()}
            />
          </Layout.Section>
        )}
        <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
          <Card>
            <Card.Header
              title={[
                cms("label.exportSetting"),
                updatedAt && (
                  <TextStyle variation="subdued">
                    <Caption>{`${baseHelper.lastUpdateDate(updatedAt)}`}</Caption>
                  </TextStyle>
                ),
              ]}
            >
              <Checkbox label={cms("label.markAll")} checked={checked} onChange={(value) => handleAllChecked(value)} />
            </Card.Header>
            <Card.Section>
              <ExportTable className="payment-export-table">
                <DataTable columnContentTypes={PAYMENT_COLUMN} headings={PAYMENT_TABLE_HEADING} rows={getRows()} />
              </ExportTable>
              {!firstPush && (
                <PageActions
                  primaryAction={{
                    content: cms("common.button.update"),
                    onAction: () => {
                      const update = verifyValues();
                      if (!update) updatePaymentSetting();
                    },
                    disabled: !submitEnabled,
                    loading: settingUpdateLoading,
                  }}
                  secondaryActions={[
                    {
                      id: "cancelButton",
                      content: cms("common.button.cancel"),
                      onAction: () => history.push("/setting"),
                    },
                  ]}
                />
              )}
            </Card.Section>
          </Card>
          {firstPush && (
            <div>
              <PageActions
                primaryAction={{
                  content: cms("common.button.submit"),
                  onAction: () => {
                    const update = verifyValues();
                    if (!update) updatePaymentSetting(constant.SAVE_SMALL);
                  },
                  disabled: !submitEnabled,
                  loading: settingUpdateLoading,
                }}
                secondaryActions={[
                  {
                    id: "cancelButton",
                    content: cms("common.button.cancel"),
                    onAction: () => history.push("/setting"),
                  },
                ]}
              />
            </div>
          )}
        </Layout.AnnotatedSection>
        <Toast message={message} timeout={5000} />
      </Layout.Section>
    </Layout>
  );
};

export default withFeature(withErrorBoundary(PaymentExportSetting), { feature: constant.PAYMENT_EXPORT_SETTING });
