/* eslint-disable max-len */
import React, { useState, useCallback, useEffect, useContext } from "react";
import { Badge, Banner, Card, PageActions, FormLayout, Select, TextField, Caption, TextStyle } from "@shopify/polaris";
import { Editor } from "@tinymce/tinymce-react";
import { useQuery, useMutation } from "@apollo/react-hooks";

// import constant
import constant from "lib/constant/constant";

// import component
import { Sheet, SkeletonCard, Toast } from "lib/components";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import mutation
import { EDIT_PRODUCT_INFORMATION } from "app/product/apollo/mutations";

// import queries
import { GET_CURRENCY_RATE, GET_PRODUCT_TAGS, GET_PRODUCT_TYPE, GET_PRODUCT } from "app/product/apollo/queries";

// import context
import { PrivateContext } from "lib/context/privateContext";
import { ProductContext } from "app/product/modules/generic/context";

// import Tag
import editData from "app/product/modules/provider/features/edit/config/editData";
import Tags from "app/product/modules/generic/features/form/tag/tag";

const Information = () => {
  const { handleTabChange, setBanner, setDescription, setTitle } = useContext(ProductContext);
  const { cms, match } = useContext(PrivateContext);
  const { typeOption } = editData(cms);
  const [disabledButton, setDisabledButton] = useState(true);
  const [sheetActive, setSheetActive] = useState(false);
  const [message, setMessage] = useState("");
  const [vendor, setVendor] = useState("");
  const [basePricing, setBasePricing] = useState();
  const [currencyPrice, setCurrencyPrice] = useState();
  const [currencySymbol, setCurrencySymbol] = useState();
  const [rate, setRate] = useState([]);
  const [value, setValue] = useState({});
  // const [sheetTitle, setSheetTitle] = useState("");
  // const [sheetContent, setSheetContent] = useState("");

  const { CRITICAL, gql } = constant;

  setDescription(cms("label.editInformation"));
  setTitle(cms("label.title.information"));

  const {
    value: { MIN_PRICE = 0, MAX_FLAT = 99999999, MAX_AMOUNT, MAX_PERCENTAGE },
  } = constant;

  const { loading: productTypeLoading, data: productTypeData } = useQuery(GET_PRODUCT_TYPE, {
    variables: { input: { vendorId: vendor } },
  });
  const { loading: productTagLoading, data: productTagsData } = useQuery(GET_PRODUCT_TAGS, {
    variables: { input: { vendorId: vendor } },
  });
  const { loading: productLoading, data: productData } = useQuery(GET_PRODUCT, {
    variables: { input: { id: match.params.id } },
  });

  const { loading: currencyRateLoading, data: currencyRateData } = useQuery(GET_CURRENCY_RATE, {
    variables: {
      input: { vendorId: vendor },
    },
  });

  const [updateProduct, { loading: editProductLoading }] = useMutation(EDIT_PRODUCT_INFORMATION);

  const acceptOnlyValidInput = (val, prevValue, max = MAX_FLAT) => {
    if (parseFloat(val) > MIN_PRICE && parseFloat(val) < max) {
      return (baseHelper.validatePositiveNumericValues(val) && val) || (val !== "" && prevValue) || "";
    }
    if (!val) {
      return "";
    }
    return prevValue;
  };

  const handleChange = useCallback(
    (fieldName, fieldValue) => {
      const fieldData = [constant.TITLE, constant.DESCRIPTION];
      setDisabledButton(false);
      setValue({
        ...value,
        [fieldName]: (!fieldData.includes(fieldName) && parseFloat(0)) || fieldValue,
      });
    },
    [value]
  );

  useEffect(() => {
    if (productData) {
      const productResponse = baseHelper.getResponseData(productData, gql.GET_PRODUCT) || {};
      setValue(productResponse);
      setVendor((productResponse && productResponse.vendorId) || "");
    }
  }, [gql.GET_PRODUCT, productData]);

  const productListError = baseHelper.getResponseError(productData, gql.GET_PRODUCT);

  useEffect(() => {
    if (productListError) {
      setBanner({ isOpen: true, status: CRITICAL, title: productListError });
    }
  }, [CRITICAL, cms, productListError, setBanner]);

  useEffect(() => {
    if (productData) {
      const productResponse = baseHelper.getResponseData(productData, gql.GET_PRODUCT) || {};
      const { basePricing: vendorBasePricing } = productResponse || {};
      setValue(productResponse);
      setBasePricing(vendorBasePricing);
    }
  }, [gql.GET_PRODUCT, productData]);

  useEffect(() => {
    if (rate && rate.length && basePricing && basePricing.currencyCode) {
      const currencyData =
        rate && rate.length && rate.find((element) => basePricing.currencyCode === element.currencyCode);
      if (currencyData) {
        const { currencySymbol: symbol, price } = currencyData;
        setCurrencySymbol(symbol);
        setCurrencyPrice(price);
      }
    }
  }, [basePricing, rate]);

  const currencyRateError = baseHelper.getResponseError(currencyRateData, gql.GET_CURRENCY_RATE);

  useEffect(() => {
    if (currencyRateError) {
      return;
    }

    const currencyRateSetting = baseHelper.getResponseData(currencyRateData, constant.gql.GET_CURRENCY_RATE) || {};
    const { currency = {} } = currencyRateSetting || {};
    const { rate: rateData = [] } = currency || {};
    setRate(rateData);
  }, [currencyRateError, currencyRateData]);

  // load product type data
  const productTypeOptions = baseHelper.getResponseData(productTypeData, constant.gql.GET_PRODUCT_TYPE) || [];

  if (currencyRateLoading || productLoading || productTagLoading || productTypeLoading) {
    return <SkeletonCard />;
  }
  // load product tags data
  const availableProductTags = baseHelper.getResponseData(productTagsData, constant.gql.GET_PRODUCT_TAGS) || {};

  const primaryAction = {
    content: cms("button.save"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("button.cancel"),
    onAction: () => setSheetActive(false),
  };

  // const learnMore = (productItem, title) => {
  //   setSheetActive(true);
  //   setSheetTitle(title);
  //   setSheetContent(cms("label.todo")`${productItem}`);
  // };
  const handleCurrencyPrice = (field, newValue) => {
    setBasePricing((prevState) => ({
      ...prevState,
      [field]: newValue,
    }));
    if (currencyPrice) {
      setValue({
        ...value,
        [field]: parseFloat(((1 / currencyPrice) * newValue).toFixed(2)),
      });
    }
  };

  const handleDiscount = (fieldName, fieldValue) => {
    const vendorDiscount = { ...value.vendorDiscount };
    vendorDiscount[fieldName] = fieldValue || parseFloat(0);
    if (fieldName !== constant.PRICE) {
      vendorDiscount.price = null;
    }
    setValue({
      ...value,
      vendorDiscount,
    });
  };

  const { vendorDiscount = {} } = value || {};
  const { price: vendorPrice = "", type: vendorType = "" } = vendorDiscount || {};
  const discountPrice = value.vendorDiscount && value.vendorDiscount.price;
  const discountType = value.vendorDiscount && value.vendorDiscount.type;

  const maxDiscount = (discountType === constant.PERCENTAGE && MAX_PERCENTAGE) || MAX_AMOUNT;

  const renderPricingComponent = () => {
    if (!basePricing || !basePricing.currencyCode) return null;
    const { price = 0, comparePrice = 0, currencyCode } = basePricing || {};
    return (
      <FormLayout.Group>
        <TextField
          id="price"
          label={cms("label.price")}
          min={0}
          value={(price && price.toString()) || ""}
          placeholder={constant.ZERO_PRICE}
          prefix={currencySymbol}
          suffix={currencyCode}
          disabled={!currencyPrice}
          onChange={(val) => {
            handleCurrencyPrice(constant.PRICE, acceptOnlyValidInput(val, price), currencyCode);
            setDisabledButton(false);
          }}
        />
        <TextField
          id="comparePrice"
          label={cms("label.comparePrice")}
          min={0}
          placeholder={constant.ZERO_PRICE}
          prefix={currencySymbol}
          suffix={currencyCode}
          disabled={!currencyPrice}
          value={(comparePrice && comparePrice.toString()) || ""}
          onChange={(val) => {
            handleCurrencyPrice(constant.COMPARE_PRICE, acceptOnlyValidInput(val, comparePrice), currencyCode);
            setDisabledButton(false);
          }}
        />
      </FormLayout.Group>
    );
  };

  const selectDiscount = (
    <Select
      value={discountType || ""}
      options={typeOption}
      placeholder={cms("placeholder.selectType")}
      onChange={(val) => {
        handleDiscount(constant.TYPE, val);
        setDisabledButton(false);
      }}
    />
  );

  const onFormSubmit = async () => {
    const { _id: id = "" } = value;
    const { price, comparePrice } = basePricing || {};
    const formValues = {
      productId: id,
      title: value.title,
      basePricing: {
        price: parseFloat(price || 0),
        comparePrice: parseFloat(comparePrice || 0),
      },
      description: value.description,
      productType: value.productType,
      price: parseFloat(value.price),
      comparePrice: parseFloat(value.comparePrice),
      tags: value.tags,
      discount: {
        price: parseFloat(discountPrice),
        type: discountType,
      },
    };

    await updateProduct({ variables: { input: formValues } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.EDIT_PRODUCT_INFORMATION);
        const responseData = baseHelper.getResponseData(res.data, gql.EDIT_PRODUCT_INFORMATION);
        if (responseError) {
          setBanner({ isOpen: true, status: CRITICAL, title: responseError });
        }
        if (responseData) {
          setMessage(cms("message.success.information"));
          setTimeout(() => {
            handleTabChange(1);
          }, 1500);
        }
      })
      .catch((exception) => {
        setBanner({ isOpen: true, status: CRITICAL, title: errorHelper.parse(exception) });
      });
  };
  return (
    <>
      <Card
        title={[
          cms("label.productInformation"),
          value && value.updatedAt && (
            <TextStyle variation="subdued">
              <Caption>{`${baseHelper.lastUpdateDate(value.updatedAt)}`}</Caption>
            </TextStyle>
          ),
        ]}
        id="productInformation"
        // actions={[
        //   {
        //     content: "Learn More",
        //     onAction: () => {
        //       learnMore("productInformation", cms("label.productInformation"));
        //     },
        //   },
        // ]}
      >
        <Card.Section>
          <p>{cms("label.infoDescription")}</p>
          <br />
          <FormLayout>
            <TextField
              label={`${cms("label.title.informationTitle")}*`}
              required="true"
              onChange={(val) => {
                handleChange(constant.TITLE, val);
                setDisabledButton(false);
              }}
              error=""
              value={value.title || ""}
              onBlur={() => value && value.title && handleChange(constant.TITLE, value.title.trim())}
            />
            {cms("label.description")}
            <Editor
              id="idTiny"
              textareaName={cms("label.description")}
              value={value.description}
              onEditorChange={(val) => handleChange(constant.DESCRIPTION, val)}
              init={{
                menubar: true,
                plugins: ["autolink link image lists print preview"],
                toolbar: "undo redo | bold italic | alignleft aligncenter alignright",
                entity_encoding: "raw",
              }}
            />
            {!(value && value.variants && value.variants.length) ? (
              <FormLayout>
                {basePricing && basePricing.currencyCode && currencyPrice ? (
                  <>
                    <br />
                    <Banner status={constant.INFO} isOpen>
                      {`${cms("label.rate")} ${value.moneyFormat}1 = ${currencySymbol}${currencyPrice}`}
                    </Banner>
                  </>
                ) : null}
                <FormLayout.Group condensed>
                  <TextField
                    id="price"
                    label={[
                      cms("label.price"),
                      <span className="currency-badge">
                        <Badge status="info">{cms("label.currency")}</Badge>
                      </span>,
                    ]}
                    min={0}
                    value={(value.price && value.price.toString()) || ""}
                    placeholder={constant.ZERO_PRICE}
                    prefix={value.moneyFormat}
                    disabled={currencyPrice}
                    onChange={(val) => {
                      handleChange(constant.PRICE, acceptOnlyValidInput(val, value.price));
                      setDisabledButton(false);
                    }}
                  />
                  <TextField
                    id="comparePrice"
                    label={cms("label.comparePrice")}
                    min={0}
                    placeholder={constant.ZERO_PRICE}
                    prefix={value.moneyFormat}
                    disabled={currencyPrice}
                    value={(value.comparePrice && value.comparePrice.toString()) || ""}
                    onChange={(val) => {
                      handleChange(constant.COMPARE_PRICE, acceptOnlyValidInput(val, value.comparePrice));
                      setDisabledButton(false);
                    }}
                  />
                </FormLayout.Group>
                {basePricing && basePricing.currencyCode && currencyPrice ? renderPricingComponent() : null}
                <FormLayout.Group>
                  <TextField
                    label={cms("label.discount")}
                    value={(discountPrice && discountPrice.toString()) || ""}
                    placeholder={constant.ZERO_PRICE}
                    max={maxDiscount}
                    prefix={vendorType === constant.FLAT && value.moneyFormat}
                    suffix={vendorType === constant.PERCENTAGE && constant.symbol.PERCENTAGE}
                    onChange={(val) => {
                      handleDiscount(constant.PRICE, acceptOnlyValidInput(val, vendorPrice, maxDiscount));
                      setDisabledButton(false);
                    }}
                    connectedRight={selectDiscount}
                  />
                </FormLayout.Group>
              </FormLayout>
            ) : null}
            <TextField label={`${cms("label.vendor")}*`} value={value.vendor || ""} disabled />
            <Select
              label={cms("label.type")}
              placeholder={cms("placeholder.selectProductType")}
              options={productTypeOptions}
              value={value.productType}
              onChange={(val) => {
                handleChange(constant.PRODUCT_TYPE, val);
                setDisabledButton(false);
              }}
            />
            <Tags productTag={availableProductTags} data={value} handleChange={handleChange} />
          </FormLayout>
          <br />
          <div className="fulfillment_button">
            <PageActions
              primaryAction={{
                content: cms("button.update"),
                onAction: () => onFormSubmit(),
                loading: editProductLoading,
                disabled: disabledButton,
              }}
            />
          </div>
        </Card.Section>
        <div className="toast">
          <Toast message={message} />
        </div>
      </Card>
      <Sheet
        // title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {/* {sheetContent} */}
      </Sheet>
    </>
  );
};
export default Information;
