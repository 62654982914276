import React, { useContext } from "react";
import { Card, Caption, Stack, TextStyle } from "@shopify/polaris";

import baseHelper from "lib/helpers/base";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";

import { fullfillmentStatusProp } from "app/orders/modules/operator/features/view/propsType";

import { TotalSection } from "app/orders/modules/operator/features/view/genericStyled";
import { StyledFulfillment } from "asset/styles/globalStyle";

const FulfillmentStatus = (props) => {
  const { cms, userData } = useContext(PrivateContext);
  const { data, lineData, fulfilledData, totalFulfilledAmount = 0, totalRejectAmount = 0, term } = props;

  const totalTax = lineData.reduce((prev, value) => {
    return prev + value.actualQuantity * value.totalTax;
  }, 0);

  const isRejected =
    lineData &&
    lineData.every(
      (item) =>
        item.status === constant.REJECTED &&
        (item.fulfillmentStatus === constant.UNFULFILLED || item.fulfillmentStatus === constant.REFUND)
    );
  const currentUserData = baseHelper.getResponseData(userData, constant.gql.GET_CURRENT_USER);
  const isSeller = baseHelper.isSeller(currentUserData);
  const isTaxIncluded = !!data.taxesIncluded;

  const rejectedData = {
    commissionSeller: 0,
    commissionVendor: 0,
    discount: 0,
    isUpdatedOrderLine: true,
    markUp: 0,
    markUpItems: 0,
    price: 0,
    sellerDiscount: 0,
    shippingAmount: 0,
    tax: 0,
    totalTaxRate: 0,
    totalPrice: 0,
  };

  const totals = isRejected
    ? rejectedData
    : baseHelper.getOrderPrice(lineData, data, isSeller, false, false, fulfilledData);
  const { isUpdatedOrderLine, price = 0 } = totals || {};
  const subTotal = parseFloat(price);
  const updatedLineSubTotal = parseFloat(data.totalLineItemsPrice);

  const { code: discountLineCode = "", amount } =
    (data.discountCodes && data.discountCodes.length && data.discountCodes[0]) || {};
  const lineDataItems = lineData.filter(
    (lineItem) =>
      !(
        lineItem.status === constant.REJECTED &&
        (lineItem.fulfillmentStatus === constant.UNFULFILLED || lineItem.fulfillmentStatus === constant.REFUND)
      )
  ).length;
  const items = `${lineDataItems > constant.value.ONE ? constant.items : `${cms("label.items")}:`} ${lineDataItems}`;
  const markUpItems = `${totals.markUpItems > constant.value.ONE ? constant.items : `${cms("label.items")}:`} ${
    totals.markUpItems
  }`;
  const moneyFormat = data.moneyFormat || constant.symbol.DOLLAR;

  const { title: shippingLineTitle = "" } =
    (data.shippingLines && data.shippingLines.length && data.shippingLines[0]) || {};

  const { price: shippingLinePrice = "" } =
    (data.shippingLines && data.shippingLines.length && data.shippingLines[0]) || {};

  const vendorsSum = [];
  lineData.filter(({ vendor }) => {
    vendorsSum.push(vendor);
    return vendorsSum;
  });
  const vendorSet = new Set(vendorsSum);
  const setSize = vendorSet.size;
  const addVendorTxt =
    setSize === constant.value.ONE ? `${setSize} ${cms("label.vendor")}` : `${setSize} ${cms("label.vendors")}`;

  return (
    <TotalSection className="total-Section">
      <Card>
        <Card.Section subdued>
          <Card.Subsection>
            <Card.Section>
              <Stack wrap={false}>
                <Stack.Item fill>{`${cms("label.subTotal")} (Exclude rejected order line item)`}</Stack.Item>
                <StyledFulfillment>
                  <Stack.Item>{items}</Stack.Item>
                  <Stack.Item>
                    {moneyFormat}
                    {isUpdatedOrderLine
                      ? Number(baseHelper.getPrice(subTotal)).toLocaleString()
                      : Number(updatedLineSubTotal.toFixed(2)).toLocaleString()}
                  </Stack.Item>
                </StyledFulfillment>
              </Stack>
            </Card.Section>
            {isUpdatedOrderLine && !!totals.markUp && (
              <Card.Section>
                <Stack wrap={false}>
                  <Stack.Item fill>{cms("label.markUpTotal")}</Stack.Item>
                  <StyledFulfillment>
                    <Stack.Item>{markUpItems}</Stack.Item>
                    <Stack.Item>
                      {`${moneyFormat}`}
                      {baseHelper.getPrice(totals.markUp)}
                    </Stack.Item>
                  </StyledFulfillment>
                </Stack>
              </Card.Section>
            )}

            <Card.Section>
              <Stack wrap={false}>
                <Stack.Item fill>
                  <div className="ellipsis">
                    {cms("label.shipping")}
                    {`${shippingLineTitle ? `(${shippingLineTitle})` : ""}`}
                  </div>
                </Stack.Item>
                <Stack.Item>
                  {moneyFormat}
                  {`${
                    term === "flat" || !totals.shippingAmount
                      ? baseHelper.getPrice(shippingLinePrice)
                      : baseHelper.getPrice(totals.shippingAmount)
                  }`}
                </Stack.Item>
              </Stack>
            </Card.Section>
            <Card.Section>
              <Stack wrap={false}>
                <Stack.Item fill>{cms("label.marketplaceDiscount")}</Stack.Item>
                <Stack.Item>
                  {`${moneyFormat}-`}
                  {Number(baseHelper.getPrice(totals.sellerDiscount))}
                </Stack.Item>
              </Stack>
            </Card.Section>
            {!!totals.marketplaceDiscount && (
              <Card.Section>
                <Stack wrap={false}>
                  <Stack.Item fill>
                    {cms("label.discount")}
                    {discountLineCode ? `(${discountLineCode})` : ""}
                  </Stack.Item>
                  <Stack.Item>
                    {`${moneyFormat}-`}
                    {baseHelper.getPrice(totals.marketplaceDiscount) || constant.value.FLOAT_ZERO}
                  </Stack.Item>
                </Stack>
              </Card.Section>
            )}
            <Card.Section>
              <Stack wrap={false}>
                <Stack.Item fill>
                  {`${cms("label.salesTax")} ${(isTaxIncluded && cms("label.included")) || ""}`}
                </Stack.Item>
                <Stack.Item>
                  {moneyFormat}
                  {isSeller && isUpdatedOrderLine && Number(totalTax).toLocaleString()}
                </Stack.Item>
              </Stack>
            </Card.Section>
          </Card.Subsection>
        </Card.Section>
        <Card.Section>
          <Stack vertical>
            <Stack wrap={false} alignment={constant.CENTER}>
              <Stack.Item fill>
                <b>{cms("label.total")}</b>
              </Stack.Item>
              <StyledFulfillment>
                <Stack.Item>
                  <Caption>
                    {" "}
                    (
                    {lineDataItems > 1
                      ? `${lineDataItems}
                ${cms("label.items")} from ${addVendorTxt}`
                      : `${lineDataItems}
                ${cms("label.item")} from ${addVendorTxt}`}
                    )
                  </Caption>
                </Stack.Item>
                <Stack.Item>
                  <TextStyle variation="strong">
                    {moneyFormat}
                    {term === "flat" || !isUpdatedOrderLine
                      ? Number(baseHelper.getPrice(data.totalPrice)).toLocaleString()
                      : Number(totals.totalPrice).toLocaleString()}
                  </TextStyle>
                </Stack.Item>
              </StyledFulfillment>
            </Stack>
          </Stack>
        </Card.Section>
      </Card>
    </TotalSection>
  );
};

FulfillmentStatus.propTypes = fullfillmentStatusProp.type;
export default FulfillmentStatus;
