import React, { useContext } from "react";
import { Stack, Card, TextStyle, Badge, Link, Thumbnail, Caption } from "@shopify/polaris";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { statusHelper, baseHelper, imageHelper } from "lib/helpers";

const FulfilledLineItems = (props) => {
  const { lineData, data } = props;
  const { userData, history, cms } = useContext(PrivateContext);
  const currentUserData = baseHelper.getResponseData(userData, constant.gql.GET_CURRENT_USER);

  const amount = [];
  const vendorsSum = [];
  let countItems = 0;

  lineData.forEach(({ vendor, price, fulfillmentStatus, quantity = 1 }) => {
    if (fulfillmentStatus === constant.FULFILLED) {
      vendorsSum.push(vendor);
      amount.push(parseFloat(price) * parseInt(quantity, 10));
      countItems += 1;
    }
    return [vendorsSum, amount];
  });
  const vendorSet = new Set(vendorsSum);
  const setSize = vendorSet.size;
  const addVendorTxt =
    setSize === constant.value.ONE ? `${setSize} ${cms("label.vendor")}` : `${setSize} ${cms("label.vendors")}`;
  // const lineDataItems = lineData.length;

  const stringToNumber = amount.map((item) => parseFloat(item));
  const totalAmount = stringToNumber.reduce((cur, acc) => cur + acc, constant.value.ZERO);

  const isVendor = baseHelper.isVendor(currentUserData);
  const lineItems = lineData.map((lineItem, idx) => {
    if (!lineItem || lineItem.fulfillmentStatus === constant.UNFULFILLED) {
      return null;
    }
    const lineImage =
      imageHelper.resize({ url: lineItem.image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;
    const lineItemKey = `lineItem${idx}`;
    const { isProductDeleted, isVendorDeleted } = lineItem;

    const moneyFormat = lineItem.moneyFormat || constant.symbol.DOLLAR;
    const lineTotalPrice = lineItem.price * lineItem.quantity;
    const status = statusHelper.getBadgeStatus(lineItem.status);
    const badgeType = baseHelper.getBadgeType(lineItem.status);
    const lineItemName = <div className="productName">{lineItem.name}</div>;
    return (
      <div key={lineItemKey} className="fulfilled-Secn">
        <Card>
          <Card.Section subdued>
            <Stack wrap={false}>
              <Stack.Item>
                <Thumbnail size="large" source={lineImage} alt={lineItem.name} />
              </Stack.Item>
              <Stack.Item fill>
                <div className="ellipsis">
                  {(isProductDeleted && (lineItemName || "")) || (
                    <Link onClick={() => history.push(`/products/edit/${lineItem.productId}`)}>
                      {lineItem.name || ""}
                    </Link>
                  )}

                  <Caption>
                    <TextStyle variation={constant.SUBDUED}>
                      {!(isVendor || isVendorDeleted) && [
                        `${cms("label.vendor")}: `,
                        <Link onClick={() => history.push(constant.PROVIDER_VIEW + lineItem.vendorId)}>
                          {lineItem.vendor}
                        </Link>,
                      ]}
                    </TextStyle>
                    <br />
                    <TextStyle variation={constant.SUBDUED}>
                      {cms("label.skuList")}
                      <div className="wrap_sku">{lineItem.sku}</div>
                    </TextStyle>
                  </Caption>

                  <Stack>
                    <Stack.Item>
                      <Badge status={badgeType} className="text-capitalize">
                        {status}
                      </Badge>
                    </Stack.Item>
                  </Stack>
                </div>
              </Stack.Item>
              <Stack.Item>
                {`${cms("label.qty")}:`}
                {lineItem.quantity}
              </Stack.Item>
              <Stack.Item>
                {moneyFormat}
                {(lineTotalPrice && lineTotalPrice.toFixed(2)) || constant.value.ZERO}
              </Stack.Item>
            </Stack>
          </Card.Section>
        </Card>
      </div>
    );
  });
  const moneyFormat = data && data.moneyFormat;
  const totalFulfill = (
    <Card.Section>
      <Stack>
        <Stack.Item fill>
          <TextStyle variation="strong">{cms("label.total")}</TextStyle>
        </Stack.Item>
        <Stack.Item>
          <Caption>
            (
            {countItems > 1
              ? `${countItems}
                ${cms("label.items")} from ${addVendorTxt}`
              : `${countItems}
                ${cms("label.item")} from ${addVendorTxt}`}
            )
          </Caption>
        </Stack.Item>
        <Stack.Item>
          <TextStyle variation="strong">
            {`${moneyFormat || constant.symbol.DOLLAR} ${totalAmount.toFixed(2)}`}
          </TextStyle>
        </Stack.Item>
      </Stack>
    </Card.Section>
  );
  return [lineItems, totalFulfill];
};

export default FulfilledLineItems;
