import { gql } from "apollo-boost";

const GET_ORDER_LINES = gql`
  query getOrderLines($input: GetOrderLines) {
    getOrderLines(input: $input) {
      data {
        orderLines {
          _id
          actualPayoutDate
          accountingStatus
          actualQuantity
          commissionSeller
          commissionTax
          commissionVendor
          createdAt
          fulfillmentStatus
          isOrderBasedCommission
          markUpPrice
          moneyFormat
          name
          orderId
          orderNumber
          price
          priceTaxRate
          quantity
          shippingAmount
          shippingTax
          shopifyLineItemId
          shopifyOrderId
          sku
          status
          title
          totalTax
          vendor
          vendorDiscount
          vendorId
          vendorWholesalePrice
          wholesalePrice
        }
        hasMore
      }
      error
      status
    }
  }
`;

const CHECK_ORDER_LINES = gql`
  query checkOrderLines($input: CheckOrderLines) {
    checkOrderLines(input: $input) {
      data {
        accountingStatus
        vendorId
        vendor
      }
      status
      error
    }
  }
`;

export { GET_ORDER_LINES, CHECK_ORDER_LINES };
