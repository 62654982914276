import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  Badge,
  Banner,
  Button,
  ButtonGroup,
  Caption,
  Card,
  Checkbox,
  DisplayText,
  FormLayout,
  Icon,
  Layout,
  Link,
  Modal,
  Select,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";

import {
  updateOrderLineTracking,
  updateOrderLineStatus,
  updateVendorTracking,
} from "app/orders/apollo/updateOrderNote";
import {
  CircleCancelMinor,
  CircleTickOutlineMinor,
  EditMinor,
  NoteMajor,
  QuickSaleMajor,
} from "@shopify/polaris-icons";

import { GET_SHIPPING_LABEL_STATUS } from "app/orders/apollo/queries";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { statusHelper, baseHelper, imageHelper, errorHelper } from "lib/helpers";
import CollectOrder from "app/orders/modules/provider/features/view/features/collectOrder";
import { StyledCard } from "app/orders/modules/operator/features/view/genericStyled";
import { OrderContext } from "app/orders/modules/provider/features/view/orderContext";
import { FieldSetQty } from "app/orders/modules/provider/features/view/modal/modalStyle";

// import components
import LineItemProperties from "app/orders/modules/generic/service/lineItemProperties";
import Popover from "./popover";

const {
  gql,
  value: { ZERO },
  WARNING,
} = constant;

const UnfulfilledLineItems = (props) => {
  const { currentUser, cms, match } = useContext(PrivateContext);
  const {
    setAddressId,
    fulfillmentType: orderFulfillmentType,
    setFulfillmentType: setOrderFulfillmentType,
  } = useContext(OrderContext);
  const isSeller = baseHelper.isSeller(currentUser);
  const isVendor = baseHelper.isVendor(currentUser);
  const {
    // actionData,
    addressList: addressListData,
    bulkFulfillData,
    bulkFulfillItem,
    data,
    fulfillment = {},
    getSlipText,
    // fulfillmentType: orderFulfillmentType,
    itemData = [],
    isBulkChecked,
    isBulkCheckedFulfill,
    isEnabledData,
    lineLoading,
    linkData = [],
    loading,
    onRefetchOrderLineView,
    onRefetchOrderView,
    refetchOrderFulfillment,
    selectedItems,
    setLineLoading,
    setSelectedItems,
    handleBulkAccept,
    openModel,
    setActionData,
    setActive,
    setBanner,
    setMessage,
    setConsignmentValue,
    // setFulfillmentType: setOrderFulfillmentType,
    setRejectModal,
    setIsBulkChecked,
    setIsBulkCheckedFulfilled,
    packingSlipAccessData,
    setIsCapmaignOrder,
  } = props;
  const [activePopover, setActivePopover] = useState({});
  const [fulfillPopover, setFulfillPopover] = useState({});
  const [bulkQuantity, setBulkQuantity] = useState([{ id: "", quantity: "", totalQuantity: "" }]);
  const [trackingCompany] = useState("");
  const [trackingNumber] = useState("");
  const [initialTrackingCompany, setInitialTrackingCompany] = useState("");
  const [initialTrackingNumber, setInitialTrackingNumber] = useState("");

  // const { type = "", isThirdPartyShipping = false } = fulfillment || {};
  const [fulfillmentType, setFulfillmentType] = useState(constant.DROPSHIP);
  const { dropship = {} } = fulfillment || {};
  const { isThirdPartyShipping = false, nzPost = {}, shippingOption = "" } = dropship || {};
  const isConsignment = ["consignment", "pickup"].includes(fulfillmentType) || false;

  const [trackingModal, setTrackingModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const [selectedItemData, setSelectedItemData] = useState({});
  const [isAcceptOrder, setIsAcceptedOrder] = useState("");
  const [addressLabel, setAddressLabel] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState({
    address: currentUser.address,
    city: currentUser.city,
    country: currentUser.country,
    postalCode: currentUser.pinCode,
  });
  const [selectedAddressLabel, setSelectedAddressLabel] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [updateOrderLine, { data: dataUpdateOrderLineTracking, loading: loadingOrderLineTracking }] = useMutation(
    updateOrderLineTracking
  );

  const [updateVendorOrder, { data: dataVendorTracking }] = useMutation(updateVendorTracking);
  // eslint-disable-next-line no-unused-vars
  const [updateOrderStatus, { data: updateOrderLineData, loading: orderLineLoading }] = useMutation(
    updateOrderLineStatus
  );
  const { setPageData, pageData, history } = useContext(PrivateContext);
  const { title, breadcrumbs, subtitle } = pageData;

  const [getShippingLabelStatus, { loading: checkShippingLabelLoading, data: checkShippingLabelData }] = useLazyQuery(
    GET_SHIPPING_LABEL_STATUS
  );

  const [isfulfillLineItemLoading, setFulFillLineItemLoading] = useState({});

  const [sellerAddresId, setSellerAddresId] = useState("");
  const [addressModal, setAddressModal] = useState(false);
  const [isActionData, setIsActionData] = useState(false);
  const [isTrackingCompnay, setIsTrackingCompany] = useState(false);
  const [isTrackingNumber, setIsTrackingNumber] = useState(false);

  useEffect(() => {
    if (addressListData && addressListData.length) {
      const formattedAddress = addressListData
        .filter((item) => item.label && item)
        .map(({ _id: addressId, label, isDefault = false }) => ({
          label: `${label} ${isDefault ? "(default address)" : ""}`,
          value: addressId,
          isDefault: !!isDefault,
        }));
      setAddressLabel(formattedAddress);
      const defaultAddress = addressListData.filter((item) => item.isDefault);
      setSelectedAddress(defaultAddress[0]);
      // eslint-disable-next-line no-underscore-dangle
      setSelectedAddressLabel(defaultAddress[0]._id);
    }
  }, [addressListData]);

  useEffect(() => {
    if (updateOrderLineData) {
      const updateOrderStatusData = baseHelper.getResponseData(
        updateOrderLineData,
        constant.gql.UPDATE_ORDER_LINE_STATUS
      );
      const updateOrderStatusError = baseHelper.getResponseError(
        updateOrderLineData,
        constant.gql.UPDATE_ORDER_LINE_STATUS
      );
      if (updateOrderStatusData && isAcceptOrder) {
        // setPageData({ title, breadcrumbs, subtitle });
        setMessage(cms("message.success.orderLineApproved"));
        onRefetchOrderView();
        onRefetchOrderLineView();
        refetchOrderFulfillment();
      } else if (updateOrderStatusData && !isAcceptOrder) {
        setMessage(cms("message.error.orderLineRejected"));
        onRefetchOrderView();
        onRefetchOrderLineView();
        refetchOrderFulfillment();
      }
      if (updateOrderStatusError) {
        setBanner({
          status: constant.CRITICAL,
          title: updateOrderStatusError,
          isOpen: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOrderLineData]);

  useEffect(() => {
    let bannerData = false;
    if (!checkShippingLabelLoading && checkShippingLabelData) {
      const { _id: orderLineItemId = "" } = selectedItemData || {};
      setFulFillLineItemLoading({
        [orderLineItemId]: checkShippingLabelLoading,
      });
      const responseData = baseHelper.getResponseData(checkShippingLabelData, gql.GET_SHIPPING_LABEL_STATUS);
      if (!responseData) {
        bannerData = {
          title:
            baseHelper.getResponseError(checkShippingLabelData, gql.GET_SHIPPING_LABEL_STATUS) || errorHelper.parse(),
          status: constant.CRITICAL,
        };
      }

      if (responseData && !responseData.consignmentStatus) {
        bannerData = {
          title: cms("message.error.labelNotGenerated"),
          status: constant.WARNING,
        };
      }

      if (responseData && responseData.consignmentStatus === constant.PROCESSING) {
        bannerData = {
          title: cms("message.success.labelUnderProcess"),
          status: constant.WARNING,
        };
      }

      if (bannerData) {
        setBanner({ ...bannerData, isOpen: true });
        setTrackingModal(false);
        return;
      }
      setConsignmentValue(true);
      setTrackingModal(true);
      const { consignmentId = "", labels = [] } = responseData;
      const trackingReference = labels && labels.length && labels[0].tracking_reference;
      setInitialTrackingCompany(consignmentId);
      setInitialTrackingNumber(trackingReference);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkShippingLabelLoading, checkShippingLabelData, cms]);

  const validInput = (value, preValue) => {
    return (baseHelper.validateWholeNumber(value) && value) || (value !== "" && preValue) || "";
  };

  const handleQuantityChange = (id, value) => {
    setBulkQuantity([{ id, quantity: parseInt(value, 10), totalQuantity: bulkQuantity[0].totalQuantity.toString() }]);
  };

  const partial = bulkQuantity[0].totalQuantity - bulkQuantity[0].quantity;

  const getBadgeText = (orderLineFulfillmentType) => {
    if ([constant.RESERVE, constant.COLLECT].includes(orderLineFulfillmentType)) {
      return cms("label.partialReady");
    }
    if (orderLineFulfillmentType === constant.SERVICE) {
      return cms("label.partialService");
    }
    return cms("label.partial");
  };
  const badgeStatus =
    baseHelper.getBadgeStatus(fulfillmentType) === constant.PICKUP
      ? constant.CONSIGNMENT_PICKUP_CAPITAL
      : baseHelper.getBadgeStatus(fulfillmentType);
  const fulfillModal = () => {
    const isClickAndReserve = fulfillmentType === constant.RESERVE;

    const lineImage =
      imageHelper.resize({ url: selectedItemData.image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;

    return (
      <>
        <StyledCard>
          <Stack wrap={false} id={selectedItemData._id}>
            <Stack.Item>
              <Thumbnail size="large" source={lineImage} alt={selectedItemData.name} />
            </Stack.Item>
            <Stack.Item fill>
              <div className="ellipsis">
                <Stack.Item>
                  {(selectedItemData.isProductDeleted && (selectedItemData.name || "")) || (
                    <Link onClick={() => history.push(`/products/edit/${selectedItemData.productId}`)}>
                      {selectedItemData.name || ""}
                    </Link>
                  )}
                  {selectedItemData.variantTitle && (
                    <Caption>
                      <TextStyle variation={constant.SUBDUED}>{selectedItemData.variantTitle.toUpperCase()}</TextStyle>
                    </Caption>
                  )}
                </Stack.Item>
                <Stack.Item>
                  {fulfillmentType && (
                    <Badge className="text-capitalize">
                      {badgeStatus === constant.CONSIGNMENT ? constant.CONSIGNMENT_SHIP_CAPITAL : badgeStatus}
                    </Badge>
                  )}
                  {partial > 0 && <Badge status={constant.SUCCESS}>{getBadgeText(fulfillmentType)}</Badge>}
                </Stack.Item>
              </div>
            </Stack.Item>
            <FieldSetQty>
              <Stack.Item>
                <TextField
                  value={(bulkQuantity[0].quantity && bulkQuantity[0].quantity.toString()) || ""}
                  min={0}
                  max={(bulkQuantity[0].totalQuantity && bulkQuantity[0].totalQuantity.toString()) || ""}
                  onChange={(value) => [
                    value <= parseInt(bulkQuantity[0].totalQuantity, 10) &&
                      handleQuantityChange(bulkQuantity[0].id, validInput(value, bulkQuantity[0].quantity)),
                  ]}
                  disabled={fulfillmentType === constant.CONSIGNMENT}
                  suffix={`${cms("label.of")} ${bulkQuantity[0].totalQuantity}`}
                />
              </Stack.Item>
            </FieldSetQty>
          </Stack>
        </StyledCard>
        <br />
        {partial > 0 && <Banner status={WARNING} title={`${cms("modal.banner.partial")}.`} />}
      </>
    );
  };

  const handleChange = (value) => {
    const { fulfillmentStatus, _id: id } = value;
    if (fulfillmentStatus === constant.UNFULFILLED || fulfillmentStatus === constant.PARTIAL) {
      const found = selectedItems.find((element) => element === id);
      if (found) {
        setSelectedItems(
          selectedItems.filter((item) => {
            return item !== id;
          })
        );
      } else {
        setSelectedItems([...selectedItems, id]);
      }
    }
    setActive(false);
  };

  const checkShippingLabelStatus = (value) => {
    const { orderId = "", _id: orderLineItemId, consignmentId = "" } = value;
    setFulFillLineItemLoading({
      [orderLineItemId]: true,
    });
    getShippingLabelStatus({ variables: { input: { orderId, orderLineItemId, consignmentId } } });
  };

  const openTrackingModel = (value) => {
    const { fulfillmentType: fulfillType } = value;
    setFulfillmentType(fulfillType || constant.DROPSHIP);
    setMessage("");

    if (value.fulfillmentType === constant.PICKUP && !(addressListData && addressListData.length)) {
      setBanner({
        status: constant.CRITICAL,
        title: cms("message.error.address"),
        isOpen: true,
      });
      return;
    }

    setBulkQuantity([
      {
        id: value._id,
        quantity: value.fulfillableQuantity,
        totalQuantity: value.fulfillableQuantity.toString(),
      },
    ]);

    setSelectedItemData(value);
    if (fulfillmentType === constant.DROPSHIP && isThirdPartyShipping && nzPost && nzPost.clientId) {
      checkShippingLabelStatus(value);
      return;
    }

    if (value && value.trackingCompany) {
      setInitialTrackingCompany(value.trackingCompany);
      setIsTrackingCompany(true);
    }

    if (value && value.trackingNumber) {
      setInitialTrackingNumber(value.trackingNumber);
      setIsTrackingNumber(true);
    }
    if (fulfillType === constant.CONSIGNMENT) {
      setInitialTrackingCompany("");
      setInitialTrackingNumber("");
    }
    setTrackingModal(true);
  };
  const addTrackingDetails = async () => {
    setMessage("");
    const { fulfillmentType = "" } = selectedItemData || {};
    const isClickAndReserve = fulfillmentType === constant.RESERVE;
    if (!(isClickAndReserve || isEnabledData || initialTrackingCompany)) {
      setTrackingModal(false);
      setBanner({ isOpen: true, title: cms("message.error.trackingCompany"), status: constant.CRITICAL });
      return false;
    }

    if (!(isClickAndReserve || isEnabledData || initialTrackingNumber)) {
      setTrackingModal(false);
      setBanner({ isOpen: true, title: cms("message.error.trackingNumber"), status: constant.CRITICAL });
      return false;
    }
    // eslint-disable-next-line no-shadow
    const data = {
      trackingNumber: initialTrackingNumber ? initialTrackingNumber.trim() : "",
      trackingCompany: initialTrackingCompany ? initialTrackingCompany.trim() : "",
    };

    data.fulfillmentType = fulfillmentType || constant.DROPSHIP;
    data.bulkQuantity = bulkQuantity.map((bulkQty) => ({
      id: bulkQty.id,
      quantity: bulkQty.quantity,
    }));
    bulkFulfillItem({
      variables: {
        input: { ...data, isSingle: true },
      },
    });
    return null;
  };

  // function to fetch addresses list for corresponding vendor
  const openClickReserveModal = async (value) => {
    const { fulfillmentType: type = "", sellerId = "" } = value;

    if (value.fulfillmentType === constant.RESERVE && !(addressListData && addressListData.length)) {
      setBanner({
        status: constant.CRITICAL,
        title: cms("message.error.address"),
        isOpen: true,
      });
      return;
    }

    setBulkQuantity([
      {
        id: value._id,
        quantity: value.fulfillableQuantity.toString(),
        totalQuantity: value.fulfillableQuantity.toString(),
      },
    ]);
    setFulfillmentType(type);
    setSellerAddresId(sellerId);
    setSelectedItemData(value);
    setAddressModal(true);
  };

  const openClickCollectModal = async (value) => {
    const { fulfillmentType: type = "", properties = [], sellerId = "" } = value;

    if (value.fulfillmentType === constant.COLLECT && !(addressListData && addressListData.length)) {
      setBanner({
        status: constant.CRITICAL,
        title: cms("message.error.address"),
        isOpen: true,
      });
      return;
    }

    setBulkQuantity([
      {
        id: value._id,
        quantity: value.fulfillableQuantity.toString(),
        totalQuantity: value.fulfillableQuantity.toString(),
      },
    ]);
    setFulfillmentType(type);
    setSellerAddresId(sellerId);
    setSelectedItemData(value);
    setAddressModal(true);
  };

  const openTimeBasedModal = async (value) => {
    const { fulfillmentType: type = "", sellerId = "" } = value;

    if (value.fulfillmentType === constant.SERVICE && !(addressListData && addressListData.length)) {
      setBanner({
        status: constant.CRITICAL,
        title: cms("message.error.address"),
        isOpen: true,
      });
      return;
    }

    setBulkQuantity([
      {
        id: value._id,
        quantity: value.fulfillableQuantity.toString(),
        totalQuantity: value.fulfillableQuantity.toString(),
      },
    ]);
    setFulfillmentType(type);
    setSellerAddresId(sellerId);
    setSelectedItemData(value);
    setAddressModal(true);
  };

  const renderCollection = () => {
    return (
      addressModal && (
        <>
          <CollectOrder
            addressModal={addressModal}
            sellerAddresId={sellerAddresId}
            selectedItemData={selectedItemData}
            addressList={addressLabel}
            setAddressModal={setAddressModal}
            onRefetchOrderLineView={onRefetchOrderLineView}
            setBanner={setBanner}
            setMessage={setMessage}
            fulfillModal={fulfillModal}
            bulkQuantity={bulkQuantity}
            fulfillmentType={fulfillmentType}
          />
        </>
      )
    );
  };

  const addShipmentDetails = (isPickup = false) => {
    setModalLoading(true);
    // eslint-disable-next-line no-shadow
    let data = {
      trackingNumber: initialTrackingNumber.trim(),
      trackingCompany: initialTrackingCompany.trim(),
    };

    const { _id: id, orderId, vendorId } = selectedItemData;

    if (isPickup) {
      data = {
        consignmentPickAddress: {
          address: selectedAddress.address,
          city: selectedAddress.city,
          country: selectedAddress.country,
          pinCode: selectedAddress.postalCode,
        },
        isConsignmentPick: true,
      };
      data.id = orderId;
      data.lineItemId = id;
      updateVendorOrder({ variables: { input: data } });
      return null;
    }

    data.fulfillmentType = fulfillmentType || constant.DROPSHIP;
    data.bulkQuantity = bulkQuantity.map((bulkQty) => ({ id: bulkQty.id, quantity: bulkQty.quantity }));

    if (isVendor && !isPickup) {
      if (!isEnabledData && (!initialTrackingCompany || !initialTrackingNumber)) {
        setTrackingModal(false);
        setModalLoading(false);
        let errorMessage = cms("message.error.trackingCompany");
        if (!initialTrackingNumber) {
          errorMessage = cms("message.error.trackingNumber");
        }
        setBanner({ isOpen: true, title: errorMessage, status: constant.CRITICAL });
        return false;
      }
      const fulfillmentAddress = baseHelper.fulfillmentDetails(fulfillment, currentUser);
      data = { ...data, ...(fulfillmentAddress || {}) };
    }
    if (!isBulkCheckedFulfill) {
      data.isSingle = true;
    }
    bulkFulfillItem({ variables: { input: { ...data } } });
    return null;
  };

  useEffect(() => {
    if (bulkFulfillData) {
      const updateOrderTracking = baseHelper.getResponseData(bulkFulfillData, gql.BULK_ORDER_FULFILL);
      const updateOrderTrackingError = baseHelper.getResponseError(bulkFulfillData, gql.BULK_ORDER_FULFILL);

      if (updateOrderTracking) {
        // setPageData({ title, breadcrumbs, subtitle });
        setTrackingModal(false);
        setInitialTrackingCompany("");
        setInitialTrackingNumber("");
        if (partial > ZERO) {
          setMessage(cms("message.success.partialFulfill"));
        }
        if (partial === ZERO) {
          setMessage(cms("message.success.fulfill"));
        }
        setTimeout(() => {
          onRefetchOrderView();
          onRefetchOrderLineView();
          refetchOrderFulfillment();
        }, 2000);
      }
      if (updateOrderTrackingError) {
        setTrackingModal(false);
        setBanner({
          status: constant.CRITICAL,
          title: updateOrderTrackingError,
          isOpen: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkFulfillData]);

  // handling vendor tracking data
  useEffect(() => {
    if (dataVendorTracking) {
      const updateVendorOrderTracking = baseHelper.getResponseData(
        dataVendorTracking,
        constant.gql.UPDATE_VENDOR_TRACKING
      );
      const updateVendorTrackingError = baseHelper.getResponseError(
        dataVendorTracking,
        constant.gql.UPDATE_VENDOR_TRACKING
      );

      if (updateVendorOrderTracking) {
        // setPageData({ title, breadcrumbs, subtitle });
        setModalLoading(false);
        setTrackingModal(false);
        setSelectedItemData(false);
        setInitialTrackingNumber("");
        setInitialTrackingCompany("");
        setMessage(cms("message.success.orderFulfilled"));
        onRefetchOrderView();
        onRefetchOrderLineView();
        refetchOrderFulfillment();
      }
      if (updateVendorTrackingError) {
        setTrackingModal(false);
        setModalLoading(false);
        setBanner({
          status: constant.CRITICAL,
          title: updateVendorTrackingError,
          isOpen: true,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataVendorTracking]);

  const acceptOnlyValidInput = (value) => {
    return baseHelper.stringNotAcceptSpaceAtStart(value) && value;
  };

  const renderTracking = () => {
    const { fulfillmentType = "", collectDetail = {} } = selectedItemData || {};
    const isClickAndReserve = fulfillmentType === constant.RESERVE;
    return (
      <>
        <Modal
          open={trackingModal}
          onClose={() => {
            setTrackingModal(false);
            setInitialTrackingNumber(trackingNumber);
            setInitialTrackingCompany(trackingCompany);
          }}
          title={isClickAndReserve ? cms("label.confirmCollection") : cms("label.addTracking")}
          primaryAction={{
            content: isClickAndReserve ? cms("label.paidAndCollected") : cms("label.saveDetails"),
            onAction: () => {
              addTrackingDetails();
            },
            loading,
            disabled:
              parseInt(bulkQuantity[0].quantity, 10) === constant.value.ZERO ||
              bulkQuantity[0].quantity === "" ||
              loading,
          }}
          sectioned
        >
          <Modal.Section>
            {!isClickAndReserve && (
              <FormLayout>
                {fulfillModal()}
                <FormLayout>
                  <TextField
                    label={cms("label.company")}
                    value={initialTrackingCompany}
                    disabled={isTrackingCompnay}
                    onChange={(val) => {
                      setInitialTrackingCompany(acceptOnlyValidInput(val));
                    }}
                  />
                  <TextField
                    label={cms("label.trackingNumber")}
                    value={initialTrackingNumber}
                    disabled={isTrackingNumber}
                    onChange={(val) => {
                      setInitialTrackingNumber(acceptOnlyValidInput(val));
                    }}
                  />
                </FormLayout>
              </FormLayout>
            )}
            {isClickAndReserve && collectDetail && (
              <>
                <TextContainer>{cms("label.validateSecurityCode")}</TextContainer>
                <br />
                <DisplayText size="large">{collectDetail.code}</DisplayText>
              </>
            )}
          </Modal.Section>
        </Modal>
      </>
    );
  };

  const handleAddressChange = (value) => {
    setSelectedAddressLabel(value);
    // eslint-disable-next-line no-underscore-dangle
    const newAddress = addressListData && addressListData.filter((address) => address._id === value);
    setSelectedAddress(newAddress[0]);
  };

  const renderConsignmentTracking = () => {
    const isConsignmentShip = fulfillmentType === constant.CONSIGNMENT;
    const isPickup = fulfillmentType === constant.PICKUP;
    let primaryAction = {
      content: cms("label.saveDetails"),
      onAction: () => addShipmentDetails(),
      loading: modalLoading,
      disabled: parseInt(bulkQuantity[0].quantity, 10) === constant.value.ZERO || bulkQuantity[0].quantity === "",
    };
    if (isPickup) {
      primaryAction = {
        content: cms("label.readyForCollection"),
        onAction: () => addShipmentDetails(true),
        loading: modalLoading,
      };
    }
    return (
      <Modal
        open={trackingModal}
        onClose={() => {
          setTrackingModal(false);
          setSelectedItemData(false);
          setInitialTrackingNumber(trackingNumber);
          setInitialTrackingCompany(trackingCompany);
        }}
        title={cms("label.fulfillmentStatus")}
        primaryAction={primaryAction}
        sectioned
      >
        <Modal.Section>
          {isConsignmentShip && (
            <Layout>
              <Layout.Section>
                <Stack>
                  <Stack.Item>
                    <TextContainer>
                      {cms("label.consignmentShip")}
                      <br />
                      <TextStyle variation="subdued">{cms("helpText.consignmentShip")}</TextStyle>
                    </TextContainer>
                  </Stack.Item>
                </Stack>
              </Layout.Section>
              <Layout.Section secondary>
                <Card sectioned>
                  <FormLayout>
                    {fulfillModal()}
                    <TextField
                      label={cms("label.company")}
                      value={initialTrackingCompany}
                      onChange={(val) => setInitialTrackingCompany(acceptOnlyValidInput(val))}
                    />
                    <TextField
                      label={cms("label.trackingNumber")}
                      value={initialTrackingNumber}
                      onChange={(val) => setInitialTrackingNumber(acceptOnlyValidInput(val))}
                    />
                  </FormLayout>
                </Card>
              </Layout.Section>
            </Layout>
          )}
          {isPickup && (
            <Layout>
              <Layout.Section>
                <Stack>
                  <Stack.Item>
                    {/* {fulfillModal()} */}
                    <TextContainer>
                      {cms("label.consignmentPickup")}
                      <br />
                      <TextStyle variation="subdued">{cms("helpText.consignmentPickup")}</TextStyle>
                    </TextContainer>
                  </Stack.Item>
                </Stack>
              </Layout.Section>
              <Layout.Section secondary>
                <Card title={cms("label.pickupLocation")} sectioned>
                  <FormLayout>
                    <Select
                      options={addressLabel}
                      label="Label"
                      value={selectedAddressLabel}
                      onChange={(value) => handleAddressChange(value)}
                      placeholder="Select address label"
                    />
                    <TextField label={cms("label.address")} value={selectedAddress.address} disabled />
                    <TextField label={cms("label.city")} value={selectedAddress.city} disabled />
                    <TextField label={cms("label.country")} value={selectedAddress.country} disabled />
                    <TextField label={cms("label.pincode")} value={selectedAddress.postalCode} disabled />
                  </FormLayout>
                </Card>
              </Layout.Section>
            </Layout>
          )}
        </Modal.Section>
      </Modal>
    );
  };

  const checkDisable = () => {
    let isDisable = [];
    const { isEnabled, isAllVendor, selectedVendor } = packingSlipAccessData;
    if (selectedVendor && selectedVendor.length) {
      isDisable = selectedVendor.filter((item) => item === currentUser._id);
      return !isDisable.length;
    }
    if (isAllVendor && !isEnabled && !selectedVendor.length) {
      return false;
    }
    return true;
  };

  const renderTotalAmount = () => {
    const amount = [];
    const vendorsSum = [];
    let countItems = 0;
    const moneyFormat = data.moneyFormat || constant.symbol.DOLLAR;

    itemData.forEach(
      ({ collectDetail = {}, fulfillmentStatus, fulfillableQuantity = 1, price, quantity = 1, status, vendor, rejectedByVendor = false }) => {
        if (
          (fulfillmentStatus === constant.UNFULFILLED || fulfillmentStatus === constant.PARTIAL) &&
          !(status === constant.REJECTED) && // || status === constant.CLOSED
          !collectDetail &&
          !rejectedByVendor
        ) {
          vendorsSum.push(vendor);
          amount.push(parseFloat(price) * parseInt(fulfillableQuantity));
          countItems += 1;
        }
        return [vendorsSum, amount];
      }
    );
    const vendorSet = new Set(vendorsSum);
    const setSize = vendorSet.size;
    const addVendorTxt =
      setSize === constant.value.ONE ? `${setSize} ${cms("label.vendor")}` : `${setSize} ${cms("label.vendors")}`;
    const stringtoNum = amount.map((item) => parseFloat(item));
    const totalAmount = stringtoNum.reduce((cur, acc) => cur + acc, constant.value.ZERO);
    return (
      <>
        <Card.Section>
          <Stack alignment="center">
            <Stack.Item fill>
              <TextStyle variation="strong">{cms("label.total")}</TextStyle>
            </Stack.Item>
            <Stack.Item>
              <Caption>
                (
                {countItems > 1
                  ? `${countItems}
                ${cms("label.items")} from ${addVendorTxt}`
                  : `${countItems}
                ${cms("label.item")} from ${addVendorTxt}`}
                )
              </Caption>
            </Stack.Item>
            <Stack.Item>
              <TextStyle variation="strong">
                {`${moneyFormat || constant.symbol.DOLLAR}${totalAmount.toFixed(2)}`}
              </TextStyle>
            </Stack.Item>
          </Stack>
        </Card.Section>
      </>
    );
  };
  const renderUnfullfilled = () => {
    if (linkData && linkData.length && !isActionData) {
      setActionData(linkData);
      setIsActionData(true);
    }
    const UnfulFilled = itemData.map((value, key) => {
      if (
        !value ||
        value.fulfillmentStatus === constant.FULFILLED ||
        value.fulfillmentStatus === constant.REFUND ||
        value.status === constant.REJECTED ||
        (value.rejectedByVendor && value.status === constant.NEW) ||
        // value.status === constant.CLOSED ||
        value.collectDetail ||
        !value.fulfillableQuantity
      ) {
        return null;
      }
      const typeValue = value && value.fulfillmentType;
      const typeData = orderFulfillmentType;
      if (typeData && _.isArray(typeData) && typeValue && !typeData.includes(typeValue)) {
        typeData.push(typeValue);
      }
      setOrderFulfillmentType(typeData);
      if (typeValue === constant.CONSIGNMENT) {
        setAddressId((value && value.addressId) || "");
      }

      const lineImage =
        imageHelper.resize({ url: value.image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;

      const lineItemKey = `lineItem${key}`;
      const { isProductDeleted } = value;

      let isAccepted = false;
      let isFulfilled = false;
      switch (value.status) {
        case constant.ACCEPTED:
          isAccepted = true;
          break;
        case constant.REJECTED:
          isAccepted = false;
          break;
        case constant.STATUS_NEW:
          isAccepted = true;
          break;
        case constant.CLOSED:
          isAccepted = null;
          break;
        default:
          break;
      }

      if (value.fulfillmentStatus === constant.UNFULFILLED) {
        switch (value.status) {
          case constant.ACCEPTED:
            isFulfilled = false;
            break;
          case constant.REJECTED:
            isFulfilled = false;
            break;
          case constant.STATUS_NEW:
            isFulfilled = true;
            break;
          case constant.CLOSED:
            isFulfilled = null;
            break;
          default:
            break;
        }
      }

      if (value.isCampaign) {
        setIsCapmaignOrder(true);
      }

      const { properties } = value;
      const isVendorTracked = isVendor && value.isVendorFulfilled === true;
      const moneyFormat = value.moneyFormat || constant.symbol.DOLLAR;
      const lineTotalPrice = value.price * value.fulfillableQuantity;
      const productPrice = value && parseFloat(value.price) || 0; 
      let lineItemStatus = value.status;

      if (value.fulfillmentStatus === constant.PARTIAL) {
        isAccepted = true;
        if (value.status === constant.SHIPPED) {
          lineItemStatus = constant.ACCEPTED;
          isFulfilled = false;
        }
        if (value.status === constant.NEW) {
          isFulfilled = true;
        }
      }

      const status = statusHelper.getBadgeStatus(lineItemStatus);
      const badgeType = baseHelper.getBadgeType(lineItemStatus);
      const lineItemName = <div className="productName">{value.name}</div>;

      const isFulfillLineItemButtonVisible =
        (value && value.fulfillmentType === constant.DROPSHIP && !isThirdPartyShipping && !value.consignmentId) ||
        (value && value.fulfillmentType === constant.DROPSHIP && isThirdPartyShipping && value.consignmentId) ||
        !(value && value.fulfillmentType === constant.DROPSHIP);

      const { _id: itemId, collectDetail = {} } = value;
      const lineItemLoading = (isfulfillLineItemLoading && isfulfillLineItemLoading[itemId]) || false;
      const isClickAndReserve = typeValue === constant.RESERVE;
      const isClickAndCollect = typeValue === constant.COLLECT;
      const isTimeBasedService = typeValue === constant.SERVICE;

      const isCollectDetails = !!(collectDetail && collectDetail.code);
      const isPickup = typeValue === constant.PICKUP;
      const isServiceLineItem = baseHelper.isServiceLineItem(typeValue, properties);
      const productURL = isServiceLineItem
        ? `/products/service/edit/${value.productId}`
        : `/products/edit/${value.productId}`;

      // if (isAccepted && !isFulfilled && isFulfillLineItemButtonVisible) {
      //   const isPackingSlipEnabled = (actionData || []).find((action) => action.id === "packingSlip");
      //   if (!isPackingSlipEnabled) {
      //     const packingSlip = {
      //       action: () => window.open(`/order-slip/${match.params.id}`),
      //       caption: cms("caption.packingSlip"),
      //       id: "packingSlip",
      //       label: cms("label.packingSlip"),
      //       icon: NoteMajor,
      //     };
      //     linkData.push(packingSlip);
      //     setActionData(linkData);
      //   }
      // }

      const items = [
        {
          content: cms("popover.label.accept"),
          icon: CircleTickOutlineMinor,
          onAction: () => [handleBulkAccept(false, [itemId])],
        },
        {
          content: cms("popover.label.edit"),
          icon: EditMinor,
          onAction: () => [openModel(constant.BULK_EDIT, [itemId]), setIsBulkChecked(false)],
          disabled: value.isCampaign,
        },
        {
          content: cms("popover.label.reject"),
          destructive: true,
          icon: CircleCancelMinor,
          onAction: () => [setRejectModal(true), setSelectedItems([itemId])],
        },
      ];
      const fulfillItems = [
        {
          content: isPickup ? cms("label.readyToCollect") : cms("popover.label.fulfill"),
          icon: QuickSaleMajor,
          onAction: () => [openTrackingModel(value), setIsBulkCheckedFulfilled(false)],
          // disabled: value.isCampaign,
        },
        {
          content: cms("popover.label.edit"),
          icon: EditMinor,
          onAction: () => openModel(constant.BULK_EDIT, [itemId]),
          disabled: value.isCampaign,
        },
        {
          content: cms("popover.label.reject"),
          destructive: true,
          icon: CircleCancelMinor,
          onAction: () => [setRejectModal(true), setSelectedItems([itemId])],
        },
      ];

      return (
        <div className="badge-gap" key={lineItemKey}>
          <Card>
            <Card.Section subdued>
              <StyledCard className="styled-card-unfulfill">
                <Stack wrap={false}>
                  <Stack.Item>
                    {!value.rejectedByVendor && (
                      <Checkbox
                        id={itemId}
                        checked={isBulkChecked && selectedItems.find((element) => element === itemId)}
                        onChange={() => [handleChange(value), setIsBulkChecked(true)]}
                      />
                    )}
                  </Stack.Item>
                  <Stack.Item>
                    <Thumbnail size="large" source={lineImage} alt={value.name} />
                  </Stack.Item>
                  <Stack.Item fill>
                    {(isProductDeleted && (lineItemName || "")) || (
                      <Link onClick={() => history.push(productURL)}>{value.name || ""}</Link>
                    )}

                    <Caption>
                      <TextStyle variation={constant.SUBDUED}>
                        {value.vendor && value.vendorId && `${cms("label.vendor")}: `}
                        {value.vendor}
                      </TextStyle>
                      {isServiceLineItem ? (
                        ""
                      ) : (
                        <TextStyle variation={constant.SUBDUED}>{`${cms("label.skuList")} ${value.sku}`}</TextStyle>
                      )}
                      <LineItemProperties
                        variation={constant.SUBDUED}
                        properties={properties}
                        isServiceLineItem={isServiceLineItem}
                      />
                    </Caption>

                    {isClickAndReserve && <Badge className="text-capitalize">{cms("label.clickAndReserve")}</Badge>}
                    {isClickAndCollect && <Badge className="text-capitalize">{cms("label.clickAndCollect")}</Badge>}
                    {isTimeBasedService && <Badge className="text-capitalize">{cms("label.timeService")}</Badge>}

                    <Badge
                      status={
                        (isAccepted && lineItemStatus === cms("label.accepted").toLowerCase() && constant.SUCCESS) ||
                        (isAccepted === false && constant.WARNING) ||
                        (isAccepted && lineItemStatus === cms("label.new").toLowerCase() && constant.ATTENTION) ||
                        badgeType
                      }
                      className="text-capitalize"
                    >
                      {(isAccepted &&
                        value.rejectedByVendor &&
                        lineItemStatus === cms("label.accepted").toLowerCase() &&
                        " Accepted By operator") ||
                        (isAccepted &&
                          lineItemStatus === cms("label.accepted").toLowerCase() &&
                          cms("label.accepted")) ||
                        (isAccepted && lineItemStatus === cms("label.new").toLowerCase() && cms("label.new")) ||
                        ([constant.AUTO_REJECT, constant.NO_SHOW].includes(status) &&
                          baseHelper.getBadgeStatus(status)) ||
                        status}
                    </Badge>
                    {value.hopscotch && value.hopscotch.status && (
                      <Badge
                        status={
                          (value.hopscotch.status === constant.displayStatus.DELIVERED && constant.SUCCESS) ||
                          (value.hopscotch.status === constant.FAILED && constant.WARNING) ||
                          ((value.hopscotch.status === constant.displayStatus.OUT_OF_DELIVERY ||
                            value.hopscotch.status === constant.displayStatus.READY_FOR_DELIVERY) &&
                            constant.ATTENTION)
                        }
                      >
                        {`${baseHelper.getBadgeStatus(value.hopscotch.status).replace(/-/g, " ")}`}
                      </Badge>
                    )}
                    {value.rejectedByVendor && (
                      <Badge className="text-capitalize" status="critical">
                        Rejected By Vendor
                      </Badge>
                    )}

                    {isAccepted && isFulfilled && !value.rejectedByVendor && (
                      <Stack alignment="center">
                        <Stack.Item>
                          <Popover
                            active={activePopover[itemId]}
                            setActive={() => {
                              setLineLoading([itemId]);
                              setActivePopover({ [itemId]: !activePopover[itemId] });
                            }}
                            item={items}
                            loading={loading && lineLoading.includes(itemId)}
                          />
                        </Stack.Item>
                      </Stack>
                    )}
                    {isAccepted && !isFulfilled && isFulfillLineItemButtonVisible && !value.rejectedByVendor && (
                      <Stack alignment="center">
                        <div className="Polaris-button-minwidth">
                          <Stack.Item>
                            <ButtonGroup>
                              {fulfillment && isClickAndReserve && (
                                <>
                                  <Button
                                    size="slim"
                                    plain={isCollectDetails}
                                    onClick={() => openClickReserveModal(value)}
                                  >
                                    {isCollectDetails
                                      ? `${cms("label.editPickupDetails")}`
                                      : `${cms("label.markReadyForCollection")}`}
                                  </Button>
                                </>
                              )}
                              {fulfillment && isClickAndCollect && (
                                <>
                                  <Button
                                    size="slim"
                                    plain={isCollectDetails}
                                    onClick={() => openClickCollectModal(value)}
                                  >
                                    {isCollectDetails
                                      ? `${cms("label.editPickupDetails")}`
                                      : `${cms("label.markReadyForCollection")}`}
                                  </Button>
                                </>
                              )}
                              {fulfillment && isTimeBasedService && (
                                <>
                                  <Button
                                    size="slim"
                                    plain={isCollectDetails}
                                    onClick={() => openTimeBasedModal(value)}
                                  >
                                    {isCollectDetails
                                      ? `${cms("label.editServiceDetails")}`
                                      : `${cms("label.markReadyService")}`}
                                  </Button>
                                </>
                              )}
                              {((!isClickAndReserve && !isClickAndCollect && !isTimeBasedService) ||
                                ((isClickAndReserve || isClickAndCollect || isTimeBasedService) && isCollectDetails)) &&
                                ((isClickAndReserve || isClickAndCollect || isTimeBasedService) && isCollectDetails ? (
                                  <Button
                                    size="slim"
                                    onClick={() => openTrackingModel(value)}
                                    loading={lineItemLoading}
                                  >
                                    {cms("button.confirm")}
                                  </Button>
                                ) : (
                                  <Stack alignment="center">
                                    <Stack.Item>
                                      <Popover
                                        active={fulfillPopover[itemId]}
                                        setActive={() => setFulfillPopover({ [itemId]: !fulfillPopover[itemId] })}
                                        item={fulfillItems}
                                      />
                                    </Stack.Item>
                                  </Stack>
                                ))}
                              <Stack alignment="center">
                                {isAccepted && !isFulfilled && isFulfillLineItemButtonVisible && checkDisable() && (
                                  <Button
                                    plain
                                    size="slim"
                                    onClick={() => window.open(`/order-slip/${match.params.id}`)}
                                  >
                                    <Stack spacing="extraTight" alignment="center">
                                      <Stack.Item>
                                        <Icon source={NoteMajor} />
                                      </Stack.Item>
                                      {getSlipText(value.fulfillmentType)}
                                    </Stack>
                                  </Button>
                                )}
                              </Stack>
                            </ButtonGroup>
                          </Stack.Item>
                        </div>
                      </Stack>
                    )}
                  </Stack.Item>
                  <Stack.Item>{` ${cms("label.qty")}: ${value.fulfillableQuantity}`}</Stack.Item>
                  <Stack.Item>
                    {moneyFormat}
                    {(productPrice && productPrice.toFixed(2)) || constant.value.ZERO}
                  </Stack.Item>
                </Stack>
              </StyledCard>
            </Card.Section>
          </Card>
        </div>
      );
    });
    return UnfulFilled;
  };

  const renderTrackingMethod =
    isSeller || !isConsignment
      ? renderTracking()
      : ((fulfillmentType === constant.PICKUP && addressListData && addressListData.length) ||
          fulfillmentType === constant.CONSIGNMENT) &&
        renderConsignmentTracking();

  return [renderUnfullfilled(), renderTotalAmount(), renderTrackingMethod, renderCollection()];
};

export default UnfulfilledLineItems;
