import React, { useContext } from "react";
import _ from "lodash";
import { Badge, Button, Card, Caption, Link, Stack, TextStyle, Thumbnail } from "@shopify/polaris";
import { NoteMajor } from "@shopify/polaris-icons";
import { PrivateContext } from "lib/context";
import { OrderContext } from "app/orders/modules/provider/features/view/orderContext";
import constant from "lib/constant/constant";
import { statusHelper, baseHelper, imageHelper } from "lib/helpers";
import LineItemProperties from "app/orders/modules/generic/service/lineItemProperties";
import { StyledCard } from "app/orders/modules/operator/features/view/genericStyled";

const FulfilledLineItems = (props) => {
  const { data, fulfilledData, getSlipText, lineItemArray, packingSlipAccessData, setFulfillAmount } = props;
  const { cms, history, userData, match } = useContext(PrivateContext);
  const {
    setAddressId,
    fulfillmentType: orderFulfillmentType,
    setFulfillmentType: setOrderFulfillmentType,
  } = useContext(OrderContext);
  const { PARTIAL, FULFILLED, REFUND } = constant;
  const currentUserData = baseHelper.getResponseData(userData, constant.gql.GET_CURRENT_USER);

  const amount = [];
  const vendorsSum = [];
  let countItems = 0;

  fulfilledData &&
    fulfilledData.length &&
    fulfilledData.forEach((dataLine) => {
      const { totalPrice = 0, vendor } = dataLine;
      vendorsSum.push(vendor);
      amount.push(totalPrice);
      countItems += 1;
      return [amount];
    });

  const vendorSet = new Set(vendorsSum);
  const setSize = vendorSet.size;
  const addVendorTxt =
    setSize === constant.value.ONE ? `${setSize} ${cms("label.vendor")}` : `${setSize} ${cms("label.vendors")}`;

  const stringToNumber = amount.map((item) => parseFloat(item));
  const totalAmount = stringToNumber.reduce((cur, acc) => cur + acc, constant.value.ZERO);
  if (totalAmount) {
    setFulfillAmount(totalAmount);
  }

  const isVendor = baseHelper.isVendor(currentUserData);

  const lineItems = fulfilledData.map((lineItem, idx) => {
    const {
      id: shopifyLineItemId = "",
      name = "",
      sku,
      totalPrice,
      totalQuantity,
      // fulfillmentStatus,
      fulfillmentType,
    } = lineItem || {};
    const getItem = lineItemArray.find((item) => item.shopifyLineItemId === shopifyLineItemId);
    const {
      fulfillmentStatus,
      image = "",
      isProductDeleted,
      isVendorDeleted,
      moneyFormat: lineMoneyFormat,
      productId,
      rejectedByVendor = false,
      vendor,
      vendorId,
      properties,
    } = getItem || {};

    const lineItemKey = `lineItem${idx}`;
    const lineImage = imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;
    const typeValue = getItem && getItem.fulfillmentType;
    const typeData = orderFulfillmentType;
    if (typeData && _.isArray(typeData) && typeValue && !typeData.includes(typeValue)) {
      typeData.push(typeValue);
    }
    setOrderFulfillmentType(typeData);
    if (typeValue === constant.CONSIGNMENT) {
      setAddressId((getItem && getItem.addressId) || "");
    }

    const moneyFormat = lineMoneyFormat || constant.symbol.DOLLAR;
    const isServiceLineItem = baseHelper.isServiceLineItem(typeValue, properties);
    const status = statusHelper.getBadgeStatus(
      isServiceLineItem ? constant.COMPLETED : rejectedByVendor ? constant.FULFILLED_BY_OPERATOR : constant.FULFILLED
    );
    const badgeType = baseHelper.getBadgeType(constant.FULFILLED);
    const productURL = isServiceLineItem ? `/products/service/edit/${productId}` : `/products/edit/${productId}`;

    const lineItemName = <div className="productName">{name}</div>;

    return (
      <div key={lineItemKey} className="fulfilled-Secn">
        <Card>
          <Card.Section subdued>
            <StyledCard className="styled-card-unfulfill">
              <Stack wrap={false}>
                <Stack.Item>
                  <Thumbnail size="large" source={lineImage} alt={name} />
                </Stack.Item>
                <Stack.Item fill>
                  <div className="ellipsis">
                    {(isProductDeleted && (lineItemName || "")) || (
                      <Link onClick={() => history.push(productURL)}>{lineItem.name || ""}</Link>
                    )}

                    <Caption>
                      <LineItemProperties
                        variation={constant.SUBDUED}
                        properties={properties}
                        isServiceLineItem={isServiceLineItem}
                      />
                      <TextStyle variation={constant.SUBDUED}>
                        {vendor && vendorId && `${cms("label.vendor")}: ${vendor}`}
                      </TextStyle>
                      {isServiceLineItem ? (
                        ""
                      ) : (
                        <TextStyle variation={constant.SUBDUED}>
                          {cms("label.skuList")} {sku}
                        </TextStyle>
                      )}
                    </Caption>
                    <Badge status={badgeType} className="text-capitalize">
                      {status}
                    </Badge>
                    {rejectedByVendor && (
                      <Badge status={baseHelper.getBadgeType(constant.REJECTED)} className="text-capitalize">
                        {constant.REJECTED}
                      </Badge>
                    )}
                    {!(fulfillmentStatus === constant.REFUND) && (
                      <span className="packing-slip-link">
                        {[FULFILLED, PARTIAL, REFUND].includes(fulfillmentStatus) &&
                          (packingSlipAccessData.isEnabled ||
                            !(
                              packingSlipAccessData.isAllVendor ||
                              (packingSlipAccessData.selectedVendor &&
                                packingSlipAccessData.selectedVendor.includes(vendorId))
                            )) && (
                            <Button
                              plain
                              icon={NoteMajor}
                              onClick={() => window.open(`/order-slip/${match.params.id}`)}
                            >
                              {getSlipText(fulfillmentType)}
                            </Button>
                          )}
                      </span>
                    )}
                  </div>
                </Stack.Item>
                <Stack.Item>
                  {`${cms("label.qty")}:`}
                  {totalQuantity}
                </Stack.Item>
                <Stack.Item>
                  {moneyFormat}
                  {(totalPrice && totalPrice.toFixed(2)) || constant.value.ZERO}
                </Stack.Item>
              </Stack>
            </StyledCard>
          </Card.Section>
        </Card>
      </div>
    );
  });
  const moneyFormat = data && data.moneyFormat;
  const totalFulfill = (
    <Card.Section>
      <Stack>
        <Stack.Item fill>
          <TextStyle variation="strong">{cms("label.total")}</TextStyle>
        </Stack.Item>
        <Stack.Item>
          <Caption>
            {" "}
            (
            {countItems > 1
              ? `${countItems}
                ${cms("label.items")} from ${addVendorTxt}`
              : `${countItems}
                ${cms("label.item")} from ${addVendorTxt}`}
            )
          </Caption>
        </Stack.Item>
        <Stack.Item>
          <TextStyle variation="strong">
            {`${moneyFormat || constant.symbol.DOLLAR}${totalAmount.toFixed(2)}`}
          </TextStyle>
        </Stack.Item>
      </Stack>
    </Card.Section>
  );
  return [lineItems, totalFulfill];
};

export default FulfilledLineItems;
