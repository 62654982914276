import React, { useState, useContext, useEffect } from "react";
import { Layout, PageActions } from "@shopify/polaris";
import { useMutation, useQuery } from "react-apollo";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";
import { Banner, SkeletonAnnotated } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { NotFound } from "layout/private/components";

import { GET_CURRENCY_RATE, GET_VENDOR_VARIANT } from "app/product/apollo/queries";
import { UPDATE_VARIANT, UPLOAD_IMAGE } from "app/product/apollo/mutations";
import validate from "app/product/modules/admin/features/variant/subFeatures/yup/validate";
import { TitleEdit, Image, Price, Inventory, Shipping } from "./subFeatureItems";

const {
  value: { MAX_MEASUREMENT = 999, MAX_FLAT, MIN_PRICE },
} = constant;

const AdminVariantEdit = () => {
  const { cms, history, match } = useContext(PrivateContext);
  const { params } = match;
  const { location } = history;
  const { state } = location;
  const { productId, id: variantId } = params;
  const [disablePrice, setDisablePrice] = useState(false);
  const [basePricing, setBasePricing] = useState();
  const [currencyPrice, setCurrencyPrice] = useState();
  const [currencySymbol, setCurrencySymbol] = useState();
  const [moneyFormat, setMoneyFormat] = useState();
  const [vendor, setVendor] = useState();
  const [rate, setRate] = useState([]);

  const { loading: currencyRateLoading, data: currencyRateData } = useQuery(GET_CURRENCY_RATE, {
    variables: {
      input: { vendorId: vendor },
    },
  });

  const { data: variantData, loading, error } = useQuery(GET_VENDOR_VARIANT, {
    variables: { input: { id: productId } },
  });

  const [updateVariant, { loading: updateLoading, error: updateVariantError }] = useMutation(UPDATE_VARIANT, {
    refetchQueries: [
      {
        query: GET_VENDOR_VARIANT,
        variables: { input: { id: productId } },
      },
    ],
  });

  const [uploadImage, { loading: imageLoading, error: uploadImageError }] = useMutation(UPLOAD_IMAGE);
  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
  });
  const [variantValue, setVariantValue] = useState({});
  const [image, setImage] = useState();
  const [options, setOptions] = useState({
    option1Val: null,
    option2Val: null,
    option3Val: null,
  });
  const [disabledButton, setDisabledButton] = useState(true);
  const [productImages, setProductImages] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [submitButton, setSubmitState] = useState({
    isReadyToSubmit: false,
  });

  const { gql } = constant;

  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const acceptOnlyValidInput = (value, prevValue, max = MAX_FLAT, min = MIN_PRICE) => {
    if (value >= min && value <= max) {
      return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
    }
    if (!value) {
      return "";
    }
    return prevValue;
  };

  const currencyRateError = baseHelper.getResponseError(currencyRateData, gql.GET_CURRENCY_RATE);

  useEffect(() => {
    if (currencyRateError) {
      return;
    }
    const currencyRateSetting = baseHelper.getResponseData(currencyRateData, constant.gql.GET_CURRENCY_RATE) || {};
    const { currency = {} } = currencyRateSetting || {};
    const { rate: rateData = [], selectedVendor = [], isAllVendor = false } = currency || {};
    const isVendorEnabled =
      selectedVendor && selectedVendor.length && selectedVendor.find((vendorId) => vendorId === vendor);
    setRate(rateData);
    if (isAllVendor) {
      setDisablePrice(false);
      return;
    }
    setDisablePrice(!isVendorEnabled);
  }, [currencyRateError, currencyRateData, vendor]);

  useEffect(() => {
    if (state && state.addVariant) {
      setBanner({
        action: {
          content: cms("label.action"),
          onAction: () => history.push(`/variants/add/${productId}`),
        },
        isOpen: true,
        status: constant.SUCCESS,
        title: cms("message.success.create"),
      });
      const newState = state;
      delete newState.addVariant;
      history.replace({ ...history.location, state: newState });
    }
  }, [cms, history, productId, state]);

  useEffect(() => {
    if (error || uploadImageError || updateVariantError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: errorHelper.parse(error || uploadImageError || updateVariantError),
      });
    }
  }, [error, uploadImageError, updateVariantError]);

  useEffect(() => {
    if (rate && rate.length && basePricing && basePricing.currencyCode) {
      const currencyData =
        rate && rate.length && rate.find((element) => basePricing.currencyCode === element.currencyCode);
      if (currencyData) {
        const { currencySymbol: symbol, price } = currencyData;
        setCurrencySymbol(symbol);
        setCurrencyPrice(price);
      }
    }
  }, [basePricing, rate]);

  useEffect(() => {
    const { option1Val, option2Val, option3Val } = options;
    let isAllValuesFilled = false;
    if (option1Val) {
      isAllValuesFilled = option1Val && variantValue.option1Val;
    }
    if (option2Val) {
      isAllValuesFilled = option2Val && variantValue.option2Val;
    }
    if (option3Val) {
      isAllValuesFilled = option3Val && variantValue.option3Val;
    }

    const isAnyValidationError =
      errorMessage &&
      !!(
        (errorMessage.size && option1Val) ||
        (errorMessage.color && option2Val) ||
        (errorMessage.material && option3Val)
      );
    setSubmitState((prevState) => ({
      ...prevState,
      isReadyToSubmit: isAllValuesFilled && !isAnyValidationError,
    }));
  }, [variantValue, errorMessage, options]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const responseData = baseHelper.getResponseData(variantData, gql.GET_PRODUCT);
    if (responseData) {
      const { variants = [], images = [], vendorId, moneyFormat: operatorMoneyFormat } = responseData || {};
      if (variants.length < variantId || variantId <= 0) {
        return <NotFound />;
      }
      const variantOption = variants[variantId - 1];
      setOptions({
        option1: variantOption.option1,
        option2: variantOption.option2,
        option3: variantOption.option3,
        option1Val: variantOption.option1Val,
        option2Val: variantOption.option2Val,
        option3Val: variantOption.option3Val,
      });
      setProductImages(images || []);
      setImage((variantOption.image && [{ url: variantOption.image }]) || []);
      delete variants[variantId - 1].vendorShopifyVariantId;
      setVariantValue(variants[variantId - 1]);
      setBasePricing(variants[variantId - 1].basePricing);
      setVendor(vendorId || "");
      setMoneyFormat(operatorMoneyFormat);
    }
  }, [variantData, gql, variantId]);

  if (currencyRateLoading || loading) {
    return <SkeletonAnnotated />;
  }

  const variantUpdate = (updateValues) => {
    updateVariant({ variables: { input: updateValues } })
      .then((res) => {
        const resError = baseHelper.getResponseError(res.data, gql.EDIT_VARIANT);
        const bannerData = {};
        bannerData.url = true;
        bannerData.status = constant.SUCCESS;
        bannerData.title = cms("message.success.update");
        bannerData.action = {
          content: cms("message.success.review"),
          onAction: () => history.push(`/products/edit/${productId}`),
        };
        if (resError) {
          bannerData.url = false;
          bannerData.title = resError;
          bannerData.status = constant.CRITICAL;
          banner.action = null;
        }
        setBanner({ isOpen: true, status: bannerData.status, title: bannerData.title, action: bannerData.action });
      })
      .catch((exception) => {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
        });
      });
  };

  const onSubmit = async () => {
    const result = variantValue;
    result.comparePrice = parseFloat(result.comparePrice);
    result.price = parseFloat(result.price);
    result.weight = parseFloat(result.weight);
    const { price, comparePrice } = basePricing;
    result.basePricing = { price: parseFloat(price || 0), comparePrice: parseFloat(comparePrice || 0) };
    await handleValidation("option1", result.option1Val);
    await handleValidation("option2", result.option2Val);
    await handleValidation("option3", result.option3Val);

    if (!submitButton.isReadyToSubmit) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: constant.SMOOTH,
      });
      return false;
    }

    const { vendorDiscount, discount, measurement = {} } = result || {};
    result.discount = vendorDiscount || discount || {};
    result.discount.price = parseFloat(result.discount.price || 0);

    const { length = {}, width = {}, height = {} } = measurement || {};
    let { value: lengthValue = 0 } = length || {};
    let { value: widthValue = 0 } = width || {};
    let { value: heightValue = 0 } = height || {};

    lengthValue = parseFloat(lengthValue);
    widthValue = parseFloat(widthValue);
    heightValue = parseFloat(heightValue);

    if (lengthValue > MAX_MEASUREMENT || widthValue > MAX_MEASUREMENT || heightValue > MAX_MEASUREMENT) {
      const bannerData = {
        action: null,
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("section.measurement.length.title"),
      };
      setBanner(bannerData);
      return false;
    }

    // // check volumetric weight is greater than 180 or not if yes then through error
    // const isLargeVolumetricWeight = baseHelper.isLargeVolumetricWeight(lengthValue, widthValue, heightValue);
    // if (isLargeVolumetricWeight) {
    //   const bannerData = {
    //     action: null,
    //     isOpen: true,
    //     status: constant.CRITICAL,
    //     title: cms("section.measurement.width.title"),
    //   };
    //   setBanner(bannerData);
    //   return false;
    // }

    result.measurement = {
      length: {
        value: lengthValue,
        unit: constant.CM,
      },
      width: {
        value: widthValue,
        unit: constant.CM,
      },
      height: {
        value: heightValue,
        unit: constant.CM,
      },
    };

    const notToUpdate = constant.NOT_TO_UPDATE;
    notToUpdate.forEach((element) => {
      delete result[element];
    });

    if (image && image.length && image[0].url) {
      const imageUrl = image[0].url;
      result.image = { url: imageUrl };
    }
    result.id = parseInt(variantId, 10);
    result.productId = productId;

    const isLocalImageUrl = image && image.upload === constant.LOCAL;

    if (!isLocalImageUrl) {
      variantUpdate(result);
    }

    if (isLocalImageUrl) {
      try {
        delete image.upload;
        const res = await uploadImage({ variables: { input: { image: image[0] } } });
        const resData = baseHelper.getResponseData(res.data, gql.UPLOAD_IMAGE);
        if (resData) {
          result.image = { url: resData.imageUrl };
          variantUpdate(result);
        }
      } catch (exception) {
        setBanner({
          isOpen: true,
          status: constant.CRITICAL,
          title: errorHelper.parse(exception),
        });
      }
    }
    setDisabledButton(true);
    return null;
  };

  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const handleChange = (fieldName, fieldValue) => {
    if (fieldValue > -1 || fieldValue.price > -1 || constant.SKU) {
      setVariantValue((prevState) => ({
        ...prevState,
        [fieldName]: fieldValue,
      }));
      setDisabledButton(false);
    }
  };

  const handleMeasurementChange = (fieldName, fieldValue) => {
    if (fieldValue > -1) {
      setVariantValue((prevState) => ({
        ...prevState,
        measurement: {
          ...(prevState.measurement || {}),
          [fieldName]: {
            ...((prevState.measurement && prevState.measurement[fieldName]) || {}),
            value: fieldValue,
          },
        },
      }));
      setDisabledButton(false);
    }
  };

  const handleTitleChange = (fieldName, fieldValue) => {
    setVariantValue((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
    setDisabledButton(false);
  };

  const handleCurrencyPrice = (field, newValue) => {
    setBasePricing((prevState) => ({
      ...prevState,
      [field]: newValue,
    }));
    if (currencyPrice) {
      setVariantValue({
        ...variantValue,
        [field]: parseFloat(((1 / currencyPrice) * newValue).toFixed(2)),
      });
    }
  };

  return (
    <>
      {banner.isOpen && (
        <>
          <Layout.Section>
            <Banner
              title={banner.title}
              status={banner.status}
              isOpen={banner.isOpen}
              action={banner.action}
              onDismiss={onDismissBanner}
            />
          </Layout.Section>
          <br />
        </>
      )}
      <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
        <TitleEdit
          cms={cms}
          errorMessage={errorMessage}
          handleChange={handleTitleChange}
          handleValidation={handleValidation}
          options={options}
          setDisabledButton={setDisabledButton}
          variantData={variantValue}
        />
        <Image
          productImages={productImages}
          setImage={setImage}
          setDisabledButton={setDisabledButton}
          image={image}
          cms={cms}
        />
        <Price
          acceptOnlyValidInput={acceptOnlyValidInput}
          basePricing={basePricing}
          cms={cms}
          currencyPrice={currencyPrice}
          currencySymbol={currencySymbol}
          disablePrice={disablePrice}
          handleChange={handleChange}
          handleCurrencyPrice={handleCurrencyPrice}
          moneyFormat={moneyFormat}
          setBasePricing={setBasePricing}
          setDisabledButton={setDisabledButton}
          variantData={variantValue}
        />
        <Inventory
          cms={cms}
          handleChange={handleChange}
          handleTitleChange={handleTitleChange}
          setDisabledButton={setDisabledButton}
          variantData={variantValue}
        />
        <Shipping
          acceptOnlyValidInput={acceptOnlyValidInput}
          handleChange={handleChange}
          handleMeasurementChange={handleMeasurementChange}
          handleTitleChange={handleTitleChange}
          setDisabledButton={setDisabledButton}
          variantData={variantValue}
          cms={cms}
        />

        <PageActions
          primaryAction={{
            content: cms("section.label.button.submit"),
            onAction: () => onSubmit(),
            disabled: disabledButton,
            loading: updateLoading || imageLoading,
          }}
          secondaryActions={{
            content: cms("section.label.button.cancel"),
            onAction: () => history.push(`/products/edit/${productId}?tab=2`),
            disabled: updateLoading || imageLoading,
          }}
        />
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(AdminVariantEdit), { feature: constant.ADD_VARIANT });
