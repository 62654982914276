/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from "react";
import { DisplayText } from "@shopify/polaris";
import PropTypes from "prop-types";

import { baseHelper } from "lib/helpers";
import { Spinner } from "lib/components";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { useQuery } from "@apollo/react-hooks";

import { GET_INVOICE_SETTING, GET_SELLER_SETTING } from "app/setup/apollo/queries";

import StyledSlip from "asset/styles/paymentSlip";

const { gql } = constant;

require("jspdf-autotable");

const OrderSlipView = (props) => {
  const { currentUser, cms } = useContext(PrivateContext);
  const [invoiceSetting, setInvoiceSetting] = useState({
    purchaseFrom: {
      label: cms("label.title"),
      isHide: false,
    },
    deliverTo: {
      label: cms("label.to"),
      isHide: false,
    },
    product: {
      label: cms("label.item"),
      isHide: false,
    },
    sku: {
      label: cms("label.sku"),
      isHide: false,
    },
    quantity: {
      label: cms("label.quantity"),
      isHide: false,
    },
    price: {
      label: cms("label.price"),
      isHide: false,
    },
    tax: {
      label: cms("label.tax"),
      isHide: false,
    },
    discount: {
      label: cms("label.discount"),
      isHide: false,
    },
    // status: {
    //   label: cms("label.status"),
    //   isHide: false,
    // },
    subtotal: {
      label: cms("label.subTotal"),
      isHide: false,
    },
    shipping: {
      label: cms("label.shipping"),
      isHide: false,
    },
    sellerCommission: {
      label: cms("label.sellerCommission"),
      isHide: false,
    },
    totalDeduction: {
      label: cms("label.totalDeduction"),
      isHide: false,
    },
    orderTotal: {
      label: cms("label.orderTotal"),
      isHide: false,
    },
    orderPayout: {
      label: cms("label.vendorAmount"),
      isHide: false,
    },
    totalVendorPayout: {
      label: cms("label.totalVendorPayout"),
      isHide: false,
    },
    fulfillBy: {
      label: cms("label.fulfill"),
      isHide: false,
    },
    totalOrderTax: {
      label: cms("label.totalOrderTax"),
      isHide: false,
    },
    totalAmount: {
      label: cms("label.totalAmount"),
      isHide: false,
    },
    totalDeductionAmount: {
      label: cms("label.totalDeductionAmount"),
      isHide: false,
    },
    totalOrderShipping: {
      label: cms("label.totalOrderShipping"),
      isHide: false,
    },
    totalDeductionTax: {
      label: cms("label.totalDeductionTax"),
      isHide: false,
    },
    totalOrderAmount: {
      label: cms("label.totalOrderAmount"),
      isHide: false,
    },
  });

  const {
    invoiceNumberPrefix,
    fulfillment,
    logo,
    orderData,
    orderLineItems,
    sellerInfo = {},
    vendorInfo = {},
    viewOrderData = {},
  } = props;

  const isSeller = baseHelper.isSeller(currentUser);
  const { type = "" } = fulfillment || {};
  const [orderLine] = orderLineItems || [];
  const {
    commissionTax,
    createdAt,
    moneyFormat,
    shopifyOrderId,
    dueDate,
    shippingTax,
    price,
    commissionSetting,
    commissionTaxSetting,
    shippingTaxSetting,
    isDiscountAllowed: isDynamicDiscount,
  } = orderLine || {};

  const { rate: commissionRate = "", type: commissionType = "" } = commissionSetting || {};
  const { rate: commissionTaxRate = "", type: commissionTaxType = "" } = commissionTaxSetting || {};
  const { rate: shippingTaxRate = "", type: shippingTaxType = "" } = shippingTaxSetting || {};

  const totals = baseHelper.getOrderPrice(orderLineItems, orderData, isSeller, false, true);
  const { taxSetting: sellerTaxInfo = {} } = sellerInfo;
  const { vat = "" } = sellerTaxInfo || {};
  let lineitemNumber = 1;
  let summaryAmount = 0;

  const [isExcludeShippingAmount, setIsExcludeShippingAmount] = useState(false);

  const vendorAddress = baseHelper.getFullAddress(vendorInfo);
  const vendorName = baseHelper.getFullName(vendorInfo);
  const sellerAddress = baseHelper.getFullAddress(sellerInfo);
  const customerAddress = baseHelper.getCustomerAddress(viewOrderData.shippingAddress || {});
  const sellerName = baseHelper.getFullName(sellerInfo);
  const isVendor = baseHelper.isVendor(currentUser);

  const { code: discountLineCode = "" } =
    (viewOrderData.discountCodes && viewOrderData.discountCodes.length && viewOrderData.discountCodes[0]) || {};

  const { data: invoiceSettingData, loading: invoiceSettingLoading } = useQuery(GET_INVOICE_SETTING);
  const { data: sellerSettingData, loading: sellerSettingLoading } = useQuery(GET_SELLER_SETTING);

  const manageInvoiceResponseData = baseHelper.getResponseData(invoiceSettingData, gql.GET_INVOICE_SETTING);
  const { payment } = manageInvoiceResponseData || {};
  const { invoice = {} } = payment || {};
  const { note = {} } = invoice || {};
  const { value = "" } = note || {};
  let totalDiscountValue = 0;

  useEffect(() => {
    if (payment) {
      const { invoice = {} } = payment;
      const { manage: invoiceData } = invoice || {};
      if (invoiceData) {
        const setting = baseHelper.getInvoiceSlipSettings(invoiceData, cms);
        setInvoiceSetting(setting);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);

  useEffect(() => {
    if (!sellerSettingData) {
      return;
    }

    const sellerData = baseHelper.getResponseData(sellerSettingData, constant.gql.GET_SELLER_SETTING);
    if (!sellerData) {
      return;
    }

    const { shipping = {} } = sellerData;
    const { isExcludeShippingAmount = false } = shipping || {};

    setIsExcludeShippingAmount(isExcludeShippingAmount);
  }, [sellerSettingData]);

  // const invoiceSetting = baseHelper.getInvoiceSlipSettings(settingData, cms);

  const fulfillmentData =
    fulfillment && type === constant.CONSIGNMENT && baseHelper.fulfillmentDetails(fulfillment, currentUser);

  const getSellerCommission = (orderLines = []) => {
    const sellerCommisionDetails = {
      priceTaxRate: 0,
      commissionSeller: 0,
      sellerTaxOnCommission: 0,
    };
    orderLines.forEach((item) => {
      const isRejected = item && item.status === constant.REJECTED;
      let { commissionSeller, priceTaxRate } = item || {};
      const tax = baseHelper.getPrice(parseFloat(priceTaxRate || 0) / 100);
      const totalPriceInclusiveTax = baseHelper.getPrice(item.price * item.quantity);
      const priceExclusiveTax = baseHelper.getPrice(totalPriceInclusiveTax / (1 + parseFloat(tax)));
      const totalTax = parseFloat(totalPriceInclusiveTax) - parseFloat(priceExclusiveTax);
      const sellerCommissionInPercent = (commissionSeller / parseFloat(totalPriceInclusiveTax)) * 100;
      const sellerTaxOnCommission = totalTax * (sellerCommissionInPercent / 100);
      if (isRejected) {
        commissionSeller = 0;
        priceTaxRate = 0;
      }
      sellerCommisionDetails.priceTaxRate += priceTaxRate;
      sellerCommisionDetails.commissionSeller += Number(commissionSeller);
      sellerCommisionDetails.sellerTaxOnCommission += sellerTaxOnCommission;
    });
    return sellerCommisionDetails;
  };

  const getItemCommissionTax = (item = {}) => {
    const { commissionSeller, priceTaxRate } = item || {};
    const tax = baseHelper.getPrice(parseFloat(priceTaxRate || 0) / 100);
    const totalPriceInclusiveTax = baseHelper.getPrice(item.price * item.quantity);
    const priceExclusiveTax = baseHelper.getPrice(totalPriceInclusiveTax / (1 + parseFloat(tax)));
    const totalTax = parseFloat(totalPriceInclusiveTax) - parseFloat(priceExclusiveTax);
    const sellerCommissionInPercent = (commissionSeller / parseFloat(totalPriceInclusiveTax)) * 100;
    const sellerTaxOnCommission = totalTax * (sellerCommissionInPercent / 100);
    return sellerTaxOnCommission;
  };
  const getSubtotalAmount = (orderLineItem = {}) => {
    const { price = 0, actualQuantity = 1, vendorDiscount = 0 } = orderLineItem || {};
    const amount = parseFloat(price || 0) * parseFloat(actualQuantity);
    const totalAmount = parseFloat(amount || 0) - parseFloat(vendorDiscount || 0) * parseFloat(actualQuantity);
    summaryAmount += totalAmount;
    return totalAmount;
  };

  const getTotalAmount = (orderLineItem = {}) => {
    const { price = 0, actualQuantity = 1, vendorDiscount = 0 } = orderLineItem || {};
    const amounts = parseFloat(price || 0) * parseFloat(actualQuantity);
    const totalAmounts = parseFloat(amounts || 0) - parseFloat(vendorDiscount || 0) * parseFloat(actualQuantity);
    return totalAmounts;
  };

  let discountValue = 0;
  const getDynamicDiscount = (orderLineItem = {}) => {
    const { actualQuantity } = orderLineItem;

    const { value_type: dynamicDiscountType } = viewOrderData.discountApplications[0] || {};

    if (orderLineItem.discountAllocations && orderLineItem.discountAllocations.length) {
      const lastDiscountAllocations = orderLineItem.discountAllocations[orderLineItem.discountAllocations.length - 1];
      discountValue = lastDiscountAllocations.amount;
    }
    return parseFloat(discountValue).toFixed(2);
  };

  const getDeductionTotal = (orderLineItem = {}) => {
    const dynamicDiscount = getDynamicDiscount(orderLineItem);
    const result = parseFloat(orderLineItem.commissionSeller || 0) + parseFloat(dynamicDiscount || 0);
    const deductionTotal = isDynamicDiscount ? result : parseFloat(orderLineItem.commissionSeller || 0);
    return deductionTotal;
  };

  const getLinesSubTotal = (orderLineItem = {}) => {
    const {
      price = 0,
      actualQuantity = 1,
      isDiscountAllowed,
      vendorDiscount = 0,
      shippingTax,
      shippingAmount,
      commissionSeller,
      priceTaxRate,
      commissionTax: commissionTaxValue = 0,
    } = orderLineItem || {};
    const amount = parseFloat(price || 0) * parseFloat(actualQuantity);
    const totalAmount = parseFloat(amount || 0) - parseFloat(vendorDiscount || 0) * parseFloat(actualQuantity);
    let discountValue = 0;
    let taxRate = 0;
    if (!priceTaxRate) {
      taxRate = parseFloat(commissionSeller) + parseFloat(commissionTaxValue);
    }
    if (priceTaxRate && !commissionTaxValue) {
      taxRate = parseFloat(commissionSeller);
    }
    if (priceTaxRate && commissionTaxValue) {
      taxRate = parseFloat(commissionSeller) + parseFloat(commissionTaxValue);
    }
    if (isDiscountAllowed) {
      discountValue = getDynamicDiscount(orderLineItem);
    }
    const totalTax = parseFloat(shippingAmount) + parseFloat(shippingTax) - parseFloat(taxRate);
    const totalLineAmount = parseFloat(totalAmount) + parseFloat(totalTax) - parseFloat(discountValue);
    return totalLineAmount;
  };

  let totalDeductionTax = 0;
  let totalShippingTax = 0;
  let shippingAmountValue = 0;
  let deductionValue = 0;
  orderLineItems.forEach((item) => {
    totalDeductionTax += parseFloat(item.commissionTax || 0);
    totalShippingTax += parseFloat(item.shippingTax || 0);
    shippingAmountValue += parseFloat(item.shippingAmount || 0);
    deductionValue += parseFloat(item.commissionSeller || 0);
    totalDiscountValue += parseFloat(getDynamicDiscount(item) || 0);
  });

  const getTotalOperatorCommission = (orderLineItem = {}) => {
    const { priceTaxRate, commissionSeller = 0, commissionTax: commissionTaxValue = 0 } = orderLineItem || {};
    let totalOperatorCommission = 0;
    if (!priceTaxRate) {
      totalOperatorCommission = parseFloat(commissionSeller) + parseFloat(commissionTaxValue);
    }
    if (priceTaxRate && !commissionTaxValue) {
      totalOperatorCommission = parseFloat(commissionSeller);
    }
    if (priceTaxRate && commissionTaxValue) {
      totalOperatorCommission = parseFloat(commissionSeller) + parseFloat(commissionTaxValue);
    }
    return totalOperatorCommission;
  };

  const getTotalDeduction = (orderLineItem = {}) => {
    let discountValue = 0;
    const totalOperatorCommission = getTotalOperatorCommission(orderLineItem);
    if (orderLineItem.isDiscountAllowed) {
      discountValue = getDynamicDiscount(orderLineItem);
    }
    const totalDeduction = totalOperatorCommission + parseFloat(discountValue || 0);
    return totalDeduction;
  };

  const getOperatorCommissionTax = (orderLineItem = {}) => {
    const { priceTaxRate, commissionTax: commissionTaxValue = 0 } = orderLineItem || {};
    const operatorCommission =
      priceTaxRate && !commissionTaxValue ? getItemCommissionTax(orderLineItem) : parseFloat(commissionTaxValue);
    return operatorCommission;
  };

  const getTotalShipping = (orderLineItem = {}) => {
    const { shippingAmount = 0, shippingTax = 0 } = orderLineItem || {};
    const totalOperatorCommission = parseFloat(shippingAmount) + parseFloat(shippingTax);
    return totalOperatorCommission;
  };

  const getItemPriceTax = (orderLineItem = {}) => {
    const { priceTaxRate, price } = orderLineItem || {};
    const totalPriceTax = parseFloat(price) - parseFloat(price) / (1 + parseFloat(priceTaxRate) / 100);
    return totalPriceTax;
  };

  const checkShippingTax = (lineData = []) => {
    let isShippingTax = false;
    lineData.forEach((data) => {
      if (data.shippingTax) {
        isShippingTax = true;
      }
    });
    return isShippingTax;
  };

  const checkPriceTaxRate = (lineData = []) => {
    let isTaxInclusive = false;
    lineData.forEach((data) => {
      if (data.priceTaxRate) {
        isTaxInclusive = true;
      }
    });
    return isTaxInclusive;
  };

  const checkSku = (lineData = []) => {
    let isSku = false;
    lineData.forEach((data) => {
      if (data.sku) {
        isSku = true;
      }
    });
    return isSku;
  };

  const checkDiscount = (lineData = []) => {
    let isDiscount = false;
    lineData.forEach((data) => {
      if (data.vendorDiscount) {
        isDiscount = true;
      }
    });
    return isDiscount;
  };

  const isShippingTax = checkShippingTax(orderLineItems || []);
  const isTaxInclusive = checkPriceTaxRate(orderLineItems || []);
  const isSku = checkSku(orderLineItems || []);
  const isDiscount = checkDiscount(orderLineItems || []);
  const { priceTaxRate } = getSellerCommission(orderLineItems);
  if (priceTaxRate && !commissionTax) {
    totals.commissionTax = 0;
  }

  const totalOrderPayout = totals.commissionVendor;
  const totalShippingPayout = parseFloat(totals.shippingAmount) + parseFloat(totals.shippingTax);
  let totalVendorPayout = totalOrderPayout;

  if (!isExcludeShippingAmount) {
    totalVendorPayout += totalShippingPayout;
  }

  const getVendorShippingAddress = (data, users) => {
    const { address = "", city = "", pincode = "", country = "" } = data || {};
    const { firstName = "", lastName = "", phoneNumber = "" } = users || {};
    return (
      <>
        {firstName}
        {lastName}
        {firstName || lastName ? <br /> : ""}
        {phoneNumber}
        {phoneNumber ? <br /> : ""}
        {address}
        {address ? <br /> : ""}
        {city}
        {city ? <br /> : ""}
        {country}
        {country ? <br /> : ""}
        {pincode}
      </>
    );
  };

  const getShippingAddress = (sName, sAddress) => (
    <>
      {sName}
      <br />
      {sAddress.address}
      <br />
      {sAddress.city}
      <br />
      {sAddress.country}
      {sAddress.pinCode ? `, ${sAddress.pinCode}` : ""}
    </>
  );
  // const { sellerCommissionTax, commissionSeller, vendorCommissionTax, commissionVendor } = orderLineItems || {};
  if (invoiceSettingLoading || sellerSettingLoading) {
    return <Spinner isFullPage />;
  }

  return (
    <>
      <StyledSlip font={currentUser && currentUser.font} className="container">
        <div className="invoice-company text-inverse ">
          {(logo && <img src={logo} className="brand-logo" id="tableBanner" alt="" />) || (
            <DisplayText size="medium">{sellerInfo.brandName}</DisplayText>
          )}
          <span className="pull-right hidden-print" id="hide-element">
            <a href="" onClick={baseHelper.printInvoice} className="btn-white">
              <i className="fa fa-print t-plus-1 fa-fw fa-lg" />
              {` ${cms("label.print")} `}
            </a>
          </span>
        </div>
        {/* invoice-header */}
        <div className="invoice-header">
          {!invoiceSetting.purchaseFrom.isHide && (
            <div className="invoice-from">
              <small className="title text-uppercase">{invoiceSetting.purchaseFrom.label}</small>
              <address>
                <strong className="text-inverse capitalize">{vendorName.toLowerCase()}</strong>
                <br />
                {vendorAddress.address}
                <br />
                {vendorAddress.city}
                <br />
                {vendorAddress.country}
                {vendorAddress.pinCode ? `, ${vendorAddress.pinCode}` : ""}
              </address>
            </div>
          )}
          {customerAddress && !invoiceSetting.deliverTo.isHide && (
            <>
              <div className="invoice-from">
                <small className="title text-uppercase">{invoiceSetting.deliverTo.label}</small>
                <address>
                  <strong className="text-inverse capitalize">{customerAddress.name || ""}</strong>
                  <br />
                  {`${customerAddress.address1 || ""}  ${customerAddress.address2 || ""}`}
                  <br />
                  {customerAddress.city || ""}
                  <br />
                  {customerAddress.country || ""}
                  {customerAddress.zip ? `, ${customerAddress.zip}` : ""}
                  <br />
                  {customerAddress.phone || ""}
                </address>
              </div>
            </>
          )}

          <div className="invoice-date">
            <small className="title text-uppercase">{`${cms("label.invoice")}`}</small>
            <div className="date text-inverse">{`${baseHelper.formatDate(createdAt, false)}`}</div>
            <div className="invoice-detail">
              {`${cms("label.invoiceNumber")}    
              ${invoiceNumberPrefix || shopifyOrderId}`}
            </div>
            {vat && (
              <div className="invoice-detail">
                {`${cms("label.vat")} #: `}
                {vat}
              </div>
            )}
            {dueDate && (
              <div className="invoice-detail">
                {`${cms("label.taxPoint")}: `}
                {`${baseHelper.dayFirstDateFormat(dueDate)}`}
              </div>
            )}
            <div className="invoice-detail">
              {`${cms("label.status")}: `}
              {baseHelper.getOrderAccountingStatus(orderLineItems)}
            </div>
          </div>
        </div>
        {/* end invoice-header */}
        {/* invoice-content */}
        <div className="invoice-content">
          <div className="table-responsive">
            <table className="table table-invoice">
              <thead>
                <tr>
                  <th style={{ textAlign: "left" }}>{invoiceSetting.product.label}</th>
                  {isSku && !invoiceSetting.sku.isHide && <th>{invoiceSetting.sku.label}</th>}
                  <th>{invoiceSetting.quantity.label}</th>
                  <th>{invoiceSetting.price.label}</th>
                  {!invoiceSetting.tax.isHide && <th>{invoiceSetting.tax.label}</th>}
                  {isDiscount && !invoiceSetting.discount.isHide && <th>{invoiceSetting.discount.label}</th>}
                  {/* {!invoiceSetting.status.isHide && <th>{`${invoiceSetting.status.label}`}</th>} */}
                  {!invoiceSetting.subtotal.isHide && <th>{invoiceSetting.subtotal.label}</th>}
                </tr>
              </thead>
              {orderLineItems &&
                orderLineItems.map((item) => (
                  <>
                    <tbody>
                      {orderLineItems.length > 1 ? (
                        <tr className="line-remove">
                          <th style={{ textAlign: "left" }}>{`Product (Line Item ${lineitemNumber++})`}</th>
                          {isSku && !invoiceSetting.sku.isHide && <td />}
                          <td />
                          <td />
                          {!invoiceSetting.tax.isHide && <td />}
                          {isDiscount && !invoiceSetting.discount.isHide && <td />}
                          {!invoiceSetting.subtotal.isHide && (
                            <th style={{ textAlign: "right" }}>{`${item.accountingStatus}`}</th>
                          )}
                        </tr>
                      ) : (
                        <tr className="line-remove">
                          <th style={{ textAlign: "left" }}> Line item details</th>
                        </tr>
                      )}

                      <tr>
                        <td style={{ textAlign: "left" }}>{`${item.name}`}</td>
                        {isSku && !invoiceSetting.sku.isHide && <td>{`${item.sku}`}</td>}
                        <td>{`${item.actualQuantity || 0}`}</td>
                        <td>{`${moneyFormat}${baseHelper.getPrice(item.price)}`}</td>
                        {isTaxInclusive && !invoiceSetting.tax.isHide ? (
                          <td>{`*${moneyFormat}${baseHelper.getPrice(getItemPriceTax(item))}`}</td>
                        ) : (
                          !invoiceSetting.tax.isHide && <td>-</td>
                        )}
                        {isDiscount && !invoiceSetting.discount.isHide && (
                          <td>{`${moneyFormat}${baseHelper.getPrice(item.vendorDiscount)}`}</td>
                        )}
                        {/* {!invoiceSetting.status.isHide && <td>{` ${item.accountingStatus || cms("label.unpaid")}`}</td>} */}
                        {!invoiceSetting.subtotal.isHide && (
                          <td>{`${moneyFormat}${baseHelper.getPrice(getSubtotalAmount(item))}`}</td>
                        )}
                      </tr>
                      <p className="description">{`${item.description}`}</p>

                      {!isExcludeShippingAmount && !invoiceSetting.shipping.isHide && (
                        <tr className="line-remove">
                          <td style={{ textAlign: "left" }}>{invoiceSetting.shipping.label}</td>
                          {isSku && !invoiceSetting.sku.isHide && <td>-</td>}
                          <td>-</td>
                          {!item.shippingAmount ? (
                            <td>-</td>
                          ) : (
                            <td>{`${moneyFormat}${baseHelper.getPrice(item.shippingAmount)}`}</td>
                          )}
                          {isShippingTax && !invoiceSetting.tax.isHide && !!item.shippingTax ? (
                            <td>
                              {`${moneyFormat}${baseHelper.getPrice(item.shippingTax)}`}
                              {shippingTaxType === "percentage" ? `(${baseHelper.getPrice(shippingTaxRate)}%)` : ""}
                            </td>
                          ) : (
                            !invoiceSetting.tax.isHide && <td>-</td>
                          )}
                          {isDiscount && !invoiceSetting.discount.isHide && <td>-</td>}
                          {/* {!invoiceSetting.status.isHide && <td>-</td>} */}
                          {!invoiceSetting.subtotal.isHide && (
                            <td>{`${moneyFormat}${baseHelper.getPrice(getTotalShipping(item))}`}</td>
                          )}
                        </tr>
                      )}
                      {!invoiceSetting.totalAmount.isHide && (
                        <tr>
                          <th>{invoiceSetting.totalAmount.label}</th>
                          {isSku && !invoiceSetting.sku.isHide && <td />}
                          <td />
                          <th>
                            {`${moneyFormat}${baseHelper.getPrice(
                              item.price * item.actualQuantity + item.shippingAmount
                            )}`}
                          </th>

                          {!isTaxInclusive
                            ? !invoiceSetting.tax.isHide && (
                            <th>
                              {`${moneyFormat}${baseHelper.getPrice(getItemPriceTax(item) + item.shippingTax)}`}
                            </th>
                              )
                            : !invoiceSetting.tax.isHide && (
                            <th>{`${moneyFormat}${baseHelper.getPrice(item.shippingTax)}`}</th>
                              )}
                          {isDiscount && !invoiceSetting.discount.isHide && (
                            <th>{`${moneyFormat}${baseHelper.getPrice(item.vendorDiscount * item.actualQuantity)}`}</th>
                          )}
                          {/* {!invoiceSetting.status.isHide && <td />} */}
                          {!invoiceSetting.subtotal.isHide && (
                            <th>
                              {`${moneyFormat}${baseHelper.getPrice(getTotalAmount(item) + getTotalShipping(item))}`}
                            </th>
                          )}
                        </tr>
                      )}

                      {!invoiceSetting.sellerCommission.isHide && (
                        <tr className="invoice-deduction">
                          <td style={{ textAlign: "left" }}>{cms("label.deduction")}</td>
                          {isSku && !invoiceSetting.sku.isHide && <td />}
                          <td />
                          <td />
                          {!invoiceSetting.tax.isHide && <td />}
                          {isDiscount && !invoiceSetting.discount.isHide && <td />}
                          {/* {!invoiceSetting.status.isHide && <td />} */}
                          {!invoiceSetting.subtotal.isHide && <td />}
                        </tr>
                      )}

                      {item.isDiscountAllowed && (
                        <tr>
                          <td style={{ textAlign: "left" }}>
                            {`${cms("label.dynamicDiscount")} (${cms("label.shopifyDiscount")})`}
                          </td>
                          {isSku && !invoiceSetting.sku.isHide && <td>-</td>}
                          <td>-</td>
                          <td>-</td>
                          {!invoiceSetting.tax.isHide && <td>-</td>}
                          {isDiscount && !invoiceSetting.discount.isHide && <td>-</td>}
                          {/* {!invoiceSetting.status.isHide && <td>-</td>} */}
                          {!invoiceSetting.subtotal.isHide && (
                            <td>{`${moneyFormat}${baseHelper.getPrice(getDynamicDiscount(item))}`}</td>
                          )}
                        </tr>
                      )}

                      {!invoiceSetting.sellerCommission.isHide && (
                        <tr>
                          <td style={{ textAlign: "left" }}>{invoiceSetting.sellerCommission.label}</td>
                          {isSku && !invoiceSetting.sku.isHide && <td>-</td>}
                          <td>-</td>
                          {item.commissionSeller != 0 ? (
                            <td>
                              {`${moneyFormat}${baseHelper.getPrice(item.commissionSeller)}`}
                              {commissionType === "percentage" ? `(${baseHelper.getPrice(commissionRate)}%)` : ""}
                            </td>
                          ) : (
                            <td>-</td>
                          )}
                          {!invoiceSetting.tax.isHide && item.priceTaxRate && !item.commissionTax ? (
                            <td>{`*${moneyFormat}${baseHelper.getPrice(getOperatorCommissionTax(item))}`}</td>
                          ) : !invoiceSetting.tax.isHide && !!item.commissionTax ? (
                            <td>
                              {`${moneyFormat}${baseHelper.getPrice(item.commissionTax)}`}
                              {commissionTaxType === "percentage" ? `(${baseHelper.getPrice(commissionTaxRate)}%)` : ""}
                            </td>
                          ) : (
                            <td>-</td>
                          )}
                          {isDiscount && !invoiceSetting.discount.isHide && <td>-</td>}
                          {/* {!invoiceSetting.status.isHide && <td>-</td>} */}
                          {!invoiceSetting.subtotal.isHide && (
                            <td>{`${moneyFormat}${baseHelper.getPrice(getTotalOperatorCommission(item))}`}</td>
                          )}
                        </tr>
                      )}
                      {!invoiceSetting.totalDeduction.isHide && (
                        <tr>
                          <th style={{ textAlign: "left" }}>{invoiceSetting.totalDeduction.label}</th>
                          {isSku && !invoiceSetting.sku.isHide && <td />}
                          <td />
                          <th>{`${moneyFormat}${baseHelper.getPrice(getDeductionTotal(item))}`}</th>
                          {!invoiceSetting.tax.isHide && (
                            <th>{`${moneyFormat}${baseHelper.getPrice(item.commissionTax)}`}</th>
                          )}
                          {isDiscount && !invoiceSetting.discount.isHide && <td />}
                          {/* {!invoiceSetting.status.isHide && <td />} */}
                          {!invoiceSetting.subtotal.isHide && (
                            <td>
                              <b>{`${moneyFormat}${baseHelper.getPrice(getTotalDeduction(item))}`}</b>
                            </td>
                          )}
                        </tr>
                      )}
                      {orderLineItems.length && !invoiceSetting.orderTotal.isHide && (
                        <tr>
                          <th style={{ textAlign: "left" }}>{invoiceSetting.orderTotal.label}</th>
                          {isSku && !invoiceSetting.sku.isHide && <td />}
                          <td />
                          <td />
                          {!invoiceSetting.tax.isHide && <td />}
                          {isDiscount && !invoiceSetting.discount.isHide && <td />}
                          {/* {!invoiceSetting.status.isHide && <td />} */}
                          {!invoiceSetting.subtotal.isHide && (
                            <td>
                              <b>{`${moneyFormat}${baseHelper.getPrice(getLinesSubTotal(item))}`}</b>
                            </td>
                          )}
                        </tr>
                      )}
                    </tbody>
                    <br />
                  </>
                ))}
            </table>
          </div>
          {/* invoice-price */}
          <div className="invoice-price">
            <b style={{ display: "flex", justifyContent: "center" }}>Order Summary</b>
            <div className="invoice-price-left">
              <div className="invoice-price-row">
                {!invoiceSetting.orderPayout.isHide && (
                  <div className="sub-price">
                    {/* <small className="label">{invoiceSetting.orderPayout.label}</small>
                    <span className="text-inverse">{`${moneyFormat}${baseHelper.getPrice(totalOrderPayout)}`}</span> */}
                  </div>
                )}
                <div className="sub-price">
                  <p className="label">{invoiceSetting.totalOrderAmount.label}</p>
                  <span className="text-inverse">{`${moneyFormat}${baseHelper.getPrice(summaryAmount)}`}</span>
                </div>
                {!invoiceSetting.totalOrderShipping.isHide && (
                  <div className="sub-price">
                    <p className="label">{invoiceSetting.totalOrderShipping.label}</p>
                    <span className="text-inverse">{`${moneyFormat}${baseHelper.getPrice(shippingAmountValue)}`}</span>
                  </div>
                )}
                {!invoiceSetting.totalOrderTax.isHide && (
                  <div className="sub-price">
                    <p className="label">{invoiceSetting.totalOrderTax.label}</p>
                    <span className="text-inverse">{`${moneyFormat}${baseHelper.getPrice(totalShippingTax)}`}</span>
                  </div>
                )}
                {!invoiceSetting.totalDeductionAmount.isHide && (
                  <div className="sub-price">
                    <p className="label">{invoiceSetting.totalDeductionAmount.label}</p>
                    <span className="text-inverse">
                      {isDynamicDiscount
                        ? `${moneyFormat}${baseHelper.getPrice(deductionValue + totalDiscountValue)}`
                        : `${moneyFormat}${baseHelper.getPrice(deductionValue)}`}
                    </span>
                  </div>
                )}
                {!invoiceSetting.totalDeductionTax.isHide && (
                  <div className="sub-price">
                    <p className="label">{invoiceSetting.totalDeductionTax.label}</p>
                    <span className="text-inverse">{`${moneyFormat}${baseHelper.getPrice(totalDeductionTax)}`}</span>
                  </div>
                )}
              </div>
            </div>
            <hr style={{ borderTop: "0.1rem solid rgb(225, 227, 229, 1)" }} />
            <div className="invoice-price-right">
              <p className="label-bold">{invoiceSetting.totalVendorPayout.label.toLowerCase()}</p>
              <span className="text-inverse">{`${moneyFormat}${baseHelper.getPrice(totalVendorPayout)}`}</span>
            </div>
          </div>
          <br />

          {isTaxInclusive && <h2>{`*${cms("label.taxInclusive")}`}</h2>}
          {/* end invoice-price */}
        </div>
        {/* end invoice-content */}
        {/* invoice-note */}
        <div className="invoice-footer-content">
          {!invoiceSetting.fulfillBy.isHide && (
            <>
              <div className="note">
                <span className="text-uppercase note-label">{`${invoiceSetting.fulfillBy.label}:`}</span>
                <address>
                  {(isVendor &&
                    type === constant.CONSIGNMENT &&
                    getVendorShippingAddress(fulfillmentData, sellerInfo)) ||
                    getShippingAddress(sellerName, sellerAddress)}
                  {sellerTaxInfo && sellerTaxInfo.company && (
                    <>
                      {`${cms("label.companyNumber")}`}
                      {` ${sellerTaxInfo && sellerTaxInfo.company}`}
                      <br />
                    </>
                  )}
                  {sellerTaxInfo && sellerTaxInfo.customs && (
                    <>
                      {`${cms("label.customsNumber")}`}
                      {` ${sellerTaxInfo && sellerTaxInfo.customs}`}
                      <br />
                    </>
                  )}
                </address>
              </div>
            </>
          )}
          {value && (
            <div className="note">
              <span className="text-uppercase">{`${cms("label.note")}:`}</span>
              <br />
              <span>{`${value}`}</span>
            </div>
          )}
        </div>
        {/* invoice-footer */}
        <div className="invoice-footer">{`${cms("label.footerNote")}`}</div>
      </StyledSlip>
    </>
  );
};
OrderSlipView.defaultProps = {
  fulfillment: () => {},
  logo: "",
  orderData: {},
  orderLineItems: [],
  sellerInfo: {},
  vendorInfo: {},
};
OrderSlipView.propTypes = {
  fulfillment: PropTypes.func,
  logo: PropTypes.string,
  orderData: PropTypes.shape(PropTypes.object),
  orderLineItems: PropTypes.arrayOf(PropTypes.object),
  sellerInfo: PropTypes.shape(PropTypes.object),
  vendorInfo: PropTypes.shape(PropTypes.object),
};

export default OrderSlipView;
